import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { CarListingSearchBoxDesktop } from '^/components/CarSearchBox/base/desktop/CarListingSearchBox.desktop';
import { SPACING, font } from '^/constants/commonStyles';
import { NOTICE_DETAIL } from '^/constants/routes';

import Banner from '../../../Notice/components/Banner';
import { fakeBanner } from '../../../Notice/data';
import { HomeViewModel } from '../viewmodel/Home.viewmodel';

export const HomeDesktop: React.FC<{
  viewmodel: HomeViewModel;
}> = observer(function HomePage2Desktop({ viewmodel }) {
  const navigate = useNavigate();

  return (
    <div
      className={cx(
        css`
          position: relative;
          /* overflow-x: auto; */
          height: 788px;
          @media (max-width: 1280px) {
            padding: 0 24px;
          }
        `,
      )}
    >
      <h1
        className={cx(
          font.LargeTitle,
          css`
            margin-bottom: 56px;
          `,
        )}
      >
        <div>이번 휴가는 </div>
        <div>해외여행 어떠세요?</div>
      </h1>
      <CarListingSearchBoxDesktop
        viewmodel={viewmodel.carListingSearchBox}
        onBlur={() => viewmodel.carListingSearchBox.stepManager.clearStep()}
      />
      <div
        className={css`
          display: grid;
          grid-template-columns: repeat(4, minmax(0, 1fr));
          margin-top: 58px;
          gap: ${SPACING.spacing24};
        `}
      >
        {fakeBanner.map((banner) => {
          return (
            <Banner
              key={banner.id}
              item={banner}
              onClick={() => {
                navigate({
                  pathname: NOTICE_DETAIL,
                  search: `${new URLSearchParams({
                    id: String(banner.id),
                  })}`,
                });
              }}
              hasIcon={false}
              subdomain="default"
            />
          );
        })}
      </div>
    </div>
  );
});

// calendar month without header(sun~sat)

import React, { Ref, RefObject, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { useInView } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { RentalCarOfficeLocationType } from '^/AppContext/RentalOfficeLocationRepo';
import { SPACING } from '^/constants/commonStyles';
import useWindowResize from '^/hooks/useWindowResize';
import { MobxInputValue } from '^/util/MobxInputValue';

import { CalendarStateViewModel } from '../../Calendar.state.viewmodel';
import { isYearMonthInsideSelectionRange } from '../../helpers/isYearMonthInsideSelectionRange';
import { CalendarHeader } from '../Calendar.header';
import { CalendarDay } from '../Day/Calendar.Day';

import type { CalendarMonthViewModel } from './Calendar.Month.viewmdel';
import { WeekDays } from './Calendar.WeekDays';

const classNames = {
  daysWrapper: css`
    display: flex;
    flex-flow: wrap;
    width: 100%;
  `,
};

export const CalendarMonth: React.FC<{
  isCalendarWidthTimeMaxSize?: boolean,
  selectionEndRef: Ref<HTMLDivElement>,
  containerRef?: RefObject<Element>,
  hasDifferentPickupReturnLocations: MobxInputValue<boolean>,
  pickupLocation: RentalCarOfficeLocationType,
  returnLocation: RentalCarOfficeLocationType,
  viewmodel: CalendarMonthViewModel,
  showPrevButton?: boolean,
  showNextButton?: boolean,
  stateViewModel: CalendarStateViewModel,
  subdomain?: string,
}> = observer(function CalendarMonth({
  selectionEndRef,
  containerRef,
  hasDifferentPickupReturnLocations,
  pickupLocation,
  returnLocation,
  viewmodel,
  showPrevButton,
  showNextButton,
  stateViewModel,
  subdomain,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [width] = useWindowResize();
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, {
    once: true,
    root: containerRef,
    margin: `${width * 2}px 0px`,
  });
  const isInSelectionRange = isYearMonthInsideSelectionRange(
    viewmodel.yearMonth,
    stateViewModel.selection,
  );

  const selectionEnd = stateViewModel.selection.end;

  const hasSelectionEndDate = isInSelectionRange
    && selectionEnd
    && selectionEnd.year === viewmodel.yearMonth.year
    && selectionEnd.yearMonth.monthFrom0 === viewmodel.yearMonth.monthFrom0;

  const isMRT = subdomain?.includes('myrealtrip');

  return (
    <div
      ref={ref}
      className={cx(
        isMobile
          ? css`
            min-height: 80vw;
          `
          : css`
              width: 100%;
              border: solid 1px #a9d3f9;
              border-radius: 10px;
              padding: ${SPACING.spacing24};
            `,
        isMRT
        && css`
        border: none;
        padding: 0;
        `,
      )}
    >

      <CalendarHeader
        calendarStateVM={stateViewModel}
        yearMonth={viewmodel.yearMonth}
        showNextButton={showNextButton}
        showPrevButton={showPrevButton}
        subdomain={subdomain}
      />

      {!isMobile && (
        <WeekDays subdomain={subdomain} />
      )}
      {/* <h1>INVIEW: {JSON.stringify(inView)}</h1> */}
      {(inView || null) && (
        <div
          className={cx(
            classNames.daysWrapper,
            isMRT && css`gap: 2px 0;`,
            // borders.DEBUG_purpleBorderDashed,
          )}
        >
          {[...Array(viewmodel.startWeekDay)].map((_, i) => (
            <div
              key={i}
              className={
                cx(css`
                  width: 14.28%;
                `,
                isMRT && css`height: 37px;`)
              }
            >
            </div>
          ))}
          {viewmodel
            .dayViewModels
            .map((vm) => (
              <CalendarDay
                monthInSelectionRange={isInSelectionRange}
                hasDifferentPickupReturnLocations={
                  hasDifferentPickupReturnLocations
                }
                pickupLocation={pickupLocation}
                returnLocation={returnLocation}
                stateViewModel={stateViewModel}
                key={vm.str}
                viewmodel={vm}
                elemRef={(
                  (
                    hasSelectionEndDate
                    && vm.day === selectionEnd.day
                  ) || undefined)
                  && selectionEndRef}
                subdomain={subdomain}
              />
            ),
            )}
        </div>
      )}
    </div>

  );
});

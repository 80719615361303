/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import { Fragment, RefObject, useEffect, useRef, useState } from 'react';

import { cx, css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { BRAND } from 'zod';

import { actions, sendTrackingLog } from '^/api/trackingLog';
import { isOntra } from '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { CarListingSearchBoxViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { popularCityList } from '^/components/CarSearchBox/common/popularCity.constants';
import { MRTCitySearchInputButton } from '^/components/CarSearchBox/myrealtrip/desktop/headerArea/MRTCitySearchInputButton';
import { MRTSearchStepperMobile } from '^/components/CarSearchBox/myrealtrip/mobile/contentsArea/SearchStepper/MRTSearchStepper.mobile';
import { AlertPopup } from '^/components/Popup/AlertPopup/AlertPopup';
import { COLORS2 } from '^/constants/commonStyles';

import { mrtCitiesByCountry } from './mrtCitiesByCountry';

const continents = ['인기도시'].concat(
  mrtCitiesByCountry.map((continentItem) => continentItem.continent),
);

export const MRTSelectReturnCity: React.FC<{
  key: string;
  viewmodel: CarListingSearchBoxViewModel;
  step: { type: '5_return_city' };
  pickupCityInputRef?: RefObject<HTMLInputElement>;
}> = observer(function MRTSelectReturnCity({ key, viewmodel }) {
  const [selectedContinent, setSelectedContinent] = useState(
    viewmodel.i.returnContinent.value || continents[0],
  );
  const [selectedCountry, setselectedCountry] = useState(viewmodel.i.returnContry.value || '');
  const citiesRef = useRef<HTMLDivElement>(null);
  const mounted = useRef(false);

  const mobileWebVhSetting = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    mobileWebVhSetting();
  }, []);

  useEffect(() => {
    if (!citiesRef.current || !mounted.current) {
      mounted.current = true;
      return;
    }
    citiesRef.current.scrollTo({ top: 0 });
  }, [selectedContinent]);

  useEffect(() => {
    if (
      !citiesRef.current ||
      !viewmodel.i.returnContinent.value ||
      !viewmodel.i.returnContry.value
    ) {
      return;
    }
    const selectdContinentIndex = mrtCitiesByCountry.findIndex(
      (continentItem) => continentItem.continent === viewmodel.i.returnContinent.value,
    );
    const selectdContryIndex = mrtCitiesByCountry[selectdContinentIndex].countries.findIndex(
      (contryItem) => contryItem.code === viewmodel.i.returnContry.value,
    );
    if (selectdContinentIndex < 0 || selectdContryIndex < 0) {
      return;
    }

    citiesRef.current.scrollTo({ top: 50 * selectdContryIndex });
  }, []);

  const findSelectCityInfo = (code: string) => {
    let contry = '';
    let continent = '';
    let city = '';
    mrtCitiesByCountry.find((continentItem) => {
      return continentItem.countries.find((contryItem) => {
        return contryItem.cities.find((cityItem) => {
          if (cityItem.code === code) {
            contry = contryItem.code;
            city = cityItem.code;
            continent = continentItem.continent;
            return true;
          }
          return false;
        });
      });
    });

    return { contry, continent, city };
  };
  return (
    <Fragment key={key}>
      <div>
        <div
          className={cx(
            css`
              padding: 0 20px;
              margin-top: 68px;
            `,
          )}
        >
          <MRTSearchStepperMobile currentStep={1} />
          <div
            className={cx(
              css`
                margin-top: 20px;
                color: #1e1e1e;
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
              `,
            )}
          >
            도시를 선택 또는 검색해주세요.
          </div>
          <div
            className={cx(css`
              margin-top: 16px;
            `)}
          >
            <MRTCitySearchInputButton
              placeholder="도시이름을 검색해보세요"
              // inputRef={citySearchInputRef}
              viewmodel={viewmodel.i.tempReturnCitySearch}
              title=""
              // showSuggestions
              onSelectCity={(cityCode, cityKoName) => {
                const { contry, continent } = findSelectCityInfo(cityCode) as {
                  contry: string;
                  continent: string;
                  city: string & BRAND<'CityCode'>;
                };
                viewmodel.i.returnContinent.set(continent);
                viewmodel.i.returnContry.set(contry);
                viewmodel.i.returnLocationId.set(null);
                viewmodel.i.tempReturnCitySearch.selectedCityCode.set(cityCode);
                viewmodel.i.tempReturnCitySearch.tempSearchInput.set(cityKoName);
                sessionStorage.setItem('selectedCountry', contry || '');
                if (
                  viewmodel.i.hasDifferentPickupReturnLocations.value &&
                  isOntra(viewmodel.i.tempReturnCitySearch.selectedCityCode.value)
                ) {
                  viewmodel.showDifferentLocationAlert.set(true);
                  viewmodel.i.returnCitySearch.selectedCityCode.set(null);
                  viewmodel.i.returnCitySearch.searchInput.set('');
                }
                viewmodel.stepManager.pushStep({ type: '6_return_office' });
              }}
              inputElemProps={{
                autoFocus: true,
              }}
            />
          </div>
        </div>
        <div>
          <div
            className={cx(
              css`
                display: flex;
                margin-top: 20px;
                border-top: 1px solid #f4f4f4;
              `,
            )}
          >
            <div
              className={cx(
                css`
                  flex: 1;
                  background-color: #fafafa;
                `,
              )}
            >
              {continents.map((continent) => {
                return (
                  <div
                    className={cx(
                      css`
                        height: 50px;
                        border-bottom: 1px solid #f4f4f4;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22px;
                        display: flex;
                        align-items: center;
                        padding-left: 16px;
                        width: 100%;
                        background-color: ${selectedContinent === continent ? '#fff' : '#FAFAFA'};
                        color: ${selectedContinent === continent ? '#1E1E1E' : '#575757'};
                      `,
                    )}
                    onClick={() => {
                      setSelectedContinent(continent);
                      viewmodel.i.returnContinent.set(continent);
                    }}
                  >
                    {continent}
                  </div>
                );
              })}
            </div>
            <div
              className={cx(
                css`
                  overflow: scroll;
                  height: calc(var(--vh) * 100 - 221px);
                  flex: 1.75;
                  padding-left: 12px;
                `,
              )}
              ref={citiesRef}
            >
              {selectedContinent === '인기도시'
                ? popularCityList.map((city) => (
                    <div
                      role="menuitem"
                      tabIndex={-1}
                      onClick={() => {
                        const { contry, continent } = findSelectCityInfo(city.code) as {
                          contry: string;
                          continent: string;
                          city: string & BRAND<'CityCode'>;
                        };
                        viewmodel.i.returnContry.set(contry);
                        viewmodel.i.returnContinent.set(continent);
                        viewmodel.i.returnLocationId.set(null);
                        viewmodel.i.tempReturnCitySearch.selectedCityCode.set(city.code);
                        viewmodel.i.tempReturnCitySearch.tempSearchInput.set(city.nameKo);
                        if (
                          viewmodel.i.hasDifferentPickupReturnLocations.value &&
                          isOntra(viewmodel.i.tempReturnCitySearch.selectedCityCode.value)
                        ) {
                          viewmodel.showDifferentLocationAlert.set(true);
                          viewmodel.i.returnCitySearch.selectedCityCode.set(null);
                          viewmodel.i.returnCitySearch.searchInput.set('');
                          return;
                        }
                        viewmodel.stepManager.pushStep({ type: '6_return_office' });
                        sessionStorage.setItem('selectedCountry', city.countryCode);
                        sendTrackingLog({
                          action: actions['/'].SELECT_POPULAR_CITY,
                          data: city.nameKo,
                          country: city.countryCode,
                        });
                      }}
                      key={city.code}
                      className={cx(
                        css`
                          height: 50px;
                          border-bottom: 1px solid #f4f4f4;
                          font-weight: 500;
                          font-size: 16px;
                          line-height: 22px;
                          display: flex;
                          align-items: center;
                          padding-left: 16px;
                        `,
                        city.code === viewmodel.i.returnCityCode.value &&
                          css`
                            color: ${COLORS2.main.MrtBlue1};
                            display: flex;
                            justify-content: space-between;
                            cursor: pointer;
                            align-items: center;
                          `,
                      )}
                    >
                      {city.nameKo}
                    </div>
                  ))
                : mrtCitiesByCountry
                    .find((continentItem) => continentItem.continent === selectedContinent)
                    ?.countries.map((contryItem) => {
                      return (
                        <>
                          <div
                            role="menuitem"
                            tabIndex={-1}
                            onClick={() => {
                              if (selectedCountry === contryItem.code) {
                                viewmodel.i.returnContry.set('');
                                setselectedCountry('');
                                return;
                              }
                              viewmodel.i.returnContry.set(contryItem.code);
                              setselectedCountry(contryItem.code);
                            }}
                            key={contryItem.code}
                            className={cx(
                              css`
                                display: flex;
                                justify-content: space-between;
                                height: 50px;
                                border-bottom: 1px solid #f4f4f4;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 22px;
                                display: flex;
                                align-items: center;
                                padding: 0 16px;
                              `,
                            )}
                          >
                            <div>{contryItem.nameKo}</div>
                            <img
                              src={
                                contryItem.code === selectedCountry
                                  ? 'chevron-up_20.svg'
                                  : 'chevron-down_20.svg'
                              }
                              alt="chevron"
                            />
                          </div>
                          {contryItem.code === selectedCountry && (
                            <div>
                              {contryItem.cities.map((cityItem) => {
                                return (
                                  <div
                                    className={cx(
                                      css`
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        margin-left: 16px;
                                        border-bottom: 1px solid #f4f4f4;
                                        height: 48px;
                                        font-size: 14px;
                                        line-height: 20px;
                                        font-weight: 500;
                                        color: #575757;
                                        padding-right: 15px;
                                      `,
                                      cityItem.code === viewmodel.i.returnCityCode.value &&
                                        css`
                                          color: ${COLORS2.main.MrtBlue1};
                                        `,
                                    )}
                                    onClick={() => {
                                      const { contry, continent } = findSelectCityInfo(
                                        cityItem.code,
                                      ) as {
                                        contry: string;
                                        continent: string;
                                        city: string & BRAND<'CityCode'>;
                                      };
                                      viewmodel.i.returnContinent.set(continent);
                                      viewmodel.i.returnContry.set(contry);
                                      viewmodel.i.tempReturnCitySearch.selectedCityCode.set(
                                        cityItem.code,
                                      );
                                      viewmodel.i.tempReturnCitySearch.tempSearchInput.set(
                                        cityItem.nameKo,
                                      );
                                      viewmodel.i.returnLocationId.set(null);
                                      if (
                                        viewmodel.i.hasDifferentPickupReturnLocations.value &&
                                        isOntra(
                                          viewmodel.i.tempReturnCitySearch.selectedCityCode.value,
                                        )
                                      ) {
                                        viewmodel.showDifferentLocationAlert.set(true);
                                        viewmodel.i.returnCitySearch.selectedCityCode.set(null);
                                        viewmodel.i.returnCitySearch.searchInput.set('');
                                        return;
                                      }
                                      viewmodel.stepManager.pushStep({ type: '6_return_office' });
                                      sessionStorage.setItem('selectedCountry', contryItem.code);
                                      sendTrackingLog({
                                        action: actions['/'].SELECT_POPULAR_CITY,
                                        data: cityItem.nameKo,
                                        country: contryItem.code,
                                      });
                                    }}
                                  >
                                    {cityItem.nameKo}
                                    {cityItem.code ===
                                      viewmodel.i.returnCitySearch.selectedCityCode.value && (
                                      <img src="CkCheck.svg" alt="CkCheck" />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </>
                      );
                    })}
            </div>
          </div>
        </div>
      </div>
      <AlertPopup
        visible={viewmodel.showDifferentLocationAlert}
        title="해당 도시는 대여/반납 장소를 다르게 지정할 수 없습니다."
        closeText="닫기"
        // eslint-disable-next-line react/jsx-no-bind
        handleClose={() => viewmodel.showDifferentLocationAlert.set(false)}
        isMobile
      />
    </Fragment>
  );
});

/* eslint-disable no-nested-ternary */
import React from 'react';

import { css, cx } from '@emotion/css';
import { useRecoilValue } from 'recoil';

import { SPACING, font } from '^/constants/commonStyles';
import { subdomainState } from '^/recoil/atoms';
import { OpenCloseTime } from '^/types/RawOpenHours';

import { TimeSelectMinuteNullable } from './TimeSelect.constants';
import { classNames } from './TimeSelect.styles';

export const MinuteRenderer: React.FC<{
  value: TimeSelectMinuteNullable;
  isOpen?: boolean;
  isListItem?: boolean;
  disabled?: boolean;
  isNotSelected?: boolean;
}> = ({
  value, isOpen, isListItem, disabled, isNotSelected,
}) => {
  const subdomain = useRecoilValue(subdomainState);
  const isMRT = subdomain.includes('myrealtrip');
  const isEmpty = value === null;

  return (
    <div
      className={cx(
        isListItem ? [classNames.btnRendererBasic, font.Body1_Regular]
          : classNames.btnRendererBasic,
        disabled
          ? isMRT ? css`color: #cecece !important` : css`opacity: 0.3;`
          : '',
        isMRT && css`height: 40px;
        padding: 0 3px 0 8px !important;`,
        isMRT && isListItem && css`height: 30px`,
      )}
    >
      <div>
        {isEmpty && isMRT
        && (
        <span
          className={cx(classNames.timeNotSelected)}
        >
          00
        </span>
        )}
        <span className={cx(isMRT && classNames.timeSelected,
          isMRT && isNotSelected && classNames.timeNotSelected)}
        >{value}분
        </span>
      </div>
      {!isListItem && (
        <img
          alt="minute-list-toggle"
          src={isMRT
            ? isOpen ? './arrow_drop_up_20.svg' : './arrow_drop_down_20.svg'
            : isOpen ? './ic_arrow_up.png' : './ic_arrow_down.png'}
          className={css`
          @media (max-width: 768px) {
            width: ${SPACING.spacing16};
            height: ${SPACING.spacing16};
          }
        `}
        />
      )}
    </div>
  );
};

import { getSessionId, getUUID } from '^/util/user';
import axios from '../../axios/index';
import localAxios from '../../axios/localAxios';
import { sendTrackingLog, actions } from '../trackingLog';

const BASE_PATH = '/reservations';

export const createReservation = async (reservation) => {
  return axios.fetch({
    url: `${BASE_PATH}/book`,
    method: 'POST',
    data: reservation,
  });
};

export const cancelReservation = async (reservation) => {
  return axios.fetch({
    url: `${BASE_PATH}/cancel`,
    method: 'POST',
    data: reservation,
  });
};

export const checkReservation = async (reservation) => {
  return axios.fetch({
    url: `${BASE_PATH}/check`,
    method: 'POST',
    data: reservation,
  });
};

export const getReservation = async (id) => {
  return axios.fetch({
    url: `${BASE_PATH}/${id}`,
    method: 'GET',
  });
};

export const getMyBookedReservations = async () => {
  const res = await axios.fetch({
    url: `${BASE_PATH}/mine/booked`,
    method: 'GET',
  });
  return res.data;
};

export const getMyCanceledReservations = async () => {
  const res = await axios.fetch({
    url: `${BASE_PATH}/mine/canceled`,
    method: 'GET',
  });
  return res.data;
};

// ===================================================
// Non Member v2
// CHECK: 신규 예약 호출, V2
export const createReservationV2 = async (reservation) => {
  return axios.fetch({
    url: '/v2/reservation/make',
    method: 'POST',
    data: reservation,
  });
};

export const localCreateReservation = async (reservation) => {
  return localAxios.fetch({
    url: '/v2/reservation/make',
    method: 'post',
    data: reservation,
  });
};

export const confirmReservation = async (reservation_id, ispay, imp_uid, merchant_uid) => {
  return axios.fetch({
    url: '/v2/reservation/confirm',
    method: 'POST',
    data: {
      reservation_id: reservation_id,
      ispay: ispay,
      imp_uid: imp_uid,
      merchant_uid: merchant_uid,
    },
  });
};

export const getMyReservationV2 = async (email, phone) => {
  return axios.fetch({
    url: '/v2/reservation/list',
    method: 'POST',
    data: {
      email: email,
      phone: phone,
    },
  });
};

export const getOne = async (reservation_id) => {
  return axios.fetch({
    url: '/v2/reservation/get',
    method: 'POST',
    data: {
      res_id: reservation_id,
    },
  });
};

export const getReservationListBooked = async (email, phone) => {
  const startTime = performance.now();
  const response = await axios.fetch({
    url: `/v2/reservation/list/booked?email=${email}&phone=${phone}`,
    method: 'GET',
  });
  if (response.data) {
    const endTime = performance.now();
    const duration = (endTime - startTime) / 1000;
    sendTrackingLog({
      action: actions.RESERVATION_LIST.BOOKED_RESERVATION_LIST_COMPLETE,
      response_time: Number(duration.toFixed(2)),
    });
  }
  return response;
};
export const getReservationListCanceled = async (email, phone) => {
  const startTime = performance.now();

  const response = await axios.fetch({
    url: `/v2/reservation/list/canceled?email=${email}&phone=${phone}`,
    method: 'GET',
  });
  if (response.data) {
    const endTime = performance.now();
    const duration = (endTime - startTime) / 1000;
    sendTrackingLog({
      action: actions.RESERVATION_LIST.CANCELLED_RESERVATION_LIST_COMPLETE,
      response_time: Number(duration.toFixed(2)),
    });
  }
  return response;
};

export const getMyDetailReservationV2 = async (email, phone, reservationCode) => {
  return axios.fetch({
    url: `/v2/reservation/detail/${reservationCode}?email=${email}&phone=${phone}`,
    method: 'GET',
  });
};

export const getReservationDetailBooked = async (reservation) => {
  return axios.fetch({
    url: '/v2/reservation/detail/booked',
    method: 'POST',
    data: reservation,
  });
};

export const getReservationDetailCanceled = async (reservation_id) => {
  return axios.fetch({
    url: 'v2/reservation/detail/canceled',
    method: 'POST',
    data: {
      reservation_id,
    },
  });
};

export const cancelReservationV2 = async (reservation_id, last_name, vendor_id) => {
  return axios.fetch({
    url: '/v2/reservation/cancel-v2',
    method: 'POST',
    data: {
      reservation_id: reservation_id,
      last_name: last_name,
      vendor_id: vendor_id,
    },
  });
};

export const createReservationOT = async (reservation) => {
  const response = await axios.fetch({
    url: '/v2/reservation/make/onlineTravel',
    method: 'POST',
    data: reservation,
  });
  return response;
};

export const getReservationOT = async (res_id) => {
  const startTime = performance.now();
  const response = await axios.fetch({
    url: `/v2/reservation/detail-onlineTravel?res_id=${res_id}&uuid=${getUUID()}&session_id=${getSessionId()}`,
    method: 'GET',
  });
  if (response.data) {
    const endTime = performance.now();
    const duration = (endTime - startTime) / 1000;
    sendTrackingLog({
      action: actions.RESERVATION_DETAIL.RESERVATION_DETAIL_COMPLETE,
      response_time: Number(duration.toFixed(2)),
      data: res_id,
    });
  }
  return response;
};

export const cancelReservationOT = async (res_id, cancel_reason) => {
  const startTime = performance.now();
  const response = await axios.fetch({
    url: '/v2/reservation/cancel-onlineTravel',
    method: 'POST',
    data: {
      res_id: res_id,
      uuid: getUUID(),
      session_id: getSessionId(),
      cancel_reason,
    },
  });
  if (response.data) {
    const endTime = performance.now();
    const duration = (endTime - startTime) / 1000;
    sendTrackingLog({
      action: actions.RESERVATION_DETAIL.RESERVATION_CANCEL_COMPLETE,
      response_time: Number(duration.toFixed(2)),
      data: res_id,
    });
  }
  return response;
};

export const getShopGeocodeOT = async (shpcode, brdcode) => {
  const response = await axios.fetch({
    url: `/v2/reservation/shoplocation-onlineTravel?shpcode=${shpcode}&brdcode=${brdcode}`,
    method: 'GET',
  });
  return response;
};

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { MRTCarListingSearchBoxDesktop } from '^/components/CarSearchBox/myrealtrip/desktop/MRTCarListingSearchBox.desktop';
import { SPACING, font } from '^/constants/commonStyles';
import { NOTICE_DETAIL } from '^/constants/routes';

import MRTBanner from '../../../Notice/components/MRTBanner';
import { fakeBanner } from '../../../Notice/data';
import { HomeViewModel } from '../../base/viewmodel/Home.viewmodel';

export const HomeDesktopMRT: React.FC<{
  viewmodel: HomeViewModel;
}> = observer(function HomePage2DesktopMRT({ viewmodel }) {
  const navigate = useNavigate();
  return (
    <div>
      <div
        className={cx(
          css`
            min-width: 1440px;
            height: 218px;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            background-image: url('./header_background_city.jpg');
            background-size: cover;
            background-position: 0px 66%;
            background-color: bg-trimo-light-gray;
            filter: brightness(50%);
          `,
        )}
      />
      <div
        className={cx(
          css`
            position: relative;
            width: 1060px;
          `,
        )}
      >
        <h1
          className={cx(
            font.LargeTitle2,
            css`
              margin-top: 51px;
              margin-bottom: 27px;
            `,
          )}
        >
          <div>해외 렌터카 최저가로 찾아보세요</div>
        </h1>
        <MRTCarListingSearchBoxDesktop
          viewmodel={viewmodel.carListingSearchBox}
          onBlur={() => viewmodel.carListingSearchBox.stepManager.clearStep()}
        />
        <div
          className={css`
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            margin-top: 24px;
            gap: ${SPACING.spacing16};
          `}
        >
          {fakeBanner.map((banner) => {
            return (
              <MRTBanner
                key={banner.id}
                item={banner}
                onClick={() => {
                  navigate({
                    pathname: NOTICE_DETAIL,
                    search: `${new URLSearchParams({
                      id: String(banner.id),
                    })}`,
                  });
                }}
                hasIcon={false}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
});

import React from 'react';

import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';
import { useRecoilValue } from 'recoil';

import { subdomainState } from '^/recoil/atoms';

import { Layout2Desktop } from './Layout2Desktop';
import { Layout2Mobile } from './Layout2Mobile';
import { LayoutMRT } from './LayoutMRT';

export const Layout: React.FC<React.PropsWithChildren<{
  withoutFooter?: boolean;
  withoutMaxWidth?: boolean;
  withoutOverflowScroll?: boolean;
  scrollAreaKey?: string;
  withoutFooterTopLine?: boolean;
  withoutNavigationFullRefresh?: boolean;
  classOverrides?: {
    layout2MobileContentWrapperBg?: string;
    footerBgColor?: string;
  }
}>> = observer(function Layout2(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const subdomain = useRecoilValue(subdomainState);

  if (subdomain.includes('myrealtrip')) {
    return <LayoutMRT {...props} />;
  }

  if (isMobile) {
    return (
      <Layout2Mobile
        {...props}
      />
    );
  }

  return (
    <Layout2Desktop
      {...props}
    />
  );
});

export default Layout;

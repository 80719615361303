import { z } from 'zod';

import getConfig from '^config';

const config = getConfig();

export const BASE_URL = config.REACT_APP_BASE_API_URL;
export const BASE_LOCAL_URL = config.REACT_APP_BASE_LOCAL_API_URL;
export const API_KEY = config.REACT_APP_API_KEY!;
export const IMP_CREDENTIAL = config.REACT_APP_IMP_CREDENTIAL;
export const IMP_INICIS = config.REACT_APP_IMP_INICIS;
export const HOURS_OF_DAY = 24;
export const ACCESS_TOKEN_NAME = 'token';
export const REFRESH_TOKEN_NAME = 'refresh';
export const LOCALE_STORAGE_NAME = 'locale_code';
export const SUBDOMAIN_NAME = 'subdomain';
export const ROOT = config.REACT_APP_ROOT;
export const MAIN = 'trimo';
export const BOOKING = 'booking';
export const START_INDEX = 1;
export const PAGE_STATUS = {
  DONE: 'done',
  OPEN: '',
  IN_PROGRESS: 'in progress',
};
export const NAME_STEPS = {
  STEP_1: 'selectYourCar',
  STEP_2: 'chooseAddOn',
  STEP_3: 'driverInformation',
  STEP_4: 'pay',
};

export const TABLET_SIZE = 768;
export const INSURANCES = {
  7: 'Collision Damage Waiver',
  24: 'Loss damage waiver',
  27: 'Liability insurance supplement',
  32: 'Personal Accident Insurance',
  36: 'Personal Effects Coverage',
  38: 'Personal property insurance',
  40: 'Super collision damage waiver',
  48: 'Theft protection',
  56: 'Max Cover',
  57: 'AER (Accident Excess Reduction) plus',
  58: 'AER (Accident Excess Reduction)',
};

export const AGE_INFOS = {
  30: 'over30YearsOld',
  29: '29YearsOld',
  28: '28YearsOld',
  27: '27YearsOld',
  26: '26YearsOld',
  25: '25YearsOld',
  24: '24YearsOld',
  23: '23YearsOld',
  22: '22YearsOld',
  21: '21YearsOld',
  20: '20YearsOld',
  // 30: 'Over 30 years old',
  // 29: '29 years old',
  // 28: '28 years old',
  // 27: '27 years old',
  // 26: '26 years old',
  // 25: '25 years old',
  // 24: '24 years old',
  // 23: '23 years old',
  // 22: '22 years old',
  // 21: '21 years old',
  // 20: '20 years old',
};

export const PAYMENT_TYPE = {
  POD: 'payOnSite',
  PPD: 'prePay',
} as const;

export const ERROR_BOOKING = {
  588: 'Surname does not match the name on the Number One Club profile ',
  237: 'Hertz member number not found',
  517: 'Please book a other car',
};

export const CATEGORIES_ID = {
  LAM: 'Train Station',
  LAP: 'Airport',
  LDT: 'Downtown/City',
  LHT: 'Hotel',
} as const;

export const CATEGORY_CODES = ['LAP', 'LDT', 'LHT', 'LAM'] as const;

export const STEP_BOOKING = {
  CAR_DETAILS: 'car_details',
  DRIVER_DETAILS: 'driver_details',
} as const;

export const INITIAL_VEHCLASS = [
  'mini',
  'compact',
  'midsize',
  'intermediate',
  'luxury',
  'oversize',
  'special',
] as const;

export const INITIAL_VEHCLASS_ZOD = z.enum(INITIAL_VEHCLASS);

export const FULL_VEHCLASS = [
  'mini',
  'subcompact',
  'economy',
  'compact',
  'midsize',
  'intermediate',
  'standard',
  'fullsize',
  'luxury',
  'premium',
  'special',
  'minielite',
  'economy elite',
  'compact elite',
  'intermediate elite',
  'standard elite',
  'fullsize elite',
  'premium elite',
  'luxury elite',
  'oversize',
] as const;

export const FULL_VEHCLASS_ZOD = z.enum(FULL_VEHCLASS);

/* eslint-disable max-len */
import { css, cx } from '@emotion/css';
import formatDate from 'date-fns/format';
import { observer } from 'mobx-react-lite';

import { actions, sendTrackingLog } from '^/api/trackingLog';
import { CalendarStateViewModel } from '^/components/Calendar/BasicCalendar/Calendar.state.viewmodel';
import { CalendarListViewModel } from '^/components/Calendar/BasicCalendar/components/List/Calendar.List.viewmodel';
import { getWeekDayFromDate } from '^/components/Calendar/MRTCalendar/components/Day/DayType';
import { ScheduleTimeViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/ScheduleTimeViewModel';
import { BaseSearchBoxStepManager } from '^/components/CarSearchBox/base/desktop/viewmodel/SearchBoxStepManager';
import { SPACING, COLORS2, FLEX } from '^/constants/commonStyles';
import { OpenCloseTimes } from '^/types/RawOpenHours';

import { MRTTimeSelect } from '../../../../../Calendar/MRTCalendar/components/MRTTimeSelect/MRTTimeSelect';

import { defaultCalendarWithTimeMobileStyles } from './CalendarWithTime.Mobile.styles';

export const MRTCalendarWithTimeMobile: React.FC<{
  stepManager: BaseSearchBoxStepManager;
  calendarStateViewModel: CalendarStateViewModel;
  calendarListViewModel: CalendarListViewModel;
  scheduleTimeViewModel: ScheduleTimeViewModel;
  pickupOpenCloseTimes: OpenCloseTimes;
  returnOpenCloseTimes: OpenCloseTimes;
  onBlur?: () => void;
  onSubmit: () => void;
  stylesOverrides?: Partial<typeof defaultCalendarWithTimeMobileStyles>;
}> = observer(function MRTCalendarWithTimeMobile({
  stepManager,
  calendarStateViewModel,
  scheduleTimeViewModel,
  pickupOpenCloseTimes,
  returnOpenCloseTimes,
  onSubmit,
  stylesOverrides,
}) {
  const { startDate, endDate } = calendarStateViewModel;

  if (!startDate || !endDate) {
    return null;
  }
  const startDayOfWeek = getWeekDayFromDate(startDate);
  const startOpenClose = pickupOpenCloseTimes[startDayOfWeek];
  const startDateStr = formatDate(startDate, 'MM월 dd일(eee)');
  const endDayOfWeek = getWeekDayFromDate(endDate);
  const endOpenClose = returnOpenCloseTimes[endDayOfWeek];
  const endDateStr = formatDate(endDate, 'MM월 dd일(eee)');
  const classNames = {
    ...defaultCalendarWithTimeMobileStyles,
    ...(stylesOverrides || {}),
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!startDate || !endDate) {
          return false;
        }
        onSubmit();
        return false;
      }}
      className={css`
        padding: 8px 20px 28px;
        box-shadow: 0px -1px 12px 0px #0000001A;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
          /* border-top: 1px solid black; */
        `}
      >
        {/* left */}
        <div className={classNames.scheduleWrapper}>
          <div className={classNames.title}>대여 일시</div>
          <div className={classNames.dateTimeWrapper}>
            <div className={classNames.date}>{startDateStr}</div>
            <div className={classNames.timeSelectWrapper}>
              <MRTTimeSelect
                hour={scheduleTimeViewModel.pickupHour}
                minute={scheduleTimeViewModel.pickupMinute}
                openDirection="UP"
                openCloseTimes={startOpenClose}
              />
            </div>
          </div>
        </div>

        {/* right */}
        <div className={classNames.scheduleWrapper}>
          <div className={classNames.title}>반납 일시</div>
          <div className={classNames.dateTimeWrapper}>
            <div className={classNames.date}>{endDateStr}</div>
            <div className={classNames.timeSelectWrapper}>
              <MRTTimeSelect
                hour={scheduleTimeViewModel.returnHour}
                minute={scheduleTimeViewModel.returnMinute}
                openCloseTimes={endOpenClose}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={css`
          display: flex;
          flex-direction: column;
          margin-top: 10px;
        `}
      >
        <div
          className={css`
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 4px;
            `}
        >
          <img src="info-circle_14.svg" alt="info" />
          <span
            className={css`
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: -0.09em;
                color: #717171;
             `}
          >
            시간 선택은 영업소 오픈 시간만 선택 가능하며, 1년 이내 예약만 할 수 있어요.
          </span>
        </div>
        <button
          type="submit"
          className={cx(
            FLEX.allDirectionCenter,
            css`
              height: ${SPACING.spacing56};
              width: 100%;

              border-radius: 5px;
              background-color: ${startDate && endDate
              ? COLORS2.main.MrtBlue1
              : COLORS2.main.MrtBlue2} !important;
              color: ${COLORS2.main.MrtWhite};
              margin-top: 12px;
            `,
          )}
          onClick={async () => {
            sendTrackingLog({
              action: actions['/'].SUBMIT_SCHEDULE,
              country: sessionStorage.getItem('selectedCountry') || '',
            });
          }}
        >
          적용
        </button>
      </div>
    </form>
  );
});

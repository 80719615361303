/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable operator-linebreak */
import { Fragment, RefObject, useEffect, useRef, useState } from 'react';

import { cx, css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { BRAND } from 'zod';

import { actions, sendTrackingLog } from '^/api/trackingLog';
import { isOntra } from '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { CarListingSearchBoxViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { Step1RentalCityStep } from '^/components/CarSearchBox/base/desktop/viewmodel/SearchBoxStepManager';
import { popularCityList } from '^/components/CarSearchBox/common/popularCity.constants';
import { MRTCitySearchInputButton } from '^/components/CarSearchBox/myrealtrip/desktop/headerArea/MRTCitySearchInputButton';
import { MRTSearchStepperMobile } from '^/components/CarSearchBox/myrealtrip/mobile/contentsArea/SearchStepper/MRTSearchStepper.mobile';
import { AlertPopup } from '^/components/Popup/AlertPopup/AlertPopup';
import { COLORS2 } from '^/constants/commonStyles';

import { mrtCitiesByCountry } from './mrtCitiesByCountry';

const continents = ['인기도시'].concat(
  mrtCitiesByCountry.map((continentItem) => continentItem.continent),
);

export const MRTSelectPickupCity: React.FC<{
  key: string;
  viewmodel: CarListingSearchBoxViewModel;
  step: Step1RentalCityStep;
  pickupCityInputRef?: RefObject<HTMLInputElement>;
}> = observer(function MRTSelectPickupCity({ key, viewmodel }) {
  const [selectedContinent, setSelectedContinent] = useState(
    viewmodel.i.pickupContinent.value || continents[0],
  );
  const [selectedCountry, setselectedCountry] = useState(viewmodel.i.pickupContry.value || '');

  const citiesRef = useRef<HTMLInputElement>(null);
  const mounted = useRef(false);

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  useEffect(() => {
    if (!citiesRef.current || !mounted.current) {
      mounted.current = true;
      return;
    }
    citiesRef.current.scrollTo({ top: 0 });
  }, [selectedContinent]);

  useEffect(() => {
    if (
      !citiesRef.current ||
      !viewmodel.i.pickupContinent.value ||
      !viewmodel.i.pickupContry.value
    ) {
      return;
    }
    const selectedContinentIndex = mrtCitiesByCountry.findIndex(
      (continentItem) => continentItem.continent === viewmodel.i.pickupContinent.value,
    );
    const selectedContryIndex = mrtCitiesByCountry[selectedContinentIndex].countries.findIndex(
      (contryItem) => contryItem.code === viewmodel.i.pickupContry.value,
    );
    if (selectedContinentIndex < 0 || selectedContryIndex < 0) {
      return;
    }

    citiesRef.current.scrollTo({ top: 50 * selectedContryIndex });
  }, []);

  const findSelectCityInfo = (
    code: string,
  ): {
    contry: string;
    continent: string;
    city: string & BRAND<'CityCode'>;
  } => {
    let contry = '';
    let continent = '';
    let city = '' as string & BRAND<'CityCode'>;
    mrtCitiesByCountry.find((continentItem) => {
      return continentItem.countries.find((contryItem) => {
        return contryItem.cities.find((cityItem) => {
          if (cityItem.code === code) {
            contry = contryItem.code;
            city = cityItem.code as string & BRAND<'CityCode'>;
            continent = continentItem.continent;
            return true;
          }
          return false;
        });
      });
    });

    return { contry, continent, city };
  };
  return (
    <Fragment key={key}>
      <div>
        <div
          className={cx(
            css`
              padding: 0 20px;
              margin-top: 68px;
            `,
          )}
        >
          <MRTSearchStepperMobile currentStep={1} />
          <div
            className={cx(
              css`
                margin-top: 20px;
                color: #1e1e1e;
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
              `,
            )}
          >
            도시를 선택 또는 검색해주세요.
          </div>
          <div
            className={cx(css`
              margin-top: 16px;
            `)}
          >
            <MRTCitySearchInputButton
              placeholder="도시이름을 검색해보세요"
              // inputRef={citySearchInputRef}
              viewmodel={viewmodel.i.tempPickupCitySearch}
              title=""
              // showSuggestions
              onSelectCity={(cityCode, cityKoName) => {
                const { contry, continent } = findSelectCityInfo(cityCode);
                viewmodel.i.pickupContinent.set(continent);
                viewmodel.i.pickupContry.set(contry);
                viewmodel.i.pickupLocationId.set(null);
                viewmodel.i.tempPickupCitySearch.selectedCityCode.set(cityCode);
                viewmodel.i.tempPickupCitySearch.tempSearchInput.set(cityKoName);

                if (
                  viewmodel.i.hasDifferentPickupReturnLocations.value &&
                  isOntra(viewmodel.i.tempPickupCitySearch.selectedCityCode.value)
                ) {
                  viewmodel.showDifferentLocationAlert.set(true);
                  viewmodel.i.pickupCitySearch.selectedCityCode.set(null);
                  viewmodel.i.pickupCitySearch.searchInput.set('');
                }
                viewmodel.stepManager.pushStep({ type: '2_rental_office' });
              }}
              inputElemProps={{
                autoFocus: true,
              }}
            />
          </div>
        </div>
        <div>
          <div
            className={cx(
              css`
                display: flex;
                margin-top: 20px;
                border-top: 1px solid #f4f4f4;
              `,
            )}
          >
            <div
              className={cx(
                css`
                  flex: 1;
                  background-color: #fafafa;
                  /* height: 100vh; */
                `,
              )}
            >
              {continents.map((continent) => {
                return (
                  <div
                    role="button"
                    tabIndex={0}
                    className={cx(
                      css`
                        height: 50px;
                        border-bottom: 1px solid #f4f4f4;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22px;
                        display: flex;
                        align-items: center;
                        padding-left: 16px;
                        width: 100%;
                        background-color: ${selectedContinent === continent ? '#fff' : '#FAFAFA'};
                        color: ${selectedContinent === continent ? '#1E1E1E' : '#575757'};
                      `,
                    )}
                    onClick={() => {
                      setSelectedContinent(continent);
                      viewmodel.i.pickupContinent.set(continent);
                    }}
                  >
                    {continent}
                  </div>
                );
              })}
            </div>
            <div
              className={cx(
                css`
                  overflow-y: scroll;
                  height: calc(var(--vh) * 100 - 221px);
                  flex: 1.75;
                  padding-left: 12px;
                `,
              )}
              ref={citiesRef}
            >
              {selectedContinent === '인기도시'
                ? popularCityList.map((city) => (
                    <div
                      role="menuitem"
                      tabIndex={-1}
                      onClick={() => {
                        viewmodel.i.pickupContinent.set(selectedContinent);
                        viewmodel.i.pickupLocationId.set(null);
                        viewmodel.i.tempPickupCitySearch.selectedCityCode.set(city.code);
                        viewmodel.i.tempPickupCitySearch.tempSearchInput.set(city.nameKo);
                        if (
                          viewmodel.i.hasDifferentPickupReturnLocations.value &&
                          isOntra(viewmodel.i.tempPickupCitySearch.selectedCityCode.value)
                        ) {
                          viewmodel.showDifferentLocationAlert.set(true);
                          viewmodel.i.pickupCitySearch.selectedCityCode.set(null);
                          viewmodel.i.pickupCitySearch.searchInput.set('');
                          return;
                        }
                        viewmodel.stepManager.pushStep({ type: '2_rental_office' });
                        sessionStorage.setItem('selectedCountry', city.countryCode);
                        sendTrackingLog({
                          action: actions['/'].SELECT_POPULAR_CITY,
                          data: city.nameKo,
                          country: city.countryCode,
                        });
                      }}
                      key={city.code}
                      className={cx(
                        css`
                          height: 50px;
                          border-bottom: 1px solid #f4f4f4;
                          font-weight: 500;
                          font-size: 16px;
                          line-height: 22px;
                          display: flex;
                          align-items: center;
                          padding-left: 16px;
                          cursor: pointer;
                        `,
                        city.code === viewmodel.i.pickupCitySearch.selectedCityCode.value &&
                          css`
                            color: ${COLORS2.main.MrtBlue1};
                            display: flex;
                            justify-content: space-between;
                            cursor: pointer;
                            align-items: center;
                          `,
                      )}
                    >
                      {city.nameKo}
                      {city.code === viewmodel.i.pickupCitySearch.selectedCityCode.value && (
                        <img
                          src="CkCheck.svg"
                          alt="CkCheck"
                          className={css`
                            margin-right: 16px;
                          `}
                        />
                      )}
                    </div>
                  ))
                : mrtCitiesByCountry
                    .find((continentItem) => continentItem.continent === selectedContinent)
                    ?.countries.map((contryItem) => {
                      return (
                        <>
                          <div
                            role="menuitem"
                            tabIndex={-1}
                            onClick={() => {
                              if (selectedCountry === contryItem.code) {
                                viewmodel.i.pickupContry.set('');
                                setselectedCountry('');
                                return;
                              }
                              viewmodel.i.pickupContry.set(contryItem.code);
                              setselectedCountry(contryItem.code);
                            }}
                            key={contryItem.code}
                            className={cx(
                              css`
                                display: flex;
                                justify-content: space-between;
                                height: 50px;
                                border-bottom: 1px solid #f4f4f4;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 22px;
                                display: flex;
                                align-items: center;
                                padding: 0 16px;
                              `,
                            )}
                          >
                            <div>{contryItem.nameKo}</div>
                            <img
                              src={
                                contryItem.code === selectedCountry
                                  ? 'chevron-up_20.svg'
                                  : 'chevron-down_20.svg'
                              }
                              alt="chevron"
                            />
                          </div>
                          {contryItem.code === selectedCountry && (
                            <div>
                              {contryItem.cities.map((cityItem) => {
                                return (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    className={cx(
                                      css`
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        margin-left: 16px;
                                        border-bottom: 1px solid #f4f4f4;
                                        height: 48px;
                                        font-size: 14px;
                                        line-height: 20px;
                                        font-weight: 500;
                                        color: #575757;
                                        padding-right: 15px;
                                      `,
                                      cityItem.code === viewmodel.i.pickupCityCode.value &&
                                        css`
                                          color: ${COLORS2.main.MrtBlue1};
                                        `,
                                    )}
                                    onClick={() => {
                                      // FIXME: 대여일 경우, 반납일 경우 pickupCitySearch 수정해줘야 함
                                      viewmodel.i.pickupLocationId.set(null);
                                      viewmodel.i.tempPickupCitySearch.selectedCityCode.set(
                                        cityItem.code,
                                      );
                                      viewmodel.i.tempPickupCitySearch.tempSearchInput.set(
                                        cityItem.nameKo,
                                      );
                                      if (
                                        viewmodel.i.hasDifferentPickupReturnLocations.value &&
                                        isOntra(
                                          viewmodel.i.tempPickupCitySearch.selectedCityCode.value,
                                        )
                                      ) {
                                        viewmodel.showDifferentLocationAlert.set(true);
                                        viewmodel.i.pickupCitySearch.selectedCityCode.set(null);
                                        viewmodel.i.pickupCitySearch.searchInput.set('');
                                        return;
                                      }
                                      viewmodel.stepManager.pushStep({ type: '2_rental_office' });
                                      sessionStorage.setItem('selectedCountry', contryItem.code);
                                      sendTrackingLog({
                                        action: actions['/'].SELECT_POPULAR_CITY,
                                        data: cityItem.nameKo,
                                        country: contryItem.code,
                                      });
                                    }}
                                  >
                                    {cityItem.nameKo}
                                    {cityItem.code ===
                                      viewmodel.i.pickupCitySearch.selectedCityCode.value && (
                                      <img src="CkCheck.svg" alt="CkCheck" />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </>
                      );
                    })}
            </div>
          </div>
        </div>
      </div>
      <AlertPopup
        visible={viewmodel.showDifferentLocationAlert}
        title="해당 도시는 대여/반납 장소를 다르게 지정할 수 없습니다."
        closeText="닫기"
        handleClose={() => viewmodel.showDifferentLocationAlert.set(false)}
        isMobile
      />
    </Fragment>
  );
});

import { makeAutoObservable } from 'mobx';

import { AppContext } from '^/AppContext/AppContext';
import { CalendarStateViewModel } from '^/components/Calendar/BasicCalendar/Calendar.state.viewmodel';
import { CitySearchInputViewModel } from '^/components/CarSearchBox/base/desktop/headerArea/CitySearchInput/CitySearchInput.viewmodel';
import { popularCityList } from '^/components/CarSearchBox/common/popularCity.constants';
import { ListingPage2RouteParams } from '^/pages/Listing/ListingPage2RouteParams';
import { ShortBirthdayType, toShortBirthday } from '^/types/__BrandedDateTimeTypes';
import { CityCodeType, RentalCarOfficeId } from '^/types/__BrandedLocationTypes';
import { FreshnessType } from '^/types/__ResultType';
import { MobxInputValue } from '^/util/MobxInputValue';

import {
  createScheduleTimeViewModelFromDefaults,
  ScheduleTimeViewModel,
} from './ScheduleTimeViewModel';

export class CarListingSearchBoxInputViewModel {
  constructor(
    public appContext: AppContext,
    public freshness: FreshnessType,
    public calendarStateViewModel: CalendarStateViewModel,
    public defaultValues?: ListingPage2RouteParams,
    public pickupCityCode = new MobxInputValue<CityCodeType | null>(
      defaultValues?.pCityCode || null,
      (cityCode) => {
        if (!cityCode) {
          return;
        }
        const city = this.appContext.searchCityRepo.getById(cityCode);
        this.pickupCitySearch.searchInput.set(city?.korean_name || 'empty');
      },
    ),
    public tempPickupCityCode = new MobxInputValue<CityCodeType | null>(
      defaultValues?.pCityCode || null,
    ),
    public tempReturnCityCode = new MobxInputValue<CityCodeType | null>(
      defaultValues?.rCityCode || null,
    ),
    public pickupLocationId = new MobxInputValue<RentalCarOfficeId | null>(
      defaultValues?.pLocationId || null,
    ),
    public tempPickupLocationId = new MobxInputValue<RentalCarOfficeId | null>(
      defaultValues?.pLocationId || null,
    ),
    public tempReturnLocationId = new MobxInputValue<RentalCarOfficeId | null>(
      defaultValues?.rLocationId || null,
    ),
    public returnCityCode = new MobxInputValue<CityCodeType | null>(
      defaultValues?.rCityCode || null,
      (cityCode) => {
        // this.returnLocationId.set(null);
        if (!cityCode) {
          return;
        }
        const city = this.appContext.searchCityRepo.getById(cityCode);
        if (!city?.korean_name) {
          return;
        }
        this.returnCitySearch.searchInput.set(city?.korean_name);
      },
    ),
    public pickupCitySearch = new CitySearchInputViewModel(
      appContext,
      pickupCityCode,
      freshness,
      new MobxInputValue(defaultValues?.pCityName || ''),
    ),
    public tempPickupCitySearch = new CitySearchInputViewModel(
      appContext,
      tempPickupCityCode,
      freshness,
      new MobxInputValue(defaultValues?.rCityName || ''),
    ),

    public tempReturnCitySearch = new CitySearchInputViewModel(
      appContext,
      tempReturnCityCode,
      freshness,
      new MobxInputValue(defaultValues?.rCityName || ''),
    ),

    public birthdayInput = new MobxInputValue<ShortBirthdayType>(
      defaultValues?.birthday ? toShortBirthday(defaultValues.birthday) : ('' as ShortBirthdayType),
      (v) => v.replace(/\D/g, '').slice(0, 6) as ShortBirthdayType,
    ),

    public scheduleInput = new MobxInputValue(''),
    public hasDifferentPickupReturnLocations = new MobxInputValue(defaultValues?.pIsDiff || false),
    public returnCitySearch = new CitySearchInputViewModel(
      appContext,
      returnCityCode,
      freshness,
      new MobxInputValue<string>(defaultValues?.rCityName || '', () => {
        // this.returnLocationId.set(null);
      }),
    ),
    public returnLocationId = new MobxInputValue<RentalCarOfficeId | null>(
      defaultValues?.rLocationId || null,
      (value) => {
        console.log('returnLocationId set', value);
        this.calendarStateViewModel.selection = {
          start: null,
          end: null,
        };
        this.scheduleTimeViewModel.reset();
      },
    ),
    public scheduleTimeViewModel = createScheduleTimeViewModelFromDefaults(
      defaultValues?.pDatetime,
      defaultValues?.rDatetime,
    ),
    public pickupContinent = new MobxInputValue(''),
    public pickupContry = new MobxInputValue(''),
    public returnContinent = new MobxInputValue(''),
    public returnContry = new MobxInputValue(''),
  ) {
    makeAutoObservable(this);

    popularCityList.forEach((city) => {
      this.appContext.searchCityRepo.setById(city.code, {
        code: city.code,
        korean_name: city.nameKo,
        name: city.name,
      });
    });
  }

  // get tempPickupCitySearch() {
  //   return new CitySearchInputViewModel(
  //     this.pickupCitySearch.appContext,
  //     this.pickupCitySearch.selectedCityCode.clone(),
  //     this.pickupCitySearch.freshness,
  //   );
  // }

  // get tempReturnCitySearch() {
  //   return new CitySearchInputViewModel(
  //     this.returnCitySearch.appContext,
  //     this.returnCitySearch.selectedCityCode,
  //     this.returnCitySearch.freshness,
  //   );
  // }

  get tempScheduleTimeViewModel() {
    return new ScheduleTimeViewModel(
      this.scheduleTimeViewModel.pickupHour,
      this.scheduleTimeViewModel.pickupMinute,
      this.scheduleTimeViewModel.returnHour,
      this.scheduleTimeViewModel.returnMinute,
    );
  }

  get tempCalendarStateViewModel() {
    return new CalendarStateViewModel(
      this.calendarStateViewModel.calendarPaginationOffset.clone(),
      this.calendarStateViewModel.today,
      this.calendarStateViewModel.enabledLastDay,
      this.calendarStateViewModel.selection,
      this.calendarStateViewModel.hoverDay,
    );
  }

  get tempBirthdayInput() {
    return this.birthdayInput.clone();
  }

  // get tempPickupLocationId() {
  //   return this.pickupLocationId.clone();
  // }

  // get tempReturnLocationId() {
  //   return this.returnLocationId.clone();
  // }

  reset() {
    this.pickupLocationId.set(null);
    this.pickupCityCode.set(null);
    this.returnLocationId.set(null);
    this.returnCityCode.set(null);
    this.pickupCitySearch.searchInput.set('');
    this.returnCitySearch.searchInput.set('');
  }
}

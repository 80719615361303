// REACT_APP_API_KEY = NDk0NWE3MGEtZWRmYy00NGE4LTgzMmQtN2Q3ZGE4ZjYyNDAy
// REACT_APP_BASE_API_URL = https://api.trimo.ai/
// REACT_APP_GOOGLE_MAPS_API_KEY = AIzaSyAhfJmkyl9D_flckH7J7lEUK-LxeZKnWuU
// REACT_APP_IMP_CREDENTIAL = imp73412023
// REACT_APP_KAKAO_CLIENT_SECRET = K4RNOfAE12D2mFLvL2ZdaUEbL9a2spZW
// REACT_APP_KAKAO_REST_API_KEY = 43c23c7ecfadc7c4b22337d8e0fbfe6c
// REACT_APP_ROOT = trimo
// REACT_APP_TRANSLATION_PATH = https://trimo-rental-car.s3.ap-southeast-1.amazonaws.com/translation/{{lng}}.json

import { ConfigType } from './ConfigType';

export const ProdConfig: ConfigType = {
  REACT_APP_API_KEY: 'NDk0NWE3MGEtZWRmYy00NGE4LTgzMmQtN2Q3ZGE4ZjYyNDAy',
  REACT_APP_BASE_API_URL: 'https://api.trimo.ai/',
  REACT_APP_BASE_LOCAL_API_URL: 'http://localhost:5555/',
  REACT_APP_GOOGLE_MAPS_API_KEY: 'AIzaSyAhfJmkyl9D_flckH7J7lEUK-LxeZKnWuU',
  REACT_APP_IMP_CREDENTIAL: 'imp73412023',
  REACT_APP_IMP_INICIS: 'MOItrimo11',
  REACT_APP_KAKAO_REST_API_KEY: 'K4RNOfAE12D2mFLvL2ZdaUEbL9a2spZW',
  REACT_APP_KAKAO_CLIENT_SECRET: '43c23c7ecfadc7c4b22337d8e0fbfe6c',
  REACT_APP_ROOT: 'trimo',
  REACT_APP_TRANSLATION_PATH:
    'https://trimo-rental-car.s3.ap-southeast-1.amazonaws.com/translation/{{lng}}.json',

  CONFIG_NAME: 'prod',
};

export default function getProdConfig() {
  return ProdConfig;
}

/* eslint-disable react/jsx-no-bind */
import { useEffect } from 'react';

import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { Footer3Mobile } from '^/components/Footer3/Footer3.Mobile';
import Layout1 from '^/components/Layout';
import LoadingWithVendorLogo from '^/components/Loading/LoadingWithVendorLogo';
import { MARGINS } from '^/constants/commonStyles';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { increaseFreshness } from '^/types/__ResultType';

import { loadPhoneEmailFromStorage } from '../ReservationCheck/ReservationCheck.form.viewmodel';

import ConfirmReservationCancelPopup from './ConfirmReservationCancelPopup';
import { ReservationDetailPageDesktop } from './desktop/ReservationDetailsPage.Desktop';
import { ReservationDetailPageMobile } from './mobile/ReservationDetailsPage.Mobile';
import { ReservationDetailsPageViewModel } from './ReservationDetailsPage.viewmodel';

export const ReservationDetailsPage: React.FC<{
  viewmodel: ReservationDetailsPageViewModel;
}> = observer(function ReservationDetailsPage({ viewmodel }) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (!loadPhoneEmailFromStorage()) {
      window.location.pathname = '/reservation_check';
    }
  }, []);

  function onClickCancelReservation() {
    viewmodel.showCancelConfirmPopup.set(true);
  }

  async function onClickCancelReservationConfirm(cancelReason) {
    // FIXME: 로딩 시, 에러 시 기획 필요...
    const cancelResult = await viewmodel.cancelReservation(cancelReason);
    viewmodel.showCancelConfirmPopup.set(false);
    runInAction(() => {
      // eslint-disable-next-line no-param-reassign
      viewmodel.freshness = increaseFreshness(viewmodel.freshness);
    });
    typeRouter.session.back();
    setTimeout(() => {
      window.location.replace(window.location.href);
    }, 100);
  }

  if (isMobile) {
    return (
      <>
        <Layout1 withoutFooter>
          {viewmodel.showLoading.value === true && <LoadingWithVendorLogo />}
          <ConfirmReservationCancelPopup
            visible={viewmodel.showCancelConfirmPopup}
            handleCancel={() => viewmodel.showCancelConfirmPopup.set(false)}
            handleConfirm={onClickCancelReservationConfirm}
            isMobile={isMobile}
          />
          <ReservationDetailPageMobile
            viewmodel={viewmodel}
            onClickCancelReservation={onClickCancelReservation}
          />
        </Layout1>
        <div className={MARGINS.TOP.spacing32}>
          <Footer3Mobile />
        </div>
      </>
    );
  }
  return (
    <>
      {viewmodel.showLoading.value === true && <LoadingWithVendorLogo />}
      <ReservationDetailPageDesktop
        viewmodel={viewmodel}
        onClickCancelReservation={onClickCancelReservation}
      />
      <ConfirmReservationCancelPopup
        visible={viewmodel.showCancelConfirmPopup}
        handleCancel={() => viewmodel.showCancelConfirmPopup.set(false)}
        handleConfirm={onClickCancelReservationConfirm}
        isMobile={isMobile}
      />
    </>
  );
});

/* eslint-disable operator-linebreak */
import React from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { actions, sendTrackingLog } from '^/api/trackingLog';
import { isOntra } from '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { CalendarWithTimeDesktop } from '^/components/CarSearchBox/base/common/CalendarWithTime/CalendarWithTime.Desktop';
import { COLORS2 } from '^/constants/commonStyles';

import { CarListingSearchBoxViewModel } from '../../../base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { SearchBoxSteps } from '../../../base/desktop/viewmodel/SearchBoxStepManager';

import { MRTPopularCity } from './SelectCity/MRTPopularCity.Desktop';
import { MRTSelectOfficeList } from './SelectOffice/MRTSelectOfficeList';

export interface ContentsByStepProps {
  viewmodel: CarListingSearchBoxViewModel;
  step: typeof SearchBoxSteps[number]['type'];
}

export const ContentsByStep: React.FC<ContentsByStepProps> = observer(function ContentsByStep({
  viewmodel,
  step,
}) {
  if (step === '1_rental_city') {
    return (
      <MRTPopularCity
        viewmodel={viewmodel}
        inputViewmodel={viewmodel.i.tempPickupCitySearch}
        onSelectCity={(cityCode) => {
          if (viewmodel.i.hasDifferentPickupReturnLocations.value && isOntra(cityCode)) {
            viewmodel.showDifferentLocationAlert.set(true);
            viewmodel.i.tempPickupCitySearch.selectedCityCode.set(null);
            viewmodel.i.tempPickupCitySearch.tempSearchInput.set('');
            viewmodel.i.tempPickupCitySearch.searchInput.set('');
            viewmodel.i.pickupCitySearch.searchInput.set('');
            viewmodel.stepManager.replaceStep({ type: '1_rental_city' });
            return;
          }
          viewmodel.i.tempPickupCityCode.set(cityCode);
          viewmodel.stepManager.replaceStep({ type: '2_rental_office' });
        }}
      />
    );
  }
  if (step === '2_rental_office') {
    const pickupVM = viewmodel.getLocationInputViewModelByCityCode(
      viewmodel.i.tempPickupCityCode.value,
      viewmodel.i.tempPickupLocationId,
    );
    return (
      <MRTSelectOfficeList
        viewmodel={viewmodel}
        officeViewmodel={pickupVM}
        searchInputViewmodel={viewmodel.i.tempPickupCitySearch}
        selectedCityName={
          viewmodel.i.tempPickupCitySearch.tempSearchInput.value ||
          viewmodel.i.pickupCitySearch.searchInput.value
        }
        onResetCity={() => {
          viewmodel.i.pickupCitySearch.selectedCityCode.set(null);
          viewmodel.i.pickupCitySearch.searchInput.set('');
          viewmodel.i.pickupLocationId.set(null);
          viewmodel.i.pickupContinent.set('');
          viewmodel.i.pickupContry.set('');
          viewmodel.i.tempPickupLocationId.set(null);
          viewmodel.stepManager.replaceStep({ type: '1_rental_city' });
        }}
        selectedRentalOffice={viewmodel.i.pickupLocationId.value}
        onSelectRentalOffice={(officeId) => {
          viewmodel.i.tempPickupLocationId.set(officeId);
        }}
        showOfficeInfo={
          !!viewmodel.i.tempPickupLocationId.value || !!viewmodel.i.pickupLocationId.value
        }
        onSubmit={() => {
          viewmodel.i.pickupLocationId.copyFrom(viewmodel.i.tempPickupLocationId);
          viewmodel.i.pickupCityCode.copyFrom(viewmodel.i.tempPickupCityCode);
          viewmodel.i.pickupCitySearch.searchInput.copyFrom(
            viewmodel.i.tempPickupCitySearch.tempSearchInput,
          );
          sendTrackingLog({
            action: actions['/'].SELECT_PICKUP_LOCATION,
            data: viewmodel.pickupLocation?.locationDescriptions.get('ko')?.description,
          });
          viewmodel.stepManager.replaceStep({ type: '3_schedule' });
        }}
        isSubmitBtnDisabled={viewmodel.i.tempPickupLocationId.value === null}
      />
    );
  }
  if (step === '3_schedule') {
    const returnLocation =
      viewmodel.i.hasDifferentPickupReturnLocations.value === true
        ? viewmodel.returnLocation
        : viewmodel.pickupLocation;

    if (!viewmodel.pickupLocation || !returnLocation) {
      console.error(
        'SHOULD NOT HAPPEN!',
        '!viewmodel.pickupLocation || !viewmodel.returnLocation',
        'inside: ',
      );
      return null;
    }
    return (
      <div
        className={cx(
          css`
            width: 606px;
            background-color: ${COLORS2.main.MrtWhite};
            border-radius: 12px;
          `,
        )}
      >
        <CalendarWithTimeDesktop
          hasDifferentPickupReturnLocations={viewmodel.i.hasDifferentPickupReturnLocations}
          pickupLocation={viewmodel.pickupLocation}
          returnLocation={returnLocation}
          stepManager={viewmodel.stepManager}
          calendarStateViewModel={viewmodel.i.tempCalendarStateViewModel}
          calendarListViewModel={viewmodel.calendarListViewModel}
          scheduleTimeViewModel={viewmodel.i.tempScheduleTimeViewModel}
          onAllDateSelected={() => {
            viewmodel.stepManager.replaceStep({
              type: '4_birthday',
            });
          }}
          onSubmit={() => {
            viewmodel.i.calendarStateViewModel.copyFrom(viewmodel.i.tempCalendarStateViewModel);
            viewmodel.i.scheduleTimeViewModel.copyFrom(viewmodel.i.tempScheduleTimeViewModel);
          }}
          isMobile={false}
          onBlur={() => {
            viewmodel.i.tempScheduleTimeViewModel.copyFrom(viewmodel.i.scheduleTimeViewModel);
          }}
          subdomain="myrealtrip"
        />
      </div>
    );
  }
  if (step === '5_return_city') {
    return (
      <MRTPopularCity
        viewmodel={viewmodel}
        inputViewmodel={viewmodel.i.tempReturnCitySearch}
        onSelectCity={(cityCode) => {
          if (viewmodel.i.hasDifferentPickupReturnLocations.value && isOntra(cityCode)) {
            viewmodel.showDifferentLocationAlert.set(true);
            viewmodel.i.tempReturnCitySearch.selectedCityCode.set(null);
            viewmodel.i.tempReturnCitySearch.tempSearchInput.set('');
            viewmodel.i.tempReturnCitySearch.searchInput.set('');
            viewmodel.i.returnCitySearch.searchInput.set('');
            viewmodel.stepManager.replaceStep({ type: '5_return_city' });
            return;
          }
          viewmodel.i.tempReturnCityCode.set(cityCode);
          viewmodel.stepManager.replaceStep({ type: '6_return_office' });
        }}
      />
    );
  }
  if (step === '6_return_office') {
    const returnVM = viewmodel.getLocationInputViewModelByCityCode(
      viewmodel.i.tempReturnCityCode.value,
      viewmodel.i.tempReturnLocationId,
    );
    return (
      <MRTSelectOfficeList
        viewmodel={viewmodel}
        officeViewmodel={returnVM}
        searchInputViewmodel={viewmodel.i.tempReturnCitySearch}
        selectedCityName={
          viewmodel.i.tempReturnCitySearch.tempSearchInput.value ||
          viewmodel.i.returnCitySearch.searchInput.value
        }
        onResetCity={() => {
          viewmodel.i.returnCitySearch.selectedCityCode.set(null);
          viewmodel.i.returnCitySearch.searchInput.set('');
          viewmodel.i.returnLocationId.set(null);
          viewmodel.i.returnContinent.set('');
          viewmodel.i.returnContry.set('');
          viewmodel.i.tempReturnLocationId.set(null);
          viewmodel.stepManager.replaceStep({ type: '5_return_city' });
        }}
        selectedRentalOffice={viewmodel.i.returnLocationId.value}
        onSelectRentalOffice={(officeId) => {
          viewmodel.i.tempReturnLocationId.set(officeId);
        }}
        showOfficeInfo={
          !!viewmodel.i.tempReturnLocationId.value || !!viewmodel.i.returnLocationId.value
        }
        onSubmit={async () => {
          viewmodel.i.returnLocationId.copyFrom(viewmodel.i.tempReturnLocationId);
          viewmodel.i.returnCityCode.copyFrom(viewmodel.i.tempReturnCityCode);
          viewmodel.i.returnCitySearch.searchInput.copyFrom(
            viewmodel.i.tempReturnCitySearch.tempSearchInput,
          );
          await sendTrackingLog({
            action: actions['/'].SELECT_PICKUP_LOCATION,
            data: viewmodel.returnLocation?.locationDescriptions.get('ko')?.description,
          });
          viewmodel.stepManager.replaceStep({ type: '3_schedule' });
        }}
        isSubmitBtnDisabled={viewmodel.i.tempReturnLocationId.value === null}
      />
    );
  }
  return null;
});

/* eslint-disable @typescript-eslint/indent */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';
import { useRecoilValue } from 'recoil';

import { actions, sendTrackingLog } from '^/api/trackingLog';
import { isOntra } from '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { AppContext } from '^/AppContext/AppContext';
import { AlertPopup } from '^/components/Popup/AlertPopup/AlertPopup';
import { subdomainState } from '^/recoil/atoms';

import { ListingPage2Desktop } from './base/desktop/ListingPage2Desktop';
import { ListingPage2Mobile } from './base/mobile/ListingPage2Mobile';
import { ListingPage2ViewModel } from './base/viewmodel/ListingPage2.viewmodel';
import { ListingPage2RouteParams } from './ListingPage2RouteParams';
import { ListingPageMRTDesktop } from './myrealtrip/ListingPage.MRT.Desktop';
import { ListingPageOnlineTravelWrapper } from './onlinetravel/ListingPage.OnlineTravel.Wrapper';
import { ListingPageOnlineTravelViewModel } from './onlinetravel/viewmodel/ListingPage.OnlineTravel.viewmodel';

export const ListingPage2Wrapper: React.FC<
  {
    appContext: AppContext;
  } & ListingPage2RouteParams
> = observer(function ListingPage2Wrapper(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    const pageEnterTrackingLog = async () => {
      sendTrackingLog({
        action: actions.CAR_LIST.ENTER,
      });
    };
    pageEnterTrackingLog();
  }, []);

  const { appContext, ...routeParams } = props;

  const { steps } = routeParams;
  const viewmodel = useMemo(
    () =>
      new ListingPage2ViewModel(
        appContext,
        routeParams,
        13,
        isMobile,
        undefined,
        routeParams.freshness,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      routeParams.pLocationId,
      routeParams.rLocationId,
      routeParams.pDatetime,
      routeParams.rDatetime,
      routeParams.birthday,
      routeParams.freshness,
    ],
  );

  useEffect(() => {
    viewmodel.carListingSearchBox.stepManager.updateRouteParams(routeParams);
  }, [steps]);

  useEffect(() => {
    // save listing path for booking error
    sessionStorage.setItem('listingSearchParam', new URL(window.location.href).search);
  }, [routeParams.pLocationId, routeParams.rLocationId, routeParams.pDatetime, routeParams.rDatetime, routeParams.birthday]);

  const isOnlineTravel = isOntra(routeParams.pCityCode);
  const subdomain = useRecoilValue(subdomainState);

  const isMRT = subdomain.includes('myrealtrip');

  if (isOnlineTravel) {
    const onlineTravelVM = new ListingPageOnlineTravelViewModel(
      appContext,
      routeParams,
      13,
      isMobile,
      undefined,
      routeParams.freshness,
    );
    return <ListingPageOnlineTravelWrapper viewmodel={onlineTravelVM} />;
  }

  if (isMobile) {
    return (
      <>
        <AlertPopup
          visible={viewmodel.carListingSearchBox.showDifferentLocationAlert}
          title="해당 지역은 대여/반납 장소를 다르게 지정할 수 없습니다."
          closeText="닫기"
          // eslint-disable-next-line react/jsx-no-bind
          handleClose={() => viewmodel.carListingSearchBox.showDifferentLocationAlert.set(false)}
          isMobile
        />
        <ListingPage2Mobile viewmodel={viewmodel} />
      </>
    );
  }

  return (
    <>
      <AlertPopup
        visible={viewmodel.carListingSearchBox.showDifferentLocationAlert}
        title="해당 지역은 대여/반납 장소를 다르게 지정할 수 없습니다."
        closeText="닫기"
        // eslint-disable-next-line react/jsx-no-bind
        handleClose={() => viewmodel.carListingSearchBox.showDifferentLocationAlert.set(false)}
        isMobile
      />
      {isMRT ? (
        <ListingPageMRTDesktop viewmodel={viewmodel} />
      ) : (
        <ListingPage2Desktop viewmodel={viewmodel} />
      )}
    </>
  );
});

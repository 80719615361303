import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { css, cx } from '@emotion/css';

const BANNER_TYPES = {
  clock: './badge_alarm.png',
  speaker: './badge_speaker.png',
  bell: './badge_notice.png',
  calendar: './badge_calendar.webp',
};
const SpanNotice = ({ content, type, hasIcon = true }) => {
  return (
    <div className={css`
      background-color: #F2F4F6;
      border-radius: 100px;
      width: fit-content;
      padding: 4px 8px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #4E5968;
    `}>
      {hasIcon && <img className="" src={BANNER_TYPES[type]} alt="banner-type" />}
      <span className="">{content}</span>
    </div>
  );
};
SpanNotice.propTypes = {
  content: PropTypes.string,
  type: PropTypes.string,
  hasIcon: PropTypes.bool,
};

SpanNotice.propTypes = {
  size: PropTypes.string,
};
const MRTBanner = (props) => {
  const { item, onClick, hasIcon } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const containerClassNames = css`
    box-shadow: 0px 2px 4px -1px #0000000F;
    box-shadow: 0px 4px 6px -1px #0000001A;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    cursor: pointer;
  `;
  const contentsClassNames = css`
    padding: 16px;
  `;
  const imageClassNames = css`
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  `;
  const divImagesClassNames = css`
  `;
  const sortTitleClassNames = css`
    margin-top: 8px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: #191F28;
  `;

  const title2ClassName = css`
    margin-top: 8px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
    color: #6B7684;
  `;
  return (
    <div className={containerClassNames} onClick={onClick}>
      <div className={css`
        overflow: hidden;
        position: relative;
      `}>

        <img className={imageClassNames} src={`${isMobile ? item.image_MRT_mobile : item.image_MRT_desktop}`} alt="banner-image" />
      </div>
      <div className={contentsClassNames}>
        <SpanNotice type={item.type} content={item.notice} hasIcon={hasIcon} />
        <p dangerouslySetInnerHTML={{ __html: item.sortTitle }} className={sortTitleClassNames} />
        <p dangerouslySetInnerHTML={{ __html: item.sortContent }} className={title2ClassName} />
      </div>
    </div>
  );
};
MRTBanner.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  hasIcon: PropTypes.bool,
};
export default MRTBanner;

/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import {
  borders,
  COLORS,
  SPACING,
  MARGINS,
  PADDINGS,
  COLORS2,
  COLORS2_AS_BACKGROUND,
  COLORS2_AS_FOREGROUND,
  font2,
} from '^/constants/commonStyles';
import { isReservationCancelDisabled } from '^/pages/ReservationList/isReservationDisabled';
import { ReservationDetailed } from '^/types/ReservationDetailed/RawReservationDetailed';
import { formatKRWWonAmount } from '^/util/formatKRWWonAmount';
import { parseFloatNumberOrNumberString } from '^/util/zod/numberFromString';

import { HoverToReveal } from '../../../../commonComponents/ui/HoverToReveal';
import { AdditionalFees2 } from '../../AdditionalFees2';
import { PODOnsitePaymentHoverMessage } from '../../onsiteInfoMessages';

import { CancelButtonStyle } from './ReservationDetailDesktop.commonStuff';
import { actions, sendTrackingLog } from '^/api/trackingLog';

const wonCurrency = 'KRW';

function isBookedAndPODReservation(data: ReservationDetailed) {
  if (data.res_status !== 'booked' || data.pay_type !== 'POD') {
    throw new Error('DummyErrorForTypeFiltering');
  }
  return data;
}

export const ReservationDetailsBillPODBooked: React.FC<{
  data: ReturnType<typeof isBookedAndPODReservation>;
  onClickCancelReservation(reservationToCancel: ReservationDetailed): void;
}> = observer(function ReservationDetailsBillPODBooked({ data, onClickCancelReservation }) {
  const { t } = useTranslation();
  const pickupDate = new Date(data.pickup_datetime);
  // const tax = data.tax && JSON.parse(data.tax);
  const isCancelDisabled = isReservationCancelDisabled({
    pickupDate,
    cancelEnabled: data.cancelEnabled,
    payType: data.pay_type,
  });
  const currency = data.pay_type === 'POD' ? data.currency : data.extra_equipments_json.currency;
  const total_amount = parseFloatNumberOrNumberString(data.total_amount);
  const total_foreigner_amount = parseFloatNumberOrNumberString(data.total_foreigner_amount);
  const rate_amount = parseFloatNumberOrNumberString(data.rate_amount);
  const rate_foreigner_amount = parseFloatNumberOrNumberString(data.rate_foreigner_amount);
  return (
    <div
      className={[
        // borders.DEBUG_blueBorderDashed,
      ].join(' ')}
    >
      <h3 className={[font2.Title3].join(' ')}>{t('total')}</h3>

      <div
        id="separator1"
        className={[
          css`
            margin-top: ${SPACING.spacing16};
            margin-bottom: ${SPACING.spacing16};
            background-color: ${COLORS.LIGHT_GREY};
            height: 2px;
          `,
        ].join(' ')}
      />
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <p className={[font2.Headline1_Regular].join(' ')}>대여 요금</p>
          <p className={[font2.Headline1_Bold].join(' ')}>
            {formatKRWWonAmount(rate_foreigner_amount)}{' '}
            {currency === wonCurrency ? t('won') : currency}
          </p>
        </div>
        {data.extra_charge && (data.extra_charge.additional_amount || 0) > 0 && (
          <div
            className={[
              // 'flex flex-col gap-1.5',
              // borders.DEBUG_greenBorderDashed,
              MARGINS.TOP.spacing12,
            ].join(' ')}
          >
            <AdditionalFees2
              extra_charge={data.extra_charge}
              onSiteCurrency={data.extra_equipments_json.currency}
            />
          </div>
        )}
        <div
          id="separator"
          className={[
            css`
              margin-top: ${SPACING.spacing16};
              margin-bottom: ${SPACING.spacing16};
              background-color: ${COLORS.LIGHT_GREY};
              height: 2px;
            `,
          ].join(' ')}
        />
        <div
          className={[
            'flex flex-row justify-between',
            // borders.DEBUG_goldDashed,
            css`
              position: relative;
            `,
          ].join(' ')}
        >
          <div
            className={[
              font2.Headline1_Bold,
              // borders.DEBUG_goldDashed,
              css`
                display: flex;
                align-items: center;
                justify-items: center;
                height: fit-content;
                gap: 6px;
              `,
            ].join(' ')}
          >
            <span>현장 결제 예상금액</span>
            <HoverToReveal
              toReveal={
                <div
                  className={[
                    font2.Caption1_Light,
                    PADDINGS.TOP_AND_BOTTOM.spacing16,
                    COLORS2_AS_BACKGROUND.sub.MrtGray3,
                    css`
                      position: absolute;
                      left: 0;
                      right: 0;
                      top: 70%;
                      padding-left: 10px;
                      padding-right: 10px;
                      border-radius: 10px;
                    `,
                  ].join(' ')}
                >
                  {PODOnsitePaymentHoverMessage}
                </div>
              }
            />
          </div>
          <div>
            <div className="flex flex-col items-end gap-1.5">
              <p className={[font2.Title2, COLORS2_AS_FOREGROUND.main.MrtBlack].join(' ')}>
                {formatKRWWonAmount(data?.total_foreigner_amount)}
                &nbsp;
                {currency === wonCurrency ? t('won') : currency}
              </p>
              <p className={[font2.Caption1_Light, COLORS2_AS_FOREGROUND.sub.MrtGray1].join(' ')}>
                약 {formatKRWWonAmount(data?.total_amount)} {t('won')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          onClick={() => {
            onClickCancelReservation(data);
            sendTrackingLog({
              action: actions.RESERVATION_DETAIL.CLICK_RESERVATION_CANCEL,
            });
          }}
          disabled={isCancelDisabled}
          className={CancelButtonStyle}
        >
          {t('cancelBooking')}
        </button>
        {isCancelDisabled && (
          <div
            className={[
              font2.Caption1_Light,
              css`
                display: flex;
                flex-direction: column;
                align-items: center;
                color: ${COLORS2.main.MrtBlack};
                margin-top: ${SPACING.spacing16};
              `,
            ].join(' ')}
          >
            픽업일 기준 72시간 이내 취소는 고객센터로 문의해주세요.
          </div>
        )}
      </div>
    </div>
  );
});

/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
import { css } from '@emotion/css';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from '^/assets/icons/ic_info_black.svg';
import { GroupCar, NameCar } from '^/components/ModelCar';
import { borders } from '^/constants/commonStyles';
import { VENDOR_LOGOS } from '^/constants/LogoImages.constants';
import CancellationAndRefundHertzPOD from '^/pages/Booking/components/DriverDetails/components/CancellationAndRefund/CancellationAndRefund.Hertz.POD';
import CancellationAndRefundHertzPPD from '^/pages/Booking/components/DriverDetails/components/CancellationAndRefund/CancellationAndRefund.Hertz.PPD';
import { getTermsAndConditionCancellationRichContent } from '^/pages/Booking/renewal/components/TermsAndConditions/TermsAndConditionCancellation.Rich';
import { getTermsAndConditionCancellationTumonContent } from '^/pages/Booking/renewal/components/TermsAndConditions/TermsAndConditionCancellation.Tumon';
import { translateSimplifiedServices } from '^/pages/Listing/common/translateServices';
import { Horizon } from '^/pages/ReservationList/components/common/Horizon';
import { DAY_OF_WEEK } from '^/pages/ReservationList/constant';
import { isReservationCancelDisabled } from '^/pages/ReservationList/isReservationDisabled';
import {
  isValidExtraEquipments,
  ReservationDetailed,
} from '^/types/ReservationDetailed/RawReservationDetailed';
import { capitalizeFirstLettersInWords } from '^/util/capitalizeFirstLetterInWords';
import { actions, sendTrackingLog } from '^/api/trackingLog';

export const ReservationDetailsVehicleInfo: React.FC<{
  data: ReservationDetailed;
  onClickCancelReservation(reservationToCancel: ReservationDetailed, e: React.MouseEvent): void;
  onClickShowInsuranceInfo(): void;
}> = observer(function ReservationDetailsVehicleInfo({
  data,
  onClickCancelReservation,
  onClickShowInsuranceInfo,
}) {
  const { t } = useTranslation();
  const pickupDate = new Date(data?.pickup_datetime);
  const returnDate = new Date(data?.return_datetime);
  const insurance = data.insurance_info_json;
  const validEquips = isValidExtraEquipments(data.extra_equipments_json)
    ? data.extra_equipments_json
    : null;
  const services = data.services && JSON.parse(data.services);

  const isCancelDisabled = isReservationCancelDisabled({
    pickupDate,
    cancelEnabled: data.cancelEnabled,
    payType: data.pay_type,
  });

  const getCancellationTerms = () => {
    if (data.supplier === 'RC') {
      return getTermsAndConditionCancellationRichContent();
    }
    if (data.supplier === 'TM') {
      return getTermsAndConditionCancellationTumonContent();
    }
    if (data.pay_type === 'PPD') {
      return <CancellationAndRefundHertzPPD />;
    }
    return <CancellationAndRefundHertzPOD />;
  };

  return (
    <div className="flex flex-col gap-[30px]">
      <div className="flex flex-col gap-1.5">
        <div className="flex items-start justify-between">
          <GroupCar
            modelName={data?.car_model}
            className="text-big font-bold"
            size={data?.size_car}
            type={data?.type_car}
          />
          <img
            alt="company"
            src={VENDOR_LOGOS[data.supplier]}
            className="h-5 w-11 object-scale-down"
          />
        </div>
        <NameCar modelName={data?.car_model} className="text-small font-light" />
        <p className="text-small font-light text-neutral-300">
          {translateSimplifiedServices(services, t)}
        </p>
      </div>

      <Horizon />

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-3">
          <p className="text-big font-bold">예약 번호</p>
          <p className="text-default font-light">{data.is_confirm_hertz ? data.res_id : '-'}</p>
        </div>
        <div className="flex flex-col gap-3">
          <p className="text-big font-bold">
            {t('rentaled')} / {t('return')} {t('schedule')}
          </p>
          <p className="text-default font-light">
            {format(pickupDate, 'yyyy.MM.dd')} ({t(DAY_OF_WEEK[pickupDate.getDay()])}){' '}
            {format(pickupDate, 'HH:mm')} ~ {format(returnDate, 'MM.dd')} (
            {t(DAY_OF_WEEK[returnDate.getDay()])}) {format(returnDate, 'HH:mm')}
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <div
            className={[
              // borders.DEBUG_orangeBorderDashed,
              css`
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 6px;
              `,
            ].join(' ')}
          >
            <p className="text-big font-bold">{t('insuranceDetails')}</p>
            {/* <InfoIcon
              width={20}
              height={20}
              onClick={onClickShowInsuranceInfo}
            /> */}
          </div>
          <div
            className={[
              // borders.DEBUG_orangeBorderDashed,
              css`
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 6px;
              `,
            ].join(' ')}
          >
            <p className="text-default font-light">
              {insurance.code ? t(`insurance_name.${insurance.code}`) : '기본 할인요금'}
            </p>
            <InfoIcon width={20} height={20} onClick={onClickShowInsuranceInfo} />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <p className="text-big font-bold">{t('moreOptions')}</p>
          <p className="text-default font-light">
            {(validEquips?.info?.length || 0) > 0 ? (
              <span>
                {validEquips?.info
                  ?.map((item) => `${t(`equipment_${item.code}`)} ${item.quantity}`)
                  .join(' / ')}
              </span>
            ) : (
              <p>{t('none')}</p>
            )}
          </p>
        </div>
      </div>

      <Horizon />

      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-4">
          <p className="text-big font-bold">
            {t('rentaled')} {t('location')}
          </p>
          <div className="flex flex-col gap-2.5">
            <div className="flex items-center gap-5">
              <p className="text-default font-bold"> {t(`location_name.${data.office_name}`)}</p>
              <div className="flex items-center gap-[9px]">
                <img src="./ic_timer.png" className="h-6] w-6" />
                <span className="text-default font-light">
                  {data.office_time.replace('null', '__')}
                </span>
              </div>
            </div>
            <p className="text-small font-light">
              {capitalizeFirstLettersInWords(data.office_address)}
            </p>
            {(data.office_phone || null) && (
              <p className="text-default text-primary-gray tablet:text-big">
                전화 번호: {data.office_phone}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-big font-bold">
            {t('return')} {t('location')}
          </p>
          <div className="flex flex-col gap-2.5">
            <div className="flex items-center gap-5">
              <p className="text-default font-bold">
                {' '}
                {t(`location_name.${data.office_second_name}`)}
              </p>
              <div className="flex items-center gap-[9px]">
                <img src="./ic_timer.png" className="h-6] w-6" />
                <span className="text-default font-light">
                  {data.office_second_time.replace('null', '__')}
                </span>
              </div>
            </div>
            <p className="text-small font-light">
              {capitalizeFirstLettersInWords(data.office_second_address)}
            </p>
            {(data.office_second_phone || null) && (
              <p className="text-default text-primary-gray tablet:text-big">
                전화 번호: {data.office_second_phone}
              </p>
            )}
          </div>
        </div>
      </div>

      <Horizon />

      <div>
        <p className="text-big font-bold">취소 및 환불규정</p>
        <div className="mt-4 rounded-[10px] bg-secondary-gray-light p-4 text-default font-light leading-5 mobile:text-small">
          {getCancellationTerms()}
        </div>
      </div>

      {data.res_status === 'booked' && (
        <div>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault(); // 스크롤 상단 이동 방지
              onClickCancelReservation(data, e);
              sendTrackingLog({
                action: actions.RESERVATION_DETAIL.CLICK_RESERVATION_CANCEL,
              });
            }}
            disabled={isCancelDisabled}
            className="
              mt-4 w-full rounded-trimo-5
              bg-trimo-bg p-3
              text-center font-bold
              text-primary-gray hover:bg-primary-skyblue hover:text-white focus:text-white disabled:cursor-not-allowed disabled:bg-secondary-gray disabled:text-neutral-300
            "
          >
            {t('cancelBooking')}
          </button>
          {isCancelDisabled && (
            <div
              className="
                mt-[12px]
                flex justify-center
                text-small
                text-primary-gray
              "
            >
              픽업일 기준 72시간 이내 취소는 고객센터로 문의해주세요.
            </div>
          )}
        </div>
      )}
      <div className="my-[24px] text-right">
        {data?.supplier === 'ZE' && (
          <p className="text-default font-bold text-trimo-dark underline underline-offset-1 hover:cursor-pointer">
            <a
              href="https://images.hertz.com/pdfs/asia/KR_HertzGuide2019.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Hertz 해외렌터카 가이드
            </a>
          </p>
        )}
      </div>
    </div>
  );
});

/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from 'react';

import { css } from '@emotion/css';
import { useMediaQuery } from 'react-responsive';
import { useRecoilValue } from 'recoil';

import { CUSTOMER_SERVICE_BY_CHANNEL } from '^/constants/customerServiceText.constants';
import { HELP_PAGE } from '^/constants/routes';
import { getItemByChannel } from '^/util/byChannel';
import { convertLineBreaks } from '^/util/textFormat';

import { footerColorState, subdomainState } from '../../recoil/atoms';
import { TermsPrivacyPopup } from '../Popup/TermPopup/TermsPrivacy';
import { TermsServicePopup } from '../Popup/TermPopup/TermsService';

import './index.scss';
import { IMS_LOGO } from '^/constants/LogoImages.constants';

export const Footer2 = () => {
  const [termServiceOpen, setTermServiceOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const footerColorValue = useRecoilValue(footerColorState);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const subdomain = useRecoilValue(subdomainState);
  const csInfo = getItemByChannel(subdomain, CUSTOMER_SERVICE_BY_CHANNEL);

  return (
    <div
      className={[
        `bg-${footerColorValue}`,
        'footer text-primary-gray flex-start z-20 bottom-0 top-20',
        isMobile &&
          css`
            padding: 30px 24px;
            overflow: hidden;
            width: 100%;
          `,
      ].join(' ')}
    >
      {isMobile ? (
        <div className="flex flex-row">
          <div className="flex flex-col">
            {csInfo && (
              <div className="justify-top flex flex-col ">
                <div className="flex flex-col gap-[20px] text-default font-bold">
                  고객센터 운영안내
                </div>
                <div className="flex flex-col gap-[50px] pt-[30px] text-small leading-5 ">
                  {convertLineBreaks(csInfo)}
                </div>
              </div>
            )}
            <div className="mt-[30px] flex flex-row gap-[20px] text-default font-bold">
              <div
                className="flex-col hover:cursor-pointer hover:text-primary-gray"
                onClick={() => setPrivacyOpen(true)}
              >
                개인정보 처리방침
              </div>
              <div
                className="flex-col hover:cursor-pointer hover:text-primary-gray"
                onClick={() => setTermServiceOpen(true)}
              >
                이용약관
              </div>
              <button
                type="button"
                onClick={() => {
                  window.location.href = HELP_PAGE;
                }}
              >
                자주묻는 질문
              </button>
            </div>
            <div className="mt-[45px] w-[100px]">
              <img src={IMS_LOGO} />
            </div>
            <div className="flex flex-col gap-[50px] pt-[30px] text-small leading-5 ">
              상호명 아이엠에스모빌리티 주식회사 | 대표 조영탁 |<br />
              개인정보보호책임자 조영탁 | 사업자등록번호 206-86-77227 |<br />
              통신판매업신고번호 2019-서울광진-2048
              <br />
              주소 서울특별시 광진구 아차산로 355, 3층 광진아크로텔
              <br />
              이메일 info@trimo.ai
              <br />
            </div>
            <div className="flex flex-col gap-[50px] pt-[10px] text-small leading-5">
              아이엠에스모빌리티 주식회사는 통신판매중개자로 통신판매의
              <br />
              당사자가 아닙니다. 상품의 예약, 이용 및 환불 등 상품·거래정보 및 거래에 대하여 책임을
              지지않으며 관련한 의무와 책임은 각 판매자에게 있습니다.
              <br />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={css`
            display: flex;

            padding-top: 50px;
            padding-bottom: 50px;
            width: 100%;
            max-width: ${subdomain.includes('myrealtrip') ? '1060px' : '1320px'};
            margin-left: auto;
            margin-right: auto;
          `}
        >
          <div className="mobile:justify-top flex flex-col">
            <div className="flex flex-row gap-[20px] text-default font-bold tablet-max:text-small">
              <div
                className="hover:cursor-pointer hover:text-primary-gray"
                onClick={() => setPrivacyOpen(true)}
              >
                개인정보 처리방침
              </div>
              <div
                className="hover:cursor-pointer hover:text-primary-gray"
                onClick={() => setTermServiceOpen(true)}
              >
                이용약관
              </div>
              <button
                type="button"
                onClick={() => {
                  window.location.href = HELP_PAGE;
                }}
              >
                자주묻는 질문
              </button>
            </div>
            <div className="mt-[30px] w-[100px]">
              <img src={IMS_LOGO} />
            </div>
            <div className="flex flex-row gap-[50px] pt-[10px] text-small leading-5 tablet-max:text-small">
              아이엠에스모빌리티 주식회사는 통신판매중개자로 통신판매의 당사자가 아닙니다.
              <br />
              상품의 예약, 이용 및 환불 등 상품·거래정보 및 거래에 대하여 책임을 지지않으며 관련한
              의무와 책임은 각 판매자에게 있습니다.
              <br />
            </div>
            <div className="flex flex-row gap-[50px] pt-[30px] text-small leading-5 tablet-max:text-small">
              상호명 아이엠에스모빌리티 주식회사 | 대표 조영탁 | 개인정보보호책임자 조영탁 |<br />
              사업자등록번호 206-86-77227 | 통신판매업신고번호 2019-서울광진-2048
              <br />
              주소 서울특별시 광진구 아차산로 355, 3층 광진아크로텔 이메일 info@trimo.ai
              <br />
            </div>
          </div>
          {csInfo && (
            <div className="justify-top mx-[120px] flex flex-col">
              <div className="flex flex-row gap-[20px] text-default font-bold tablet-max:text-small">
                고객센터 운영안내
              </div>
              <div className="flex flex-row gap-[50px] pt-[30px] text-small leading-5 tablet-max:text-small">
                {convertLineBreaks(csInfo)}
              </div>
            </div>
          )}
        </div>
      )}
      <TermsPrivacyPopup open={privacyOpen} setOpen={setPrivacyOpen} />
      <TermsServicePopup open={termServiceOpen} setOpen={setTermServiceOpen} />
      {/* <PrivacyStatementPopup open={privacyOpen} setOpen={setPrivacyOpen} /> */}
    </div>
  );
};

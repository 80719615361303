import { css, cx } from '@emotion/css';
import { useRecoilValue } from 'recoil';

import { SPACING, COLORS2, font } from '^/constants/commonStyles';
import { LOGO_BY_CHANNEL } from '^/constants/LogoImages.constants';
import { NAVIGATION_MENU } from '^/constants/NavigationMenu';
import { subdomainState } from '^/recoil/atoms';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { getItemByChannel } from '^/util/byChannel';

const classNames = {
  headerWrapper: css`
    max-width: 1320px;
    height: 72px;

    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;

    /* border-bottom: 1px solid #f4f4f4; */
  `,
  headerWrapper_MRT: css`
    max-width: 1060px;
    height: 72px;

    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;

    /* border-bottom: 1px solid #f4f4f4; */
  `,
  navButtonWrapper: cx(
    css`
      display: flex;
      gap: ${SPACING.spacing16};
    `,
    font.Body1_Bold,
  ),
  navButton: css`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px 20px;
    height: 36px;

    &:hover {
      background-color: ${COLORS2.sub.MrtGray3};
      border-radius: 18px;
    }
  `,
  navButton_MRT: css`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px 20px;
    height: 36px;

    &:hover {
      background-color: ${COLORS2.sub.MrtGray3};
      border-radius: 18px;
    }
  `,
};

export const Header3Desktop = (props: { withoutFullRefresh?: boolean }) => {
  const subdomain = useRecoilValue(subdomainState);
  return (
    <header
      className={
        subdomain.includes('myrealtrip') ? classNames.headerWrapper_MRT : classNames.headerWrapper
      }
    >
      <button
        type="button"
        onClick={() => {
          if (props.withoutFullRefresh) {
            typeRouter.routes.home2().push();
          } else {
            window.location.href = window.location.origin;
            window.scrollTo(0, 0);
          }
        }}
      >
        <img
          src={getItemByChannel(subdomain, LOGO_BY_CHANNEL)}
          alt="trimo-logo"
          className={css`
            max-width: 225px;
            max-height: 28px;
          `}
        />
      </button>
      {subdomain.includes('myrealtrip') ? (
        <div className={classNames.navButtonWrapper}>
          {NAVIGATION_MENU.filter((nav) => {
            return nav.subdomain.includes('myrealtrip');
          }).map((nav) => (
            <button
              key={nav.name}
              type="button"
              className={classNames.navButton_MRT}
              onClick={() => {
                if (nav.name === '예약 조회하기') {
                  // typeRouter.routes.reservationCheck().push();
                  window.location.href = typeRouter.routes.reservationCheck().href;
                }
                window.scrollTo(0, 0);
              }}
            >
              {nav.name}
            </button>
          ))}
        </div>
      ) : (
        <div className={classNames.navButtonWrapper}>
          {NAVIGATION_MENU.filter((nav) => {
            return nav.subdomain === 'default';
          }).map((nav) => (
            <button
              key={nav.name}
              type="button"
              className={classNames.navButton}
              onClick={() => {
                if (nav.name === '예약조회') {
                  // typeRouter.routes.reservationCheck().push();
                  window.location.href = typeRouter.routes.reservationCheck().href;
                }
                if (nav.name === 'FAQ') {
                  // typeRouter.routes.help().push();
                  window.location.href = nav.url;
                  // window.location = nav.url;
                }
                window.scrollTo(0, 0);
              }}
            >
              {nav.name}
            </button>
          ))}
        </div>
      )}
    </header>
  );
};

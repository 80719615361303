/* eslint-disable operator-linebreak */
import { Dispatch, useEffect, useRef } from 'react';

import { css } from '@emotion/css';
import { Tab } from '@headlessui/react';
import { observer } from 'mobx-react-lite';

import { actions, sendTrackingLog } from '^/api/trackingLog';
import { CitySearchInputViewModel } from '^/components/CarSearchBox/base/desktop/headerArea/CitySearchInput/CitySearchInput.viewmodel';
import { popularCityList } from '^/components/CarSearchBox/common/popularCity.constants';
import { mrtCitiesByCountry } from '^/components/CarSearchBox/myrealtrip/mobile/contentsArea/SelectCity/mrtCitiesByCountry';
import { CityCodeType } from '^/types/__BrandedLocationTypes';

import { Style } from '../MRTCarListingSearchBox.desktop.contentsArea.style';

interface MRTPopularCityPanelProps {
  viewmodel: CitySearchInputViewModel;
  cityContainerRef: React.RefObject<HTMLDivElement>;
  onSelectCity: (cityCode: CityCodeType) => void;
  setSelectedIndex: Dispatch<React.SetStateAction<number>>;
  onBlur: () => void;
}

const MRTPopularCityPanel: React.FC<MRTPopularCityPanelProps> = observer(
  function MRTPopularCityPanel({
    viewmodel,
    onBlur,
    cityContainerRef,
    onSelectCity,
    setSelectedIndex,
  }: MRTPopularCityPanelProps) {
    const contentsRef = useRef<{ [key: number]: IntersectionObserverEntry }>({});

    /**
     * 인기도시 패널에서 화면에 보이는 대륙이 어떤 것인지 감지하여 탭 index를 바꾸기 위한 로직
     */
    useEffect(() => {
      const callback = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((content) => {
          contentsRef.current[Number(content.target.getAttribute('data-tab-index'))] = content;
        });
        const visibleContent = Object.values(contentsRef.current).filter(
          (entry) => entry.isIntersecting,
        );
        visibleContent.sort((a, b) => b.intersectionRatio - a.intersectionRatio);
        const contentIndex = visibleContent[0].target.getAttribute('data-tab-index');
        setSelectedIndex(Number(contentIndex));
      };

      const io = new IntersectionObserver(callback, {
        root: document.getElementById('city-select-container'),
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      });

      if (cityContainerRef.current) {
        const elementsWithTabIndex = cityContainerRef.current.querySelectorAll('[data-tab-index]');
        elementsWithTabIndex.forEach((element) => io.observe(element));
      }

      return () => {
        // unmount시 대륙 element 구독 해제
        if (cityContainerRef.current) {
          const elementsWithTabIndex =
            cityContainerRef.current.querySelectorAll('[data-tab-index]');
          elementsWithTabIndex.forEach((element) => io.unobserve(element));
        }
      };
    }, []);

    return (
      <Tab.Panels
        className={css`
          flex: 1;
        `}
        onBlur={(e) => {
          if (e.currentTarget.contains(e.relatedTarget)) {
            return;
          }
          if (onBlur) {
            onBlur();
          }
        }}
      >
        <Tab.Panel ref={cityContainerRef} static>
          <div className={Style.cityPanelWrapper}>
            <div className={Style.citySelectContainer} id="city-select-container">
              <div data-tab-index={0}>
                <div className={Style.countryWrapper} data-first-country>
                  <p className={Style.countryLabel}>인기도시</p>
                  <div className={Style.cityGrid}>
                    {popularCityList.map((city, index) => {
                      return (
                        <span
                          role="button"
                          tabIndex={index}
                          className={`${Style.cityLabel} ${
                            city.code === viewmodel.selectedCityCode.value && 'selected'
                          }`}
                          onClick={async () => {
                            viewmodel.selectedCityCode.set(city.code);
                            viewmodel.tempSearchInput.set(city.nameKo);
                            if (onSelectCity) {
                              onSelectCity(city.code);
                            }
                            sessionStorage.setItem('selectedCountry', city.countryCode);
                            sendTrackingLog({
                              action: actions['/'].SELECT_POPULAR_CITY,
                              country: city.countryCode,
                              data: city.nameKo,
                            });
                          }}
                        >
                          <button type="button">{city.nameKo}</button>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
              {mrtCitiesByCountry.map((continent, continentIndex) => {
                return (
                  <div data-tab-index={continentIndex + 1}>
                    {continent.countries.map((country, countryIndex) => {
                      return (
                        <div
                          className={Style.countryWrapper}
                          data-first-country={countryIndex === 0}
                        >
                          <p className={Style.countryLabel}>{country.nameKo}</p>
                          <div className={Style.cityGrid}>
                            {country.cities.map((city) => (
                              <div
                                role="button"
                                tabIndex={continentIndex}
                                className={`${Style.cityLabel} ${
                                  city.code === viewmodel.selectedCityCode.value && 'selected'
                                }`}
                                onClick={async () => {
                                  viewmodel.selectedCityCode.set(city.code);
                                  viewmodel.tempSearchInput.set(city.nameKo);
                                  if (onSelectCity) {
                                    onSelectCity(city.code);
                                  }
                                  sendTrackingLog({
                                    action: actions['/'].SELECT_POPULAR_CITY,
                                    data: city.nameKo,
                                  });
                                }}
                              >
                                <button type="button">{city.nameKo}</button>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </Tab.Panel>
      </Tab.Panels>
    );
  },
);

export default MRTPopularCityPanel;

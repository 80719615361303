/* eslint-disable max-len */
import { CityCodeType } from '^/types/__BrandedLocationTypes';

export type CitiesByCountry = {
  continent: string;
  countries: {
    nameKo: string;
    code: string;
    cities: {
      nameEn: string;
      nameKo: string;
      code: CityCodeType;
      countryCode: string;
    }[];
  }[];
}[];

export const mrtCitiesByCountry: CitiesByCountry = [
  {
    continent: '아시아',
    countries: [
      {
        nameKo: '일본',
        code: 'JP',
        cities: [
          {
            nameEn: 'Sapporo',
            code: '01003' as CityCodeType,
            nameKo: '삿포로',
            vendor: 'OT',
            hertzCode: 'SPK',
            countryCode: 'JP',
          },
          {
            nameEn: 'Okinawa',
            code: '47003' as CityCodeType,
            nameKo: '오키나와',
            vendor: 'OT',
            hertzCode: 'OKA',
            countryCode: 'JP',
          },
          {
            nameEn: 'Fukuoka',
            code: '40001' as CityCodeType,
            nameKo: '후쿠오카',
            vendor: 'OT',
            hertzCode: 'FUK',
            countryCode: 'JP',
          },
          {
            name: 'Tokyo',
            hertzCode: 'TYO',
            code: '13013' as CityCodeType,
            nameKo: '도쿄',
            vendor: 'OT',
            countryCode: 'Japan',
          },
          {
            name: 'Osaka',
            hertzCode: 'OSA',
            code: '27004' as CityCodeType,
            nameKo: '오사카',
            vendor: 'OT',
            countryCode: 'Japan',
          },
          {
            name: 'Nagoya',
            hertzCode: 'NGO',
            code: '23002' as CityCodeType,
            nameKo: '나고야',
            vendor: 'OT',
            countryCode: 'Japan',
          },
          {
            name: 'Kagoshima',
            hertzCode: 'KOJ',
            code: '46001' as CityCodeType,
            nameKo: '가고시마',
            vendor: 'OT',
            countryCode: 'Japan',
          },
        ],
      },
      {
        nameKo: '대만',
        code: 'TW',
        cities: [
          {
            nameEn: 'Kaohsiung',
            code: 'KHH' as CityCodeType,
            nameKo: '가오슝',
            countryCode: 'TW',
          },
          {
            nameEn: 'TAICHUNG',
            code: 'TXG1H' as CityCodeType,
            nameKo: '타이중',
            countryCode: 'TW',
          },
          {
            nameEn: 'Taipei',
            code: 'TPE' as CityCodeType,
            nameKo: '타이페이',
            countryCode: 'TW',
          },
        ],
      },
      {
        nameKo: '태국',
        code: 'TH',
        cities: [
          {
            nameEn: 'Bangkok',
            code: 'BKK' as CityCodeType,
            nameKo: '방콕',
            countryCode: 'TH',
          },
        ],
      },
      {
        nameKo: '필리핀',
        code: 'PH',
        cities: [
          {
            nameEn: 'Manila',
            code: 'MNL' as CityCodeType,
            nameKo: '마닐라',
            countryCode: 'PH',
          },
          {
            nameEn: 'Cebu City',
            code: 'CEB' as CityCodeType,
            nameKo: '세부',
            countryCode: 'PH',
          },
        ],
      },
      {
        nameKo: '싱가포르',
        code: 'SG',
        cities: [
          {
            nameEn: 'Dammam',
            code: 'DMM' as CityCodeType,
            nameKo: '담암',
            countryCode: 'SA',
          },
          {
            nameEn: 'Riyadh',
            code: 'RUH' as CityCodeType,
            nameKo: '리야드',
            countryCode: 'SA',
          },
          {
            nameEn: 'Jeddah',
            code: 'JED' as CityCodeType,
            nameKo: '제다',
            countryCode: 'SA',
          },
        ],
      },
      {
        nameKo: '쿠웨이트',
        code: 'KW',
        cities: [
          {
            nameEn: 'Kuwait',
            code: 'KWI' as CityCodeType,
            nameKo: '쿠웨이트',
            countryCode: 'KWI',
          },
        ],
      },
      {
        nameKo: '아제르바이잔',
        code: 'AZ',
        cities: [
          {
            nameEn: 'Baku',
            code: 'BAK' as CityCodeType,
            nameKo: '바쿠',
            countryCode: 'AZ',
          },
        ],
      },
      {
        nameKo: '브루나이',
        code: 'BN',
        cities: [
          {
            nameEn: 'Bandar Seri Begawan',
            code: 'BWN' as CityCodeType,
            nameKo: '반다르세리베가완',
            countryCode: 'BWN',
          },
        ],
      },
    ],
  },
  {
    continent: '아메리카',
    countries: [
      {
        nameKo: '미국',
        code: 'US',
        cities: [
          {
            nameEn: 'Guam',
            code: 'GUM' as CityCodeType,
            nameKo: '괌',
            countryCode: 'GU',
          },
          {
            nameEn: 'New York',
            code: 'NYC' as CityCodeType,
            nameKo: '뉴욕',
            countryCode: 'US',
          },
          {
            nameEn: 'Dallas',
            code: 'DFW' as CityCodeType,
            nameKo: '달라스',
            countryCode: 'US',
          },
          {
            nameEn: 'Las Vegas',
            code: 'LAS' as CityCodeType,
            nameKo: '라스베가스',
            countryCode: 'US',
          },
          {
            nameEn: 'Los Angeles',
            code: 'LAX' as CityCodeType,
            nameKo: '로스앤젤레스',
            countryCode: 'US',
          },
          {
            nameEn: 'Miami',
            code: 'MIA' as CityCodeType,
            nameKo: '마이애미',
            countryCode: 'US',
          },
          {
            nameEn: 'Saipan',
            code: 'SPN' as CityCodeType,
            nameKo: '사이판',
            countryCode: 'MP',
          },
          {
            nameEn: 'San Francisco',
            code: 'SFO' as CityCodeType,
            nameKo: '샌프란시스코',
            countryCode: 'US',
          },
          {
            nameEn: 'Seattle',
            code: 'SEA' as CityCodeType,
            nameKo: '시애틀',
            countryCode: 'US',
          },
          {
            nameEn: 'Chicago',
            code: 'CHI' as CityCodeType,
            nameKo: '시카고',
            countryCode: 'US',
          },
          {
            nameEn: 'Atlanta',
            code: 'ATL' as CityCodeType,
            nameKo: '애틀랜타',
            countryCode: 'US',
          },
          {
            nameEn: 'Washington',
            code: 'WAS' as CityCodeType,
            nameKo: '워싱턴',
            countryCode: 'US',
          },
          {
            nameEn: 'Kailua-Kona',
            code: 'KOA' as CityCodeType,
            nameKo: '카일루아 코나',
            countryCode: 'US',
          },
          {
            nameEn: 'Kahului',
            code: 'OGG' as CityCodeType,
            nameKo: '카훌루이',
            countryCode: 'US',
          },
          {
            nameEn: 'Honolulu',
            code: 'HNL' as CityCodeType,
            nameKo: '호놀룰루',
            countryCode: 'US',
          },
          {
            nameEn: 'Houston',
            code: 'HOU' as CityCodeType,
            nameKo: '휴스턴',
            countryCode: 'US',
          },
        ],
      },
      {
        nameKo: '캐나다',
        code: 'CA',
        cities: [
          {
            nameEn: 'Montreal',
            code: 'YMQ' as CityCodeType,
            nameKo: '몬트리올',
            countryCode: 'CA',
          },
          {
            nameEn: 'Vancouver',
            code: 'YVR' as CityCodeType,
            nameKo: '밴쿠버',
            countryCode: 'CA',
          },
          {
            nameEn: 'Ottawa',
            code: 'YOW' as CityCodeType,
            nameKo: '오타와',
            countryCode: 'CA',
          },
          {
            nameEn: 'Calgary',
            code: 'YYC' as CityCodeType,
            nameKo: '캘거리',
            countryCode: 'CA',
          },
          {
            nameEn: 'Toronto',
            code: 'YTO' as CityCodeType,
            nameKo: '토론토',
            countryCode: 'CA',
          },
        ],
      },
      {
        nameKo: '멕시코',
        code: 'MX',
        cities: [
          {
            nameEn: 'Mexico City',
            code: 'MEX' as CityCodeType,
            nameKo: '멕시코 시티',
            countryCode: 'MX',
          },
          {
            nameEn: 'Cancun',
            code: 'CUN' as CityCodeType,
            nameKo: '칸쿤',
            countryCode: 'MX',
          },
        ],
      },
      {
        nameKo: '파라과이',
        code: 'PY',
        cities: [
          {
            nameEn: 'Asuncion',
            code: 'ASU' as CityCodeType,
            nameKo: '아순시온',
            countryCode: 'PY',
          },
        ],
      },
      {
        nameKo: '페루',
        code: 'PE',
        cities: [
          {
            nameEn: 'Lima',
            code: 'LIM' as CityCodeType,
            nameKo: '리마',
            countryCode: 'PE',
          },
        ],
      },
      {
        nameKo: '과테말라',
        code: 'GT',
        cities: [
          {
            nameEn: 'Guatemala City',
            code: 'GUA' as CityCodeType,
            nameKo: '과테말라시',
            countryCode: 'GT',
          },
        ],
      },
      {
        nameKo: '푸에르토 리코',
        code: 'PR',
        cities: [
          {
            nameEn: 'San Juan',
            code: 'SJU' as CityCodeType,
            nameKo: '산 후안',
            countryCode: 'PR',
          },
        ],
      },
      {
        nameKo: '우루과이',
        code: 'UY',
        cities: [
          {
            nameEn: 'Montevideo',
            code: 'MVD' as CityCodeType,
            nameKo: '몬테비데오',
            countryCode: 'UY',
          },
        ],
      },
    ],
  },
  {
    continent: '유럽',
    countries: [
      {
        nameKo: '프랑스',
        code: 'FR',
        cities: [
          {
            nameEn: 'Nice',
            code: 'NCE' as CityCodeType,
            nameKo: '니스',
            countryCode: 'FR',
          },
          {
            nameEn: 'Nimes',
            code: 'FNI' as CityCodeType,
            nameKo: '님',
            countryCode: 'FR',
          },
          {
            nameEn: 'Rennes',
            code: 'RNS' as CityCodeType,
            nameKo: '렌',
            countryCode: 'FR',
          },
          {
            nameEn: 'Marseille',
            code: 'MRS' as CityCodeType,
            nameKo: '마르세유',
            countryCode: 'FR',
          },
          {
            nameEn: 'Paris',
            code: 'PAR' as CityCodeType,
            nameKo: '파리',
            countryCode: 'FR',
          },
        ],
      },
      {
        nameKo: '이탈리아',
        code: 'IT',
        cities: [
          {
            nameEn: 'Naples',
            code: 'NAP' as CityCodeType,
            nameKo: '나폴리',
            countryCode: 'IT',
          },
          {
            nameEn: 'Rome',
            code: 'ROM' as CityCodeType,
            nameKo: '로마',
            countryCode: 'IT',
          },
          {
            nameEn: 'Milan',
            code: 'MIL' as CityCodeType,
            nameKo: '밀라노',
            countryCode: 'IT',
          },
          {
            nameEn: 'Bari',
            code: 'BRI' as CityCodeType,
            nameKo: '바리',
            countryCode: 'IT',
          },
          {
            nameEn: 'Venice',
            code: 'VCE' as CityCodeType,
            nameKo: '베니스',
            countryCode: 'IT',
          },
          {
            nameEn: 'Florence',
            code: 'FLR' as CityCodeType,
            nameKo: '피렌체',
            countryCode: 'IT',
          },
        ],
      },
      {
        nameKo: '독일',
        code: 'DE',
        cities: [
          {
            nameEn: 'Dusseldorf',
            code: 'DUS' as CityCodeType,
            nameKo: '뒤셀도르프',
            countryCode: 'DE',
          },
          {
            nameEn: 'Munich',
            code: 'MUC' as CityCodeType,
            nameKo: '뮌헨',
            countryCode: 'DE',
          },
          {
            nameEn: 'Berlin',
            code: 'BER' as CityCodeType,
            nameKo: '베를린',
            countryCode: 'DE',
          },
          {
            nameEn: 'Frankfurt am Main',
            code: 'FRA' as CityCodeType,
            nameKo: '프랑크푸르트',
            countryCode: 'DE',
          },
          {
            nameEn: 'Hamburg',
            code: 'HAM' as CityCodeType,
            nameKo: '함부르크',
            countryCode: 'DE',
          },
        ],
      },
      {
        nameKo: '스페인',
        code: 'ES',
        cities: [
          {
            nameEn: 'Madrid',
            code: 'MAD' as CityCodeType,
            nameKo: '마드리드',
            countryCode: 'ES',
          },
          {
            nameEn: 'Menorca',
            code: 'MAH' as CityCodeType,
            nameKo: '메노르카',
            countryCode: 'ES',
          },
          {
            nameEn: 'Barcelona',
            code: 'BCN' as CityCodeType,
            nameKo: '바르셀로나',
            countryCode: 'ES',
          },
          {
            nameEn: 'Sevilla',
            code: 'SVQ' as CityCodeType,
            nameKo: '세비야',
            countryCode: 'ES',
          },
          {
            nameEn: 'Palma',
            code: 'PMI' as CityCodeType,
            nameKo: '팔마',
            countryCode: 'ES',
          },
        ],
      },
      {
        nameKo: '영국',
        code: 'GB',
        cities: [
          {
            nameEn: 'London',
            code: 'LON' as CityCodeType,
            nameKo: '런던',
            countryCode: 'GB',
          },
          {
            nameEn: 'Manchester',
            code: 'MAN' as CityCodeType,
            nameKo: '맨체스터',
            countryCode: 'GB',
          },
          {
            nameEn: 'Birmingham',
            code: 'BHX' as CityCodeType,
            nameKo: '버밍엄',
            countryCode: 'GB',
          },
        ],
      },
      {
        nameKo: '스위스',
        code: 'CH',
        cities: [
          {
            nameEn: 'Geneva',
            code: 'GVA' as CityCodeType,
            nameKo: '제네바',
            countryCode: 'CH',
          },
          {
            nameEn: 'Zurich',
            code: 'ZRH' as CityCodeType,
            nameKo: '취리히',
            countryCode: 'CH',
          },
        ],
      },
      {
        nameKo: '벨기에',
        code: 'BE',
        cities: [
          {
            nameEn: 'Brussels',
            code: 'BRU' as CityCodeType,
            nameKo: '브뤼셀',
            countryCode: 'BE',
          },
        ],
      },
      {
        nameKo: '오스트리아',
        code: 'AT',
        cities: [
          {
            nameEn: 'Vienna',
            code: 'VIE' as CityCodeType,
            nameKo: '비엔나',
            countryCode: 'AT',
          },
          {
            nameEn: 'Salzburg',
            code: 'SZG' as CityCodeType,
            nameKo: '잘츠부르크',
            countryCode: 'AT',
          },
        ],
      },
      {
        nameKo: '덴마크',
        code: 'DK',
        cities: [
          {
            nameEn: 'Copenhagen',
            code: 'CPH' as CityCodeType,
            nameKo: '코펜하겐',
            countryCode: 'DK',
          },
        ],
      },
      {
        nameKo: '핀란드',
        code: 'FI',
        cities: [
          {
            nameEn: 'Rovaniemi',
            code: 'RVN' as CityCodeType,
            nameKo: '로바니에미',
            countryCode: 'FI',
          },
          {
            nameEn: 'Helsinki',
            code: 'HEL' as CityCodeType,
            nameKo: '헬싱키',
            countryCode: 'FI',
          },
        ],
      },
      {
        nameKo: '아이슬란드',
        code: 'IS',
        cities: [
          {
            nameEn: 'Reykjavik',
            code: 'REK' as CityCodeType,
            nameKo: '레이캬비크',
            countryCode: 'IS',
          },
        ],
      },
      {
        nameKo: '그리스',
        code: 'GR',
        cities: [
          {
            nameEn: 'Athens',
            code: 'ATH' as CityCodeType,
            nameKo: '아테네',
            countryCode: 'GR',
          },
          {
            nameEn: 'Zakinthos',
            code: 'ZTH' as CityCodeType,
            nameKo: '자킨토스',
            countryCode: 'GR',
          },
        ],
      },
      {
        nameKo: '헝가리',
        code: 'HU',
        cities: [
          {
            nameEn: 'Budapest',
            code: 'BUD' as CityCodeType,
            nameKo: '부다페스트',
            countryCode: 'HU',
          },
        ],
      },
      {
        nameKo: '체코',
        code: 'CZ',
        cities: [
          {
            nameEn: 'Prague',
            code: 'PRG' as CityCodeType,
            nameKo: '프라하',
            countryCode: 'CZ',
          },
        ],
      },
      {
        nameKo: '크로아티아',
        code: 'HR',
        cities: [
          {
            nameEn: 'Split',
            code: 'SPU' as CityCodeType,
            nameKo: '스플리트',
            countryCode: 'HR',
          },
          {
            nameEn: 'Zagreb',
            code: 'ZAG' as CityCodeType,
            nameKo: '자그레브',
            countryCode: 'HR',
          },
        ],
      },
      {
        nameKo: '아일랜드',
        code: 'IE',
        cities: [
          {
            nameEn: 'Dublin',
            code: 'DUB' as CityCodeType,
            nameKo: '더블린',
            countryCode: 'IE',
          },
        ],
      },
      {
        nameKo: '알바니아',
        code: 'AL',
        cities: [
          {
            nameEn: 'Tirana',
            code: 'TIA' as CityCodeType,
            nameKo: '티라나',
            countryCode: 'AL',
          },
        ],
      },
      {
        nameKo: '불가리아',
        code: 'BG',
        cities: [
          {
            nameEn: 'Sofia',
            code: 'SOF' as CityCodeType,
            nameKo: '소피아',
            countryCode: 'BG',
          },
        ],
      },
      {
        nameKo: '에스토니아',
        code: 'EE',
        cities: [
          {
            nameEn: 'Tallinn',
            code: 'TLL' as CityCodeType,
            nameKo: '탈린',
            countryCode: 'EE',
          },
        ],
      },
    ],
  },
  {
    continent: '오세아니아',
    countries: [
      {
        nameKo: '호주',
        code: 'AU',
        cities: [
          {
            nameEn: 'Newcastle',
            code: 'NTL' as CityCodeType,
            nameKo: '뉴캐슬',
            countryCode: 'AU',
          },
          {
            nameEn: 'Darwin',
            code: 'DRW' as CityCodeType,
            nameKo: '다윈',
            countryCode: 'AU',
          },
          {
            nameEn: 'Launceston',
            code: 'LST' as CityCodeType,
            nameKo: '론서스턴',
            countryCode: 'AU',
          },
          {
            nameEn: 'Melbourne',
            code: 'MEL' as CityCodeType,
            nameKo: '멜버른',
            countryCode: 'AU',
          },
          {
            nameEn: 'Ballina',
            code: 'BNK' as CityCodeType,
            nameKo: '발리나',
            countryCode: 'AU',
          },
          {
            nameEn: 'Brisbane',
            code: 'BNE' as CityCodeType,
            nameKo: '브리즈번',
            countryCode: 'AU',
          },
          {
            nameEn: 'Sydney',
            code: 'SYD' as CityCodeType,
            nameKo: '시드니',
            countryCode: 'AU',
          },
          {
            nameEn: 'Adelaide',
            code: 'ADL' as CityCodeType,
            nameKo: '애들레이드',
            countryCode: 'AU',
          },
          {
            nameEn: 'Alice Springs',
            code: 'ASP' as CityCodeType,
            nameKo: '앨리스 스프링스',
            countryCode: 'AU',
          },
          {
            nameEn: 'Albury',
            code: 'ABX' as CityCodeType,
            nameKo: '앨버리',
            countryCode: 'AU',
          },
          {
            nameEn: 'Cairns',
            code: 'CNS' as CityCodeType,
            nameKo: '케언스',
            countryCode: 'AU',
          },
          {
            nameEn: 'Townsville',
            code: 'TSV' as CityCodeType,
            nameKo: '타운스빌',
            countryCode: 'AU',
          },
          {
            nameEn: 'Tamworth',
            code: 'TMW' as CityCodeType,
            nameKo: '탬 워스',
            countryCode: 'AU',
          },
          {
            nameEn: 'Perth',
            code: 'PER' as CityCodeType,
            nameKo: '퍼스',
            countryCode: 'AU',
          },
          {
            nameEn: 'Hobart',
            code: 'HBA' as CityCodeType,
            nameKo: '호바트',
            countryCode: 'AU',
          },
        ],
      },
      {
        nameKo: '뉴질랜드',
        code: 'NZ',
        cities: [
          {
            nameEn: 'Dunedin',
            code: 'DUD' as CityCodeType,
            nameKo: '더니든',
            countryCode: 'NZ',
          },
          {
            nameEn: 'Auckland',
            code: 'AKL' as CityCodeType,
            nameKo: '오클랜드',
            countryCode: 'NZ',
          },
          {
            nameEn: 'Wellington',
            code: 'WLG' as CityCodeType,
            nameKo: '웰링턴',
            countryCode: 'NZ',
          },
          {
            nameEn: 'Queenstown',
            code: 'ZQN' as CityCodeType,
            nameKo: '퀸즈타운',
            countryCode: 'NZ',
          },
          {
            nameEn: 'Christchurch',
            code: 'CHC' as CityCodeType,
            nameKo: '크라이스트처치',
            countryCode: 'NZ',
          },
          {
            nameEn: 'Tauranga',
            code: 'TRG' as CityCodeType,
            nameKo: '타우랑가',
            countryCode: 'NZ',
          },
        ],
      },
    ],
  },
  {
    continent: '중동 / 아프리카',
    countries: [
      {
        nameKo: '아랍에미리트',
        code: 'AE',
        cities: [
          {
            nameEn: 'Dubai',
            code: 'DXB' as CityCodeType,
            nameKo: '두바이',
            countryCode: 'AE',
          },
          {
            nameEn: 'Sharjah',
            code: 'SHJ' as CityCodeType,
            nameKo: '샤르자',
            countryCode: 'AE',
          },
          {
            nameEn: 'Abu Dhabi',
            code: 'AUH' as CityCodeType,
            nameKo: '아부다비',
            countryCode: 'AE',
          },
        ],
      },
      {
        nameKo: '이스라엘',
        code: 'IL',
        cities: [
          {
            nameEn: 'Tel Aviv-Yafo',
            code: 'TLV' as CityCodeType,
            nameKo: '텔아비브',
            countryCode: 'IL',
          },
        ],
      },
      {
        nameKo: '튀르키예',
        code: 'TR',
        cities: [
          {
            nameEn: 'Adana',
            code: 'ADA' as CityCodeType,
            nameKo: '아다나',
            countryCode: 'TR',
          },
          {
            nameEn: 'Istanbul',
            code: 'IST' as CityCodeType,
            nameKo: '이스탄불',
            countryCode: 'TR',
          },
        ],
      },
      {
        nameKo: '남아프리카',
        code: 'ZA',
        cities: [
          {
            nameEn: 'Johannesburg',
            code: 'JNB' as CityCodeType,
            nameKo: '요하네스 버그',
            countryCode: 'ZA',
          },
          {
            nameEn: 'Cape Town',
            code: 'CPT' as CityCodeType,
            nameKo: '케이프 타운',
            countryCode: 'ZA',
          },
        ],
      },
      {
        nameKo: '모리셔스',
        code: 'MU',
        cities: [
          {
            nameEn: 'Mauritius',
            code: 'MRU' as CityCodeType,
            nameKo: '모리셔스',
            countryCode: 'MU',
          },
        ],
      },
      {
        nameKo: '세이셸',
        code: 'SC',
        cities: [
          {
            nameEn: 'Mahe Island',
            code: 'SEZ' as CityCodeType,
            nameKo: '마에섬',
            countryCode: 'SC',
          },
        ],
      },
      {
        nameKo: '요르단',
        code: 'JO',
        cities: [
          {
            nameEn: 'Amman',
            code: 'AMM' as CityCodeType,
            nameKo: '암만',
            countryCode: 'JO',
          },
        ],
      },
    ],
  },
] as CitiesByCountry;

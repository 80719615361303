import { css, cx } from '@emotion/css';
import formatDate from 'date-fns/format';
import { observer } from 'mobx-react-lite';

import { actions, screen, sendTrackingLog } from '^/api/trackingLog';
import { ReactComponent as CautionGray } from '^/assets/icons/ic_info_gray.svg';
import { CalendarStateViewModel } from '^/components/Calendar/BasicCalendar/Calendar.state.viewmodel';
import { getWeekDayFromDate } from '^/components/Calendar/BasicCalendar/components/Day/DayType';
import { CalendarListViewModel } from '^/components/Calendar/BasicCalendar/components/List/Calendar.List.viewmodel';
import { ScheduleTimeViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/ScheduleTimeViewModel';
import { BaseSearchBoxStepManager } from '^/components/CarSearchBox/base/desktop/viewmodel/SearchBoxStepManager';
import {
  borders, SPACING, COLORS2, font, FLEX,
} from '^/constants/commonStyles';
import { OpenCloseTimes } from '^/types/RawOpenHours';

import { TimeSelect } from '../../../../Calendar/BasicCalendar/components/TimeSelect/TimeSelect';

import { defaultCalendarWithTimeMobileStyles } from './CalendarWithTime.Mobile.styles';

export const CalendarWithTimeMobile: React.FC<{
  stepManager: BaseSearchBoxStepManager;
  calendarStateViewModel: CalendarStateViewModel;
  calendarListViewModel: CalendarListViewModel;
  scheduleTimeViewModel: ScheduleTimeViewModel;
  pickupOpenCloseTimes: OpenCloseTimes;
  returnOpenCloseTimes: OpenCloseTimes;
  onBlur?: () => void;
  onSubmit: () => void;
  stylesOverrides?: Partial<typeof defaultCalendarWithTimeMobileStyles>;
}> = observer(function CalendarWithTimeMobile({
  stepManager,
  calendarStateViewModel,
  scheduleTimeViewModel,
  pickupOpenCloseTimes,
  returnOpenCloseTimes,
  onSubmit,
  stylesOverrides,
}) {
  const { startDate, endDate } = calendarStateViewModel;

  if (!startDate || !endDate) {
    return null;
  }
  const startDayOfWeek = getWeekDayFromDate(startDate);
  const startOpenClose = pickupOpenCloseTimes[startDayOfWeek];
  const startDateStr = formatDate(startDate, 'MM월 dd일(eee)');
  const endDayOfWeek = getWeekDayFromDate(endDate);
  const endOpenClose = returnOpenCloseTimes[endDayOfWeek];
  const endDateStr = formatDate(endDate, 'MM월 dd일(eee)');
  const classNames = {
    ...defaultCalendarWithTimeMobileStyles,
    ...(stylesOverrides || {}),
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!startDate || !endDate) {
          return false;
        }
        onSubmit();
        return false;
      }}
    >
      <div
        className={css`
          height: 12px;
          margin-left: -${SPACING.spacing24};
          margin-right: -${SPACING.spacing24};
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0.05), rgba(84, 84, 84, 0));
        `}
      >
      </div>
      <div
        className={css`
          display: flex;
          margin-top: 12px;
        `}
      >
        {/* left */}
        <div className={classNames.scheduleWrapper}>
          <div className={classNames.title}>대여 일정</div>
          <div className={classNames.date}>{startDateStr}</div>
          <div className={classNames.timeSelectWrapper}>
            <TimeSelect
              hour={scheduleTimeViewModel.pickupHour}
              minute={scheduleTimeViewModel.pickupMinute}
              openDirection="UP"
              openCloseTimes={startOpenClose}
            />
          </div>
        </div>

        {/* vertical  */}
        <div
          className={css`
            height: 122px;
            width: 1px;

            margin-left: ${SPACING.spacing8};
            margin-right: ${SPACING.spacing8};

            background-color: ${COLORS2.sub.MrtGray3};
            align-self: end;
          `}
        />

        {/* right */}
        <div className={classNames.scheduleWrapper}>
          <div className={classNames.title}>반납 일정</div>
          <div className={classNames.date}>{endDateStr}</div>
          <div className={classNames.timeSelectWrapper}>
            <TimeSelect
              hour={scheduleTimeViewModel.returnHour}
              minute={scheduleTimeViewModel.returnMinute}
              openDirection="UP"
              openCloseTimes={endOpenClose}
            />
          </div>
        </div>
      </div>

      <div
        className={css`
          display: flex;
          flex-direction: column;
          margin-top: 10px;
        `}
      >
        <div
          className={cx(
            font.Caption1_Light,
            FLEX.allDirectionCenter,
            css`
              color: ${COLORS2.sub.MrtGray1};
              gap: 6px;
            `,
          )}
        >
          <div style={{ marginBottom: '20px' }}>
            <CautionGray />
          </div>
          <div
            className={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <span>시간 선택은 영업소 오픈 시간만 선택 가능하며, </span>
            <span>1년 이내 예약만 할 수 있어요. </span>
          </div>
        </div>
        <button
          type="submit"
          className={cx(
            FLEX.allDirectionCenter,
            css`
              height: ${SPACING.spacing56};
              width: 100%;

              border-radius: 5px;
              background-color: ${startDate && endDate
              ? COLORS2.main.MrtBlue1
              : COLORS2.main.MrtBlue2} !important;
              color: ${COLORS2.main.MrtWhite};
              margin-top: 12px;
            `,
          )}
          onClick={async () => {
            sendTrackingLog({
              action: actions['/'].SUBMIT_SCHEDULE,
            });
          }}
        >
          적용
        </button>
      </div>
    </form>
  );
});

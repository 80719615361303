import { cx, css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { actions, sendTrackingLog } from '^/api/trackingLog';
import { RentalOfficeInputViewModel } from '^/components/CarSearchBox/base/common/RentalOfficeInput/RentalOfficeInput.viewmodel';
import { SPACING, COLORS2, font } from '^/constants/commonStyles';
import { RentalCarOfficeId } from '^/types/__BrandedLocationTypes';

export const CarListingBottomSheetSelectedLocationCategoryItems: React.FC<{
  viewmodel: RentalOfficeInputViewModel;
  onSelectRentalOffice(id: RentalCarOfficeId): void;
}> = observer(function CarListingBottomSheetSelectedLocationCategoryItems({
  viewmodel,
  onSelectRentalOffice,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const selectedCategory = viewmodel.selectedCategory.value || viewmodel.selectedOfficeData?.category_id;
  return (
    <div
      className={cx(
        css`
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: ${SPACING.spacing24};

          max-height: 400px;
          overflow-y: auto;

          @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            gap: 0;

            margin-left: -24px;
            margin-right: -24px;
            max-height: none;
          } ;
        `,
      )}
    >
      {/* selected categories */}
      {selectedCategory
        && viewmodel.data.get(selectedCategory)?.map((a) => {
          if (a.oag_code === 'GUMCK') {
            return null;
          }
          return (
            <button
              type="button"
              className={cx(
                css`
                  /* background-color: pink !important; */
                  height: 62px;

                  display: flex;
                  align-items: center;
                  justify-content: center;

                  border: 1px solid ${COLORS2.sub.MrtGray2};
                  border-radius: 5px;
                  box-sizing: border-box;
                  color: ${COLORS2.sub.MrtGray2};

                  &:hover {
                    border: 1px solid ${COLORS2.main.MrtBlue1};
                    font-weight: bold;
                  }

                  @media (max-width: 768px) {
                    height: 56px;
                    border: none;
                    border-bottom: 1px solid ${COLORS2.sub.MrtGray3};
                    justify-content: flex-start;
                  }
                `,
                font.Body1_Regular,
                // a.location_id ===
                a.location_id === viewmodel.selectedOfficeId.value
                  && !isMobile // maybe will delete
                  && cx(
                    font.Body1_Bold,
                    css`
                      border: 1px solid ${COLORS2.main.MrtBlue1};
                    `,
                  ),
              )}
              key={a.location_id}
              onMouseEnter={() => {
                viewmodel.hoverOfficeId.set(a.location_id);
              }}
              onMouseLeave={() => {
                if (viewmodel.hoverOfficeId.value === a.location_id) {
                  viewmodel.hoverOfficeId.set(null);
                }
              }}
              onClick={async () => {
                viewmodel.selectedOfficeId.set(a.location_id);
                onSelectRentalOffice(a.location_id);
                sendTrackingLog({
                  action: actions['/'].SELECT_PICKUP_LOCATION,
                  data: a.locationDescriptions.get('ko')?.description,
                });
              }}
            >
              <span
                className={css`
                  text-align: center;

                  @media (max-width: 768px) {
                    padding: 0 ${SPACING.spacing24};
                    text-align: left;
                  }
                `}
              >
                {a.locationDescriptions.get('ko')?.description}
              </span>
            </button>
          );
        })}
    </div>
  );
});

/* eslint-disable max-len */
import React from 'react';

import { css, cx } from '@emotion/css';
import { useRecoilValue } from 'recoil';

import { Footer3Desktop } from '^/components/Footer3/Footer3.Desktop';
import { Header3Desktop } from '^/components/Header3/Header3.Desktop';
import { borders } from '^/constants/commonStyles';
import { subdomainState } from '^/recoil/atoms';

export const Layout2DesktopStyles = {
  withMaxWidth: css`
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
  `,
  withMaxWidth_MRT: css`
    max-width: 1060px;
    margin-left: auto;
    margin-right: auto;
  `,
};
export const Layout2Desktop: React.FC<
React.PropsWithChildren<{
  withoutFooter?: boolean;
  withoutMaxWidth?: boolean;
  withoutOverflowScroll?: boolean;
  scrollAreaKey?: string;
  withoutFooterTopLine?: boolean;
  withoutNavigationFullRefresh?: boolean;
  classOverrides?: {
    footerBgColor?: string;
  }
}>
> = ({
  children,
  withoutFooter,
  withoutMaxWidth,
  withoutOverflowScroll,
  scrollAreaKey,
  withoutFooterTopLine,
  withoutNavigationFullRefresh,
  classOverrides,
}) => {
  const subdomain = useRecoilValue(subdomainState);

  return (
    <div
      id="Layout2_Desktop_Root"
      className={cx(
        css`
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
          /* top: 0; bottom: 0;
          left: 0; right: 0; */
          overscroll-behavior: contain;
        `,
        // borders.DEBUG_blueBorderDashed,
      )}
    >
      <div
        id="Header_Root"
        className={cx(
          css`
            width: 100%;
            top: 0;
            height: 72px;
            @media (max-width: 1280px) {
              padding-left: 24px;
              padding-right: 24px;
            }
          `,
          borders.bottom_border_1px,
          // borders.DEBUG_greenBorderDashed,
        )}
      >
        <Header3Desktop withoutFullRefresh={withoutNavigationFullRefresh} />
      </div>
      <div
        id="Layout2_Desktop_Content"
        key={scrollAreaKey}
        style={{
          overflowY: !withoutOverflowScroll ? 'scroll' : 'hidden',
          overflowX: 'hidden',
        }}
        className={[
          css`
            position: absolute;
            top: 72px;
            bottom: 0px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          `,
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
      >
        <div
          id="Layout2_Desktop_ContentContainer"
          className={cx(
            css`
              width: 100%;
              height: 100%;

              display: flex;
              flex-direction: column;
              justify-content: space-between;
            `,
            // borders.DEBUG_greenBorderDashed,
          )}
        >
          <div
            id="Layout2_Desktop_ChildrenContainer"
            className={cx(
              css`
                width: 100%;
              `,
              !withoutMaxWidth
                ? css`
                    max-width: 1320px;
                    margin-left: auto;
                    margin-right: auto;
                  `
                : '',
              // borders.DEBUG_greenBorderDashed,
            )}
            style={{
              height: withoutOverflowScroll ? '100%' : undefined,
            }}
          >
            {children}
          </div>

          {/* Footer */}
          <div>
            {!withoutFooter && (
              <>
                {!withoutFooterTopLine && subdomain.includes('myrealtrip') ? (
                  <div
                    className={css`
                      height: 1px;
                      margin-top: 56px;
                      border-top: 1px solid #f4f4f4;
                    `}
                  />
                ) : (
                  <div
                    className={
                      css`
                        height: 1px;
                        margin-top: 130px;
                        border-top: 1px solid #f4f4f4;
                      `
                      // borders.DEBUG_blueBorderDashed,
                      // ].join(' ')
                    }
                  />
                )}
                <div
                  className={css`
                    width: 100%;
                    background-color: #f4f4f4;
                  `}
                >
                  <div
                    className={cx(
                      css`
                        width: 100%;
                        background-color: ${classOverrides?.footerBgColor};
                        @media (max-width: 1280px) {
                          /* padding-left: 24px; */
                          /* padding-right: 24px; */
                        }
                      `,
                      !withoutMaxWidth
                        ? subdomain.includes('myrealtrip')
                          ? Layout2DesktopStyles.withMaxWidth_MRT
                          : Layout2DesktopStyles.withMaxWidth
                        : '',
                      // borders.DEBUG_greenBorderDashed,
                    )}
                  >
                    <Footer3Desktop />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

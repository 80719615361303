import { css } from '@emotion/css';
import { Disclosure } from '@headlessui/react';

import { actions, sendTrackingLog } from '^/api/trackingLog';
import { COLORS2, font2 } from '^/constants/commonStyles';
import { convertLineBreaks } from '^/util/textFormat';

import ShopMap from './ShopMap';

const ShopInfoDesktop = ({ data, isDetail }) => {
  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <div>
          <Disclosure.Button
            className={css`
              text-align: left;
              color: ${COLORS2.main.MrtBlue1};
              cursor: pointer;
            `}
            onClick={() => {
              const action = open
                ? actions.DRIVER_DETAIL.CLOSE_SHOP_DETAIL
                : actions.DRIVER_DETAIL.OPEN_SHOP_DETAIL;
              sendTrackingLog({
                action,
              });
            }}
          >
            {open ? '대여 영업소 정보 접기' : '대여 영업소 정보 자세히 보기'}
          </Disclosure.Button>
          <Disclosure.Panel>
            <article className="gap-4">
              <div className="mt-4">
                <h3 className={[font2.Headline1_Bold].join('')}>대여 영업소 정보</h3>
                <div className="mt-2 flex flex-col gap-3 rounded-trimo bg-gray-100 p-4">
                  <div className="gap-1.5">
                    <ShopMap data={data} />
                    <h4 className={[font2.Body1_Bold].join('')}>주소</h4>
                    <p className={[font2.Body1_Regular].join('')}>
                      {data.plan_info.shop_address || '-'}
                    </p>
                  </div>
                  <div className="gap-1.5">
                    <h4 className={[font2.Body1_Bold].join('')}>오시는 방법</h4>
                    <p className={[font2.Body1_Regular].join('')}>
                      {convertLineBreaks(data.plan_info.shop_access) || '-'}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4 gap-1.5">
                <h3 className={[font2.Headline1_Bold].join('')}>대여점 공지사항</h3>
                <div className="mt-2 flex flex-col gap-3 rounded-trimo bg-gray-100 p-4">
                  <div className="gap-1.5">
                    <p className={[font2.Body1_Regular].join('')}>
                      {convertLineBreaks(data.plan_info.shop_notice) || '-'}
                    </p>
                  </div>
                </div>
              </div>
              {isDetail && (
                <div className="mt-4 gap-1.5">
                  <h3 className={[font2.Headline1_Bold].join('')}>일정 및 시간 변경 안내</h3>
                  <div className="mt-2 flex flex-col gap-3 rounded-trimo bg-gray-100 p-4">
                    <div className="gap-1.5">
                      <p className={[font2.Body1_Regular].join('')}>
                        예약 일정 및 차량, 옵션 등 예약 내용 변경을 희망하신다면 기존 예약 취소 후
                        새롭게 예약해주셔야 합니다.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </article>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};

export default ShopInfoDesktop;

import React, { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { actions, screen, sendTrackingLog } from '^/api/trackingLog';
import { SelectBoxInput } from '^/commonComponents/inputs/SelectBoxInput';
import { SPACING } from '^/constants/commonStyles';
import { isMinuteEnabled, OpenCloseTime } from '^/types/RawOpenHours';
import { ExtractReactProps } from '^/types/utils/extractReactProps';
import { MobxInputValue } from '^/util/MobxInputValue';

import { HourRenderer } from './HourRenderer';
import { MinuteRenderer } from './MinuteRenderer';
import {
  TimeSelectHour,
  TimeSelectHourNullable,
  TimeSelectMinute,
  TimeSelectMinuteNullable,
} from './TimeSelect.constants';

type SelectBoxInputProps = ExtractReactProps<typeof SelectBoxInput>;
type SelectBoxInputOverrides = Exclude<SelectBoxInputProps['overrides'], undefined>;
export const MRTTimeSelect: React.FC<{
  disabled?: boolean;
  openDirection?: SelectBoxInputOverrides['openDirection'];
  hour: MobxInputValue<TimeSelectHourNullable>;
  minute: MobxInputValue<TimeSelectMinuteNullable>;
  openCloseTimes: OpenCloseTime[];
  type?: string;
}> = observer(function MRTTimeSelect({
  disabled, openDirection, hour, minute, openCloseTimes, type,
}) {
  const enabledHours = TimeSelectHour.filter(
    (hour2) => isMinuteEnabled(openCloseTimes, Number(hour2), 0)
      || isMinuteEnabled(openCloseTimes, Number(hour2), 30),
  );
  const enabledHoursSet = new Set(enabledHours);
  // enabledMinutes: assume 'hour' is set
  const enabledMinutes = TimeSelectMinute.filter((a) => isMinuteEnabled(openCloseTimes, Number(hour.value), Number(a)),
  );
  const enabledMinutesSet = new Set(enabledMinutes);
  useEffect(() => {
    // FIXME: ...
    const enabledHoursAfter10 = enabledHours.filter((a) => a >= '10');
    if (hour.value === null && enabledHours.length > 0) {
      if (enabledHoursAfter10.length > 0) {
        hour.set(enabledHoursAfter10[0]);
      } else {
        hour.set(enabledHours[0]);
      }
    }

    if (hour.value !== null && minute.value === null && enabledMinutes.length > 0) {
      minute.set(enabledMinutes[0]);
    }
  }, [enabledHours, enabledMinutes, hour, minute]);
  return (
    <div
      className={cx(
        css`
          display: flex;
          gap: ${SPACING.spacing24};
          width: 100%;
          @media (max-width: 768px) {
            gap: ${SPACING.spacing16};
          }
        `,
      )}
    >
      <SelectBoxInput
        overrides={{
          rootProps: {
            disabled,
            onChange: async (value) => {
              sendTrackingLog({
                action:
                  type === 'pickup'
                    ? actions['/'].SELECT_PICKUP_HOUR
                    : actions['/'].SELECT_RETURN_HOUR,
                data: value,
                country: sessionStorage.getItem('selectedCountry') || '',
              });
            },
          },
          buttonRender: HourRenderer,
          optionRender: HourRenderer,
          openDirection,
        }}
        inputValue={hour}
        choices={TimeSelectHour}
        enabledItems={enabledHoursSet}
      />
      <SelectBoxInput
        overrides={{
          rootProps: {
            disabled,
            onChange: async (value) => {
              sendTrackingLog({
                action:
                  type === 'pickup'
                    ? actions['/'].SELECT_PICKUP_MINUTE
                    : actions['/'].SELECT_RETURN_MINUTE,
                data: value,
                country: sessionStorage.getItem('selectedCountry') || '',
              });
            },
          },
          buttonRender: MinuteRenderer,
          optionRender: MinuteRenderer,
          openDirection,
        }}
        inputValue={minute}
        choices={TimeSelectMinute}
        enabledItems={enabledMinutesSet}
      />
    </div>
  );
});

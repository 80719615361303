import { css } from '@emotion/css';
import { Disclosure } from '@headlessui/react';

import { actions, sendTrackingLog } from '^/api/trackingLog';
import { COLORS2, font2 } from '^/constants/commonStyles';
import { convertLineBreaks } from '^/util/textFormat';

import ShopMap from './ShopMap';

const ShopInfoMobile = ({ data, isDetail }) => {
  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={css`
              text-align: left;
              color: ${COLORS2.main.MrtBlue1};
              cursor: pointer;
            `}
            onClick={() => {
              const action = open
                ? actions.DRIVER_DETAIL.CLOSE_SHOP_DETAIL
                : actions.DRIVER_DETAIL.OPEN_SHOP_DETAIL;
              sendTrackingLog({
                action,
              });
            }}
          >
            {open ? '대여 영업소 정보 접기' : '대여 영업소 정보 자세히 보기'}
          </Disclosure.Button>
          <Disclosure.Panel>
            <article className="gap-4">
              <div>
                <h3 className={font2.Headline1_Bold}>대여 영업소 정보</h3>
                <div className="flex flex-col gap-3">
                  <div className="gap-1.5">
                    <ShopMap data={data} />
                    <h4 className={font2.Body1_Bold}>주소</h4>
                    <p
                      className={[
                        font2.Caption1_Light,
                        css`
                          word-break: keep-all;
                        `,
                      ].join(' ')}
                    >
                      {data.plan_info.shop_address || '-'}
                    </p>
                  </div>
                  <div className="gap-1.5">
                    <h4 className={font2.Body1_Bold}>오시는 방법</h4>
                    <p
                      className={[
                        font2.Caption1_Light,
                        css`
                          word-break: keep-all;
                        `,
                      ].join(' ')}
                    >
                      {convertLineBreaks(data.plan_info.shop_access) || '-'}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4 gap-1.5">
                <h3 className={font2.Headline1_Bold}>대여점 공지사항</h3>
                <div className="flex flex-col gap-3">
                  <div className="gap-1.5">
                    <p className={font2.Caption1_Light}>
                      {convertLineBreaks(data.plan_info.shop_notice) || '-'}
                    </p>
                  </div>
                </div>
              </div>
              {isDetail && (
                <div className="mt-4 gap-1.5">
                  <h3 className={font2.Headline1_Bold}>일정 및 시간 변경 안내</h3>
                  <div className="flex flex-col gap-3">
                    <div className="gap-1.5">
                      <p className={font2.Caption1_Light}>
                        예약 일정 및 차량, 옵션 등 예약 내용 변경을 희망하신다면 기존 예약 취소 후
                        새롭게 예약해주셔야 합니다.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </article>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default ShopInfoMobile;

import { OnlineTravelOffices } from '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { CityCodeType } from '^/types/__BrandedLocationTypes';

export const popularCityList = [
  {
    name: 'Okinawa',
    code: '47003' as CityCodeType,
    nameKo: '오키나와',
    vendor: 'OT',
    hertzCode: 'OKA',
    countryCode: 'Japan',
  },
  {
    name: 'Fukuoka',
    code: '40001' as CityCodeType,
    nameKo: '후쿠오카',
    vendor: 'OT',
    hertzCode: 'FUK',
    countryCode: 'Japan',
  },
  {
    name: 'Sapporo',
    code: '01003' as CityCodeType,
    nameKo: '삿포로',
    vendor: 'OT',
    hertzCode: 'SPK',
    countryCode: 'Japan',
  },
  {
    name: 'Tokyo',
    hertzCode: 'TYO',
    code: '13013' as CityCodeType,
    nameKo: '도쿄',
    vendor: 'OT',
    countryCode: 'Japan',
  },
  {
    name: 'Osaka',
    hertzCode: 'OSA',
    code: '27004' as CityCodeType,
    nameKo: '오사카',
    vendor: 'OT',
    countryCode: 'Japan',
  },
  {
    name: 'Honolulu',
    code: 'HNL' as CityCodeType,
    nameKo: '호놀룰루',
    countryCode: 'United States',
  },
  {
    name: 'Kahului',
    code: 'OGG' as CityCodeType,
    nameKo: '카훌루이',
    countryCode: 'United States',
  },
  {
    name: 'Kailua-Kona',
    code: 'KOA' as CityCodeType,
    nameKo: '카일루아 코나',
    countryCode: 'United States',
  },
  {
    name: 'Guam',
    code: 'GUM' as CityCodeType,
    nameKo: '괌',
    countryCode: 'GU',
  },
  {
    name: 'Los Angeles',
    code: 'LAX' as CityCodeType,
    nameKo: '로스앤젤레스',
    countryCode: 'United States',
  },
  {
    name: 'Las Vegas',
    code: 'LAS' as CityCodeType,
    nameKo: '라스베가스',
    countryCode: 'United States',
  },
  {
    name: 'San Francisco',
    code: 'SFO' as CityCodeType,
    nameKo: '샌프란시스코',
    countryCode: 'United States',
  },
  {
    name: 'Saipan',
    code: 'SPN' as CityCodeType,
    nameKo: '사이판',
    countryCode: 'Northern Mariana Islands',
  },
  {
    name: 'Rome',
    code: 'ROM' as CityCodeType,
    nameKo: '로마',
    countryCode: 'Italy',
  },
  {
    name: 'Paris',
    code: 'PAR' as CityCodeType,
    nameKo: '파리',
    countryCode: 'France',
  },
];

import React from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { actions, sendTrackingLog } from '^/api/trackingLog';
import { PADDINGS, font2 } from '^/constants/commonStyles';

export const ListingPageOnlineTravelCardShopInfo: React.FC<{
  data: any;
}> = observer(function ListingPageOnlineTravelCardShopInfo({ data }) {
  return (
    <section
      id="ListingPageOnlineTravelCard_ShopInfo"
      className={[
        css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: clamp(10px, 70px - ((900px - 100vw) * 0.5), 70px);
          padding-right: clamp(10px, 72px - ((900px - 100vw) * 0.5), 72px);
          border-bottom: 1px #eee solid;
        `,
        PADDINGS.TOP_AND_BOTTOM.spacing24,
        // borders.DEBUG_orangeBorderDashed,
      ].join(' ')}
    >
      <p id="ListingPageOnlineTravelCard_ShopName" className={font2.Caption1_Light}>
        {data.shop_name}
      </p>
      {data.shop_address && (
        <p
          id="ListingPageOnlineTravelCard_ShopAddress"
          className={[
            font2.Caption1_Light,
            css`
              cursor: pointer;
            `,
          ].join(' ')}
          onClick={async () => {
            sendTrackingLog({
              action: actions.CAR_LIST.CLICK_SHOP_ADDRESS,
              data: data.code,
            });
            window.open(
              `https://www.google.com/maps/search/${data.shop_address.replace(' ', '+')}`,
            );
          }}
        >
          {data.shop_address}
        </p>
      )}
    </section>
  );
});

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { actions, sendTrackingLog } from '^/api/trackingLog';
import {
  SPACING,
  MARGINS,
  PADDINGS,
  COLORS2,
  COLORS2_AS_FOREGROUND,
  font2,
} from '^/constants/commonStyles';
import { formatKRWWonAmount } from '^/util/formatKRWWonAmount';
import { priceWithComma } from '^/util/priceFormat';

import { translateServicesForOntra } from '../../common/translateServices';
import { ListingPageOnlineTravelViewModel } from '../viewmodel/ListingPage.OnlineTravel.viewmodel';

export const ListingPageOnlineTravelCardCarInfo: React.FC<{
  viewmodel: ListingPageOnlineTravelViewModel;
  data: any;
}> = observer(function ListingPageOnlineTravelCardCarInfo({ viewmodel, data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { car_info, equipment_info_list, plan_info } = data;

  return (
    <section
      id="ListingPageOnlineTravelCard_CarInfo"
      className={[
        css`
          display: flex;
          width: 100%;
          min-height: 180px;
        `,
        PADDINGS.TOP_AND_BOTTOM.spacing32,
      ].join(' ')}
    >
      <img
        src={car_info.image}
        alt="carImage"
        className={[
          css`
            width: 220px;
            height: 135px;
            margin-left: clamp(10px, 70px - ((900px - 100vw) * 0.5), 70px);
            object-fit: contain;
          `,
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
        onError={(e) => {
          e.currentTarget.src = '/NOT_FOUND_CAR.png';
        }}
      />
      <div
        className={cx(
          css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: clamp(10px, 50px - ((800px - 100vw) * 0.5), 50px);
          `,
        )}
      >
        <h2
          className={[
            font2.Title2,
            css`
              padding-right: 50px;
            `,
          ].join(' ')}
        >
          {`[${car_info.class_name}] ${car_info.model_name}`}
        </h2>
        <h3
          className={[
            font2.Body1_Regular,
            COLORS2_AS_FOREGROUND.main.MrtBlack,
            MARGINS.TOP.spacing24,
            PADDINGS.LEFT_AND_RIGHT.spacing12,
            PADDINGS.TOP_AND_BOTTOM.spacing6,
            css`
              margin-top: ${SPACING.spacing24};
              border-radius: 5px;
              background-color: #f9f9f9;
              width: fit-content;
            `,
          ].join(' ')}
        >
          {translateServicesForOntra(equipment_info_list, plan_info.shuttle_flag, t)}
        </h3>
      </div>
      <div
        className={[
          css`
            display: flex;
            flex-direction: column;
            align-items: end;
            margin-left: auto;
            margin-right: clamp(10px, 72px - ((900px - 100vw) * 0.5), 72px);
            justify-content: space-between;
          `,
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
      >
        <h1
          className={[
            font2.Title1,
            css`
              white-space: nowrap;
            `,
          ].join(' ')}
        >
          <span className={[font2.Caption1_Bold, MARGINS.RIGHT.spacing8].join(' ')}>최저</span>
          {`${priceWithComma(data.payment_info.foreign_price)} 
          ${data.payment_info.foreign_currency_code}`}
        </h1>
        <h2 className={[COLORS2_AS_FOREGROUND.sub.MrtGray1, font2.Body1_Regular].join(' ')}>
          {`약 ${formatKRWWonAmount(data.payment_info.domestic_price)}원`}
        </h2>
        <button
          className={[
            COLORS2_AS_FOREGROUND.main.MrtBlue1,
            MARGINS.LEFT.spacing16,
            MARGINS.TOP.spacing12,
            font2.Body1_Bold,
            css`
              border: ${COLORS2.main.MrtBlue1} solid 1px;
              border-radius: 5px;
              width: 92px;
              height: 42px;
            `,
          ].join(' ')}
          onClick={() => {
            sendTrackingLog({
              action: actions.CAR_LIST.CLICK_CAR_RESERVATION,
              data: data.plan_info.code,
            });
            const routeParams = {
              carInfo: { ...data },
              listingInfo: {
                ...viewmodel.routeParamsValues,
                retry: 0,
              },
              loading: true,
            };
            sessionStorage.setItem('LOCATION_STATE', JSON.stringify(routeParams));
            setTimeout(() => {
              navigate(`/booking?id=${data.plan_info.code}&label=car_details`, {
                state: routeParams,
              });
            }, 300);
          }}
          type="button"
        >
          예약
        </button>
      </div>
    </section>
  );
});

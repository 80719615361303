/* eslint-disable no-nested-ternary */
import React from 'react';

import { css, cx } from '@emotion/css';
import { useRecoilValue } from 'recoil';

import { SPACING, font } from '^/constants/commonStyles';
import { subdomainState } from '^/recoil/atoms';

import { TimeSelectHourNullable } from './TimeSelect.constants';
import { classNames } from './TimeSelect.styles';

export const HourRenderer: React.FC<{
  value: TimeSelectHourNullable;
  isOpen?: boolean;
  isListItem?: boolean;
  disabled?: boolean;
  isNotSelected?: boolean;
}> = ({
  value, isOpen, isListItem, disabled, isNotSelected,
}) => {
  const subdomain = useRecoilValue(subdomainState);
  const isMRT = subdomain.includes('myrealtrip');
  const AMPM = (value !== null && value > '11') ? '오후' : '오전';

  const numberValue = Number(value);
  const hour = numberValue > 12 ? numberValue - 12 : numberValue;

  return (
    <div
      className={[
        isListItem
          ? cx(classNames.btnRendererBasic, font.Body1_Regular)
          : classNames.btnRendererBasic,
        disabled
          ? isMRT ? css`color: #cecece !important; cursor:` : css`opacity: 0.3;`
          : '',
        isMRT && css`height: 40px;
           padding: 0 2px 0 7px !important;
           cursor: default;`,
        isMRT && isListItem && css`height: 30px;`,
      ].join(' ')}
    >
      <div>
        {isMRT
        && (
        <span
          className={cx(isMRT && classNames.timeSelected,
            isNotSelected && classNames.timeNotSelected)}
        >
          {AMPM}{' '}
        </span>
        )}
        {isMRT
        && (
        <span
          className={cx(classNames.timeSelected,
            isNotSelected && classNames.timeNotSelected)}
        >
          {hour}시
        </span>
        )}
        {!isMRT && (
        <span>
          {value}시
        </span>
        )}
      </div>
      {!isListItem && (
        <img
          alt="hour-list-toggle"
          src={isMRT
            ? isOpen ? './arrow_drop_up_20.svg' : './arrow_drop_down_20.svg'
            : isOpen ? './ic_arrow_up.png' : './ic_arrow_down.png'}
          className={css`
          @media (max-width: 768px) {
            width: ${SPACING.spacing16};
            height: ${SPACING.spacing16};
          }
        `}
        />
      )}
    </div>
  );
};

import { useEffect, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { TextInput } from '^/commonComponents/inputs/TextInput';
import { MRTCarListingBottomSheetWrapper } from '^/components/CarSearchBox/myrealtrip/mobile/MRTCarListingBottomSheetWrapper';
import { SPACING, font } from '^/constants/commonStyles';
import { NOTICE_DETAIL } from '^/constants/routes';
import { typeRouter } from '^/typeRoute/typeRoutes';

import { MRTCarListingSearchBoxMobileRoot } from '../../../../components/CarSearchBox/myrealtrip/mobile/MRTCarListingSearchBox.mobile.root';
import MRTBanner from '../../../Notice/components/MRTBanner';
import { fakeBanner } from '../../../Notice/data';
import { HomeViewModel } from '../../base/viewmodel/Home.viewmodel';

export const MRTHomeMobile: React.FC<{
  viewmodel: HomeViewModel;
}> = observer(function HomePage2Mobile({
  viewmodel,
}) {
  const navigate = useNavigate();
  const pickupCityInputRef = useRef<HTMLInputElement>(null);
  const pickupCityInputDummyRefForIOSKeyboard = useRef<HTMLInputElement>(null);
  const returnCityInputDummyRefForIOSKeyboard = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const unlisten = typeRouter.session.listen((nextRoute) => {
      if (nextRoute.action === 'pop') {
        viewmodel.carListingSearchBox.stepManager.updatePushCountBy(-1);
      }
    });
    return unlisten;
  });

  return (
    <>
      <TextInput
        id="DummyInputForPickupCityIOSKeyboardPopup"
        rootRef={pickupCityInputDummyRefForIOSKeyboard}
        viewmodel={viewmodel
          .carListingSearchBoxInputVM
          .pickupCitySearch
          .searchInput}
        className={css`
          position: fixed;
          top:0; left: 0;
          bottom: 0;
          opacity: 0.5;
          z-index: -200;
          /* hide caret from screen */
          transform: translate(-100px, -100px);
        `}
      />
      <TextInput
        id="DummyInputForReturnCityIOSKeyboardPopup"
        rootRef={returnCityInputDummyRefForIOSKeyboard}
        viewmodel={viewmodel
          .carListingSearchBoxInputVM
          .returnCitySearch
          .searchInput}
        className={css`
          position: fixed;
          top:0; left: 0;
          bottom: 0;
          opacity: 0.2;
          z-index: -500;
          /* hide caret from screen */
          transform: translate(-100px, -100px);
        `}
      />
      {(viewmodel.carListingSearchBox
        .stepManager
        .getSteps()
        .length || null) && (
          <MRTCarListingBottomSheetWrapper
            pickupCityInputRef={pickupCityInputRef}
            pickupCityInputDummyRefForIOSKeyboard={pickupCityInputDummyRefForIOSKeyboard}
            returnCityInputDummyRefForIOSKeyboard={returnCityInputDummyRefForIOSKeyboard}
            viewmodel={viewmodel.carListingSearchBox}
            carsScrollAreaRef={null}
            carClassChipsScrollRef={null}
          />
      )}
      <div
        className={css`
          padding-left: ${SPACING.spacing24};
          padding-right: ${SPACING.spacing24};
          padding-top: ${SPACING.spacing24};
          padding-bottom: ${SPACING.spacing16};
        `}
      >
        <h1
          className={cx(
            font.Title2,
            css`
              display: flex;
              flex-direction: column;
              font-size: 24px;
              line-height: 32px;
            `,
          )}
        >
          <div>해외 렌터카</div>
          <div>최저가로 찾아보세요.</div>
        </h1>
      </div>
      <div
        className={css`
          padding-left: ${SPACING.spacing24};
          padding-right: ${SPACING.spacing24};
          padding-top: ${SPACING.spacing16};
          padding-bottom: ${SPACING.spacing32};
        `}
      >
        <MRTCarListingSearchBoxMobileRoot viewmodel={viewmodel.carListingSearchBox} />
        <button
          type="button"
          className={cx(
            css`
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #fff;
              width: 100%;
              height: 48px;
              border-radius: 8px;
              color: #717171;
              margin-top: 8px;
              font-size: 16px;
              font-weight: 600;
              border: 1px solid #cecece;
            `,
          )}
          onClick={() => typeRouter.routes.reservationCheck().push()}
        >
          예약 조회하기
        </button>
        <div
          className={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 52px;
          `}
        >
          {fakeBanner.map((banner) => {
            return (
              <MRTBanner
                key={banner.id}
                item={banner}
                onClick={() => {
                  navigate({
                    pathname: NOTICE_DETAIL,
                    search: `${new URLSearchParams({
                      id: String(banner.id),
                    })}`,
                  });
                }}
                hasIcon={false}
              />
            );
          })}
        </div>
      </div>
    </>
  );
});

import { css, cx } from '@emotion/css';
import { useMediaQuery } from 'react-responsive';

import { SPACING, COLORS2, font } from '^/constants/commonStyles';

import { WEEK_KO } from '../../Calendar.constants';

export const WeekDays:React.FC<{ subdomain?: string }> = ({ subdomain }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isMRT = subdomain?.includes('myrealtrip');

  return (
    <div
      className={cx(
        css`
          display: flex;
          width: 100%;
          color: ${COLORS2.main.MrtBlack};
        `,
        (isMobile && !isMRT)
          ? font.Caption1_Light
          : cx(
            css`
              margin-top: ${SPACING.spacing24};
            `,
            font.Body1_Regular,
          ),
        isMRT
        && css`margin: 8px 0 4px !important;`,
      )}
    >
      {WEEK_KO.map((day) => (
        <div
          key={day}
          className={cx(
            css`
              display: flex;
              justify-content: center;
              width: 14.28%;
            `,
            isMRT && css`padding: 8px;`,
          )}
        >
          <span
            className={
              cx(
                css`
                  display: flex;
                  justify-content:center;
                  align-items:center;
                `,
                isMRT
              && css`
                font-size: 12px;
                color: #9f9f9f;
                font-weight: 500;
                line-height: 16px;
              `)
            }
          >
            {day}
          </span>
        </div>
      ))}
    </div>
  );
};

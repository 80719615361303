import { useMemo } from 'react';

import { Tab } from '@headlessui/react';

import { mrtCitiesByCountry } from '^/components/CarSearchBox/myrealtrip/mobile/contentsArea/SelectCity/mrtCitiesByCountry';

import { Style } from '../MRTCarListingSearchBox.desktop.contentsArea.style';

const MRTPopularCityTabList = ({ cityContainerRef }) => {
  const continents = useMemo(
    () => ['인기도시'].concat(mrtCitiesByCountry.map((continentItem) => continentItem.continent)), [],
  );

  return (
    <Tab.List className={Style.tabList}>
      {continents.map((tab, index) => (
        <Tab
          className={({ selected }) => `${selected ? Style.tabSelected : Style.tabDefault}`}
          onClick={() => {
            const contentEl = document.querySelector<HTMLElement>('#Layout_Desktop_Content');
            const pageOriginalScroll = contentEl!.scrollTop || 0;
            const el = cityContainerRef.current?.querySelector(`[data-tab-index='${index}']`)?.querySelector('[data-first-country=true]');
            el?.scrollIntoView();
            contentEl!.scrollTop = pageOriginalScroll; // 전체 페이지 스크롤 이동 방지
          }}
        >
          {tab}
        </Tab>
      ))}
    </Tab.List>
  );
};

export default MRTPopularCityTabList;

import { useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useRecoilValue } from 'recoil';

import { ViewByState } from '^/commonComponents/ui/ViewByState';
import { CarListingSearchBoxDesktop } from '^/components/CarSearchBox/base/desktop/CarListingSearchBox.desktop';
import { Footer2 } from '^/components/Footer/Footer2';
import { Layout2Desktop } from '^/components/Layout/Layout2Desktop';
import { SPACING, MARGINS, COLORS2 } from '^/constants/commonStyles';
import { subdomainState } from '^/recoil/atoms';

import { ListingPageDesktopLoading } from '../../base/desktop/ListingPage.Desktop.Loading';
import { ListingPage2DesktopNoCar } from '../../base/desktop/ListingPage2.Desktop.NoCar';
import { ListingPageOnlineTravelViewModel } from '../viewmodel/ListingPage.OnlineTravel.viewmodel';

import { ListingPageOnlineTravelCard } from './ListingPage.OnlineTravel.Desktop.Card';

export const ListingPageOnlineTravelDesktop: React.FC<{
  viewmodel: ListingPageOnlineTravelViewModel;
}> = observer(function ListingPageOnlineTravelDesktop({ viewmodel }) {
  const classNames = {
    maxWidthAndCenter: css`
      width: 100%;
      max-width: 1320px;
      margin-left: auto;
      margin-right: auto;
    `,
    listingPage2DesktopCardWrapper: css`
      width: 100%;

      max-width: 1320px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: ${SPACING.spacing56};

      display: flex;
      flex-direction: column;
      gap: ${SPACING.spacing32};
    `,
  };

  const [hoverSearchBox, setHoverSearchBox] = useState(false);
  const carListngSearchBoxRef = useRef<HTMLDivElement>(null);
  const carsScrollAreaRef = useRef<HTMLDivElement>(null);

  const subdomain = useRecoilValue(subdomainState);
  const isMRT = subdomain.includes('myrealtrip');

  return (
    <Layout2Desktop
      withoutFooter
      withoutMaxWidth
      withoutOverflowScroll
      withoutNavigationFullRefresh
    >
      <div
        ref={carsScrollAreaRef}
        className={cx(
          css`
            background-color: ${COLORS2.sub.MrtGray3};
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
          `,
          // borders.DEBUG_orangeBorderDashed,
        )}
      >
        <div
          className={cx(
            css`
              position: sticky;
              top: 0px;
              width: 100%;
              max-width: 1320px;
              margin-left: auto;
              margin-right: auto;
              background-color: ${COLORS2.sub.MrtGray3};
              padding-top: ${SPACING.spacing24};
              padding-bottom: ${SPACING.spacing32};
              z-index: 30;
            `,
            // borders.DEBUG_purpleBorderDashed,
          )}
        >
          <div
            ref={carListngSearchBoxRef}
            // tabIndex={-1}
            onFocus={() => setHoverSearchBox(true)}
            onBlur={(e) => {
              if (e.currentTarget.contains(e.relatedTarget)) {
                return;
              }
              setHoverSearchBox(false);
            }}
            className={isMRT ? css`
              width: 1060px;
              margin: 0 auto;
            ` : css``}
          >
            <CarListingSearchBoxDesktop
              smaller
              viewmodel={viewmodel.carListingSearchBox}
              onBlur={() => viewmodel.carListingSearchBox.stepManager.clearStep()}
            />
          </div>
        </div>
        <div className={isMRT ? css`
          width: 1060px;
          margin: 0 auto;
        ` : css``}
        >
          <ViewByState
            loadingView={<ListingPageDesktopLoading />}
            errorView={(e) => {
              return (
                <div className={[MARGINS.BOTTOM.spacing154].join(' ')}>
                  <ListingPage2DesktopNoCar />
                </div>
              );
            }}
            data={viewmodel.allCarList}
          >
            {(carList) => {
              return (
                <section className={classNames.listingPage2DesktopCardWrapper}>
                  {carList.map((car) => {
                    return <ListingPageOnlineTravelCard data={car} viewmodel={viewmodel} />;
                  })}
                </section>
              );
            }}
          </ViewByState>
        </div>
        <Footer2 />
      </div>
    </Layout2Desktop>
  );
});

import axios from '../../axios/index';

const BASE_PATH = '/translation';
export const getAllLocales = async () => {
  const response = await axios.fetch({
    url: `${BASE_PATH}/locales`,
    method: 'GET',
  });
  return response;
};

export const getDefaultLocales = async () => {
  const response = await axios.fetch({
    url: `${BASE_PATH}/default-locale`,
    method: 'GET',
  });
  return response;
};

export const getAllTranslations = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const response = await axios.fetch({
    url: `${BASE_PATH}/all?${queryString}`,
    method: 'GET',
  });
  return response;
};


import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { actions, sendTrackingLog } from '^/api/trackingLog';
import {
  SPACING,
  MARGINS,
  PADDINGS,
  COLORS2,
  COLORS2_AS_BACKGROUND,
  COLORS2_AS_FOREGROUND,
  font2,
} from '^/constants/commonStyles';
import { formatKRWWonAmount } from '^/util/formatKRWWonAmount';
import { priceWithComma } from '^/util/priceFormat';

import { ListingPageOnlineTravelViewModel } from '../viewmodel/ListingPage.OnlineTravel.viewmodel';
import { translateServicesForOntra } from '../../common/translateServices';

export const ListingPageOnlineTravelMobileCard: React.FC<{
  data: any;
  viewmodel: ListingPageOnlineTravelViewModel;
}> = observer(function ListingPageOnlineTravelMobileCard({ data, viewmodel }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { car_info, equipment_info_list, plan_info } = data;

  return (
    <div
      className={[
        css`
          background-color: ${COLORS2.main.MrtWhite};
          padding: ${SPACING.spacing16};
          border-radius: 10px;
        `,
      ].join(' ')}
    >
      <div
        id="CarListing2"
        className={cx(
          css`
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
          `,
        )}
      >
        <section
          className={cx(
            css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `,
          )}
        >
          <h1
            className={[
              font2.Headline1_Bold,
              css`
                text-align: center;
              `,
            ].join(' ')}
          >
            {`[${car_info.class_name}] ${car_info.model_name}`}
          </h1>
          <h4 className={cx(font2.Caption2_Light, COLORS2_AS_FOREGROUND.sub.MrtGray1)}>
            {translateServicesForOntra(equipment_info_list, plan_info.shuttle_flag, t)}
          </h4>
        </section>
        <img
          alt="car"
          src={car_info.image}
          className={[
            css`
              display: block;
              width: 152px;
              height: 128px;
              object-fit: contain;
              margin: ${MARGINS.BOTTOM.spacing10};
            `,
          ].join(' ')}
          onError={(e) => {
            e.currentTarget.src = '/NOT_FOUND_CAR.png';
          }}
        />
        <section
          className={[
            css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
            `,
          ].join(' ')}
        >
          <p
            id="ListingPageOnlineTravelMobileCard_ShopName"
            className={cx(font2.Caption2_Light, COLORS2_AS_FOREGROUND.main.MrtBlack)}
          >
            {plan_info.shop_name}
          </p>
          <p
            id="ListingPageOnlineTravelMobileCard_ShopAddress"
            className={cx(font2.Caption2_Light, COLORS2_AS_FOREGROUND.main.MrtBlack)}
          >
            {plan_info.shop_address}
          </p>
        </section>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={[
          css`
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 5px;
          `,
          COLORS2_AS_BACKGROUND.sub.MrtGray3,
          MARGINS.TOP.spacing12,
          PADDINGS.TOP_AND_BOTTOM.spacing12,
        ].join(' ')}
        onClick={() => {
          // TODO: go to booking page
          sendTrackingLog({
            action: actions.CAR_LIST.CLICK_CAR_RESERVATION,
            data: data.plan_info.code,
          });
          const routeParams = {
            carInfo: { ...data },
            listingInfo: {
              ...viewmodel.routeParamsValues,
              retry: 0,
            },
            loading: true,
          };
          sessionStorage.setItem('LOCATION_STATE', JSON.stringify(routeParams));
          setTimeout(
            () =>
              navigate(`/booking?id=${data.plan_info.code}&label=car_details`, {
                state: routeParams,
              }),
            300,
          );
        }}
      >
        <h2 className={[font2.Title3, COLORS2_AS_FOREGROUND.main.MrtBlack].join(' ')}>
          <span className={[font2.Caption1_Bold, MARGINS.RIGHT.spacing6].join(' ')}>최저</span>
          {`${priceWithComma(data.payment_info.foreign_price)} ${
            data.payment_info.foreign_currency_code
          }`}
        </h2>
        <h3 className={[font2.Caption1_Light, COLORS2_AS_FOREGROUND.sub.MrtGray1].join(' ')}>
          {`약 ${formatKRWWonAmount(data.payment_info.domestic_price)}원`}
        </h3>
      </div>
    </div>
  );
});

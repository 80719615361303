/* eslint-disable operator-linebreak */
import intervalToDuration from 'date-fns/intervalToDuration';
import { makeAutoObservable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { isOntra } from '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { AppContext } from '^/AppContext/AppContext';
import { getWeekDayFromDay } from '^/components/Calendar/BasicCalendar/components/Day/DayType';
import { CalendarListViewModel } from '^/components/Calendar/BasicCalendar/components/List/Calendar.List.viewmodel';
import { RentalOfficeInputViewModel } from '^/components/CarSearchBox/base/common/RentalOfficeInput/RentalOfficeInput.viewmodel';
import { popularCityList } from '^/components/CarSearchBox/common/popularCity.constants';
import { encodeDatetimeWithoutTimezone, fromShortBirthday } from '^/types/__BrandedDateTimeTypes';
import { CityCodeType, CityNameType, RentalCarOfficeId } from '^/types/__BrandedLocationTypes';
import {
  asFailedPromise,
  asSuccessPromise,
  FreshnessType,
  NotInitiatedPromiseStale,
} from '^/types/__ResultType';
import { durationToText } from '^/util/durationToText';
import { MobxInputValue } from '^/util/MobxInputValue';

import { CarListingSearchBoxInputViewModel } from './CarListingSearchBox.InputViewmodel';
import { CarListingSearchBoxSubmitActionVM } from './CarListingSearchBox.SubmitActionVM';
import { BaseSearchBoxStepManager } from './SearchBoxStepManager';

export class CarListingSearchBoxViewModel {
  constructor(
    public appContext: AppContext,
    public freshness: FreshnessType,
    public calendarListViewModel: CalendarListViewModel,
    public i: CarListingSearchBoxInputViewModel,
    public stepManager: BaseSearchBoxStepManager,
    public showDifferentLocationAlert = new MobxInputValue(false),
  ) {
    makeAutoObservable(this, {
      getLocationById: false,
    });
    popularCityList.forEach((city) => {
      this.appContext.searchCityRepo.setById(city.code, {
        code: city.code,
        korean_name: city.nameKo,
        name: city.name,
      });
    });
  }

  getLocationById = computedFn((locationId: RentalCarOfficeId, cityCode: CityCodeType) => {
    const officeLocations = this.appContext.rentalCarOfficeLocationsCore.getRentalOfficeLocations(
      cityCode,
      this.freshness,
    );
    if (officeLocations.status !== 'success') {
      return undefined;
    }
    return this.appContext.rentalCarOfficeLocationsRepo.getById(locationId);
  });

  get pickupLocation() {
    if (this.i.pickupLocationId.value === null) {
      return undefined;
    }
    if (this.i.pickupCityCode.value === null) {
      return undefined;
    }
    return this.getLocationById(this.i.pickupLocationId.value, this.i.pickupCityCode.value);
  }

  get tempPickupLocation() {
    if (this.i.tempPickupLocationId.value === null) {
      return undefined;
    }
    if (this.i.tempPickupCityCode.value === null) {
      return undefined;
    }
    return this.getLocationById(this.i.tempPickupLocationId.value, this.i.tempPickupCityCode.value);
  }

  getLocationInputViewModelByCityCode = computedFn(
    (
      cityCode: CityCodeType | null,
      outputSelectedLocationIdViewModel: MobxInputValue<RentalCarOfficeId | null>,
    ) => {
      if (!cityCode) {
        return NotInitiatedPromiseStale;
      }
      const result = this.appContext.rentalCarOfficeLocationsCore.getRentalOfficeLocations(
        cityCode,
        this.freshness,
      );
      if (result.status !== 'success') {
        return result;
      }
      return asSuccessPromise(
        new RentalOfficeInputViewModel(
          this.appContext,
          result.value,
          outputSelectedLocationIdViewModel,
        ),
      );
    },
  );

  get pickupLocationViewModel() {
    return this.getLocationInputViewModelByCityCode(
      this.i.pickupCityCode.value,
      this.i.pickupLocationId,
    );
  }

  get returnLocation() {
    if (this.i.returnLocationId.value === null) {
      return undefined;
    }
    if (this.i.returnCityCode.value === null) {
      return undefined;
    }
    return this.getLocationById(this.i.returnLocationId.value, this.i.returnCityCode.value);
  }

  get returnLocationViewModel() {
    return this.getLocationInputViewModelByCityCode(
      this.i.returnCityCode.value,
      this.i.returnLocationId,
    );
  }

  get submitActionVM() {
    if (!this.i.birthdayInput.value) {
      return asFailedPromise({
        reason: 'birthdayMissing',
      } as const);
    }
    if (this.birthdayAgeValidation.status !== 'success') {
      return asFailedPromise({
        reason: 'invalidBirthday',
        err: this.birthdayAgeValidation,
        birthdayInput: this.i.birthdayInput.value,
      } as const);
    }
    const dateRange = this.i.calendarStateViewModel.selection;
    if (!dateRange.start || !dateRange.end) {
      return asFailedPromise({
        reason: 'DateRangeStartOrEndMissing',
        dateRange,
      } as const);
    }
    if (!this.i.pickupCityCode.value) {
      return asFailedPromise({
        reason: 'pickupCityCodeMissing',
      } as const);
    }
    if (!this.pickupLocation) {
      return asFailedPromise({
        reason: 'pickupLocationMissing',
      } as const);
    }
    const returnLocation =
      this.i.hasDifferentPickupReturnLocations.value === true
        ? this.returnLocation
        : this.pickupLocation;
    if (!returnLocation) {
      return asFailedPromise({
        reason: 'returnLocationMissing',
      } as const);
    }
    const pickupValidatedTime = this.i.scheduleTimeViewModel.validatePickupTime(
      this.pickupLocation.openCloseTimes[getWeekDayFromDay(dateRange.start)],
    );
    const returnValidatedTime = this.i.scheduleTimeViewModel.validateReturnTime(
      returnLocation.openCloseTimes[getWeekDayFromDay(dateRange.end)],
    );
    if (pickupValidatedTime.status !== 'success') {
      return asFailedPromise({
        reason: 'pickupTimeInvalid',
        err: pickupValidatedTime,
      } as const);
    }
    if (returnValidatedTime.status !== 'success') {
      return asFailedPromise({
        reason: 'returnTimeInvalid',
        err: returnValidatedTime,
      } as const);
    }
    const birthDate = fromShortBirthday(this.i.birthdayInput.value);
    const pCityName = this.i.pickupCitySearch.searchInput.value as CityNameType;
    if (!this.i.hasDifferentPickupReturnLocations.value) {
      return asSuccessPromise(
        new CarListingSearchBoxSubmitActionVM({
          birthday: birthDate,
          pCityCode: this.i.pickupCityCode.value,
          pCityName,
          pLocationCode: this.pickupLocation.oag_code,
          pParentCategoryCode: this.pickupLocation.category_id,
          pLocationId: this.pickupLocation.location_id,
          pLocationName: this.pickupLocation.locationDescriptions.get('ko')?.description,
          pDatetime: encodeDatetimeWithoutTimezone(
            dateRange.start,
            pickupValidatedTime.value.pickupHour,
            pickupValidatedTime.value.pickupMinute,
          ),

          pIsDiff: this.i.hasDifferentPickupReturnLocations.value,
          rDatetime: encodeDatetimeWithoutTimezone(
            dateRange.end,
            returnValidatedTime.value.returnHour,
            returnValidatedTime.value.returnMinute,
          ),
          steps: [],

          pickupCitySearchText: this.i.pickupCitySearch.searchInput.value,
          returnCitySearchText: this.i.returnCitySearch.searchInput.value,
        }),
      );
    }
    if (!this.i.returnCityCode.value) {
      return asFailedPromise({
        reason: 'returnCityCodeMissing',
      } as const);
    }
    const rCityName = this.i.returnCitySearch.searchInput.value as CityNameType;
    return asSuccessPromise(
      new CarListingSearchBoxSubmitActionVM({
        birthday: birthDate,
        pCityCode: this.i.pickupCityCode.value,
        pCityName,
        pLocationCode: this.pickupLocation.oag_code,
        pParentCategoryCode: this.pickupLocation.category_id,
        pLocationId: this.pickupLocation.location_id,
        pLocationName: this.pickupLocation.locationDescriptions.get('ko')?.description,
        pDatetime: encodeDatetimeWithoutTimezone(
          dateRange.start,
          pickupValidatedTime.value.pickupHour,
          pickupValidatedTime.value.pickupMinute,
        ),

        pIsDiff: this.i.hasDifferentPickupReturnLocations.value,
        rCityCode: this.i.returnCityCode.value,
        rCityName,
        rLocationCode: returnLocation.oag_code,
        rParentCategoryCode: returnLocation.category_id,
        rLocationId: returnLocation.location_id,
        rLocationName: returnLocation.locationDescriptions.get('ko')?.description,
        rDatetime: encodeDatetimeWithoutTimezone(
          dateRange.end,
          returnValidatedTime.value.returnHour,
          returnValidatedTime.value.returnMinute,
        ),
        steps: [],

        pickupCitySearchText: this.i.pickupCitySearch.searchInput.value,
        returnCitySearchText: this.i.returnCitySearch.searchInput.value,
      }),
    );
  }

  get birthdayAgeValidation() {
    if (this.i.birthdayInput.value.length !== 6) {
      return NotInitiatedPromiseStale;
    }
    const birthday = fromShortBirthday(this.i.birthdayInput.value);
    const now = new Date();
    const thisYear = now.getFullYear();
    const thisMonth = now.getMonth();
    const thisDay = now.getDay();

    const [birthdayYear, birthdayMonth, birthdayDay] = birthday.split('-').map((d) => Number(d));

    const birthDayMonthFrom0 = birthdayMonth - 1;
    let age = thisYear - birthdayYear;

    if (thisMonth < birthDayMonthFrom0) {
      age -= 1;
    } else if (thisMonth === birthdayMonth && thisDay > birthdayDay) {
      age -= 1;
    }

    const birthdayTime = new Date(birthday).getTime();

    if (birthdayMonth < 1 || birthdayMonth > 12) {
      return asFailedPromise({
        reason: '태어난 달을 확인해 주세요.',
      } as const);
    }

    if (birthdayDay < 1 || birthdayDay > 31) {
      return asFailedPromise({
        reason: '태어난 날을 확인해 주세요.',
      } as const);
    }

    if (Number.isNaN(birthdayTime)) {
      return asFailedPromise({
        reason: '생년월일을 확인해주세요.',
      } as const);
    }

    if (age < 20) {
      return asFailedPromise({
        reason: '만 20세 미만은 예약할 수 없어요',
      } as const);
    }
    return asSuccessPromise({
      age,
    } as const);
  }

  get rentalPeriod() {
    const pickupHour = this.i.scheduleTimeViewModel.pickupHour.value;
    const pickupMinute = this.i.scheduleTimeViewModel.pickupMinute.value;
    const returnHour = this.i.scheduleTimeViewModel.returnHour.value;
    const returnMinute = this.i.scheduleTimeViewModel.returnMinute.value;
    const pickupStr = this.i.calendarStateViewModel.selection.start?.str;
    const returnStr = this.i.calendarStateViewModel.selection.end?.str;

    if (!pickupHour || !pickupMinute) return null;
    if (!returnHour || !returnMinute) return null;
    if (!pickupStr || !returnStr) return null;

    const pickupDate = new Date(`${pickupStr} ${pickupHour}:${pickupMinute}`);
    const returnDate = new Date(`${returnStr} ${returnHour}:${returnMinute}`);

    return intervalToDuration({
      start: pickupDate,
      end: returnDate,
    });
  }

  get rentalPeriodText() {
    if (!this.rentalPeriod) return;
    // eslint-disable-next-line consistent-return
    return durationToText(this.rentalPeriod);
  }

  get differentLocationValidation() {
    if (!this.i.hasDifferentPickupReturnLocations.value) {
      return true;
    }

    return !(isOntra(this.i.pickupCityCode.value) || isOntra(this.i.returnCityCode.value));
  }

  // eslint-disable-next-line class-methods-use-this
  isOntraOffice(cityCode: string | null) {
    if (!cityCode) return false;
    return (
      cityCode === '47003' ||
      cityCode === '40001' ||
      cityCode === '40003' ||
      cityCode === '01003' ||
      cityCode === '12001' ||
      cityCode === '13013' ||
      cityCode === '43001' ||
      cityCode === '23002' ||
      cityCode === '23001' ||
      cityCode === '27004' ||
      cityCode === '27001' ||
      cityCode === '46001' ||
      cityCode === 'OKA' ||
      cityCode === 'FUK' ||
      cityCode === 'SPK' ||
      cityCode === 'TYO' ||
      cityCode === 'NGO' ||
      cityCode === 'OSA' ||
      cityCode === 'KOJ'
    );
  }
}

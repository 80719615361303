/* eslint-disable import/no-named-as-default */
import React, { useEffect, useMemo } from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';
import { useRecoilValue } from 'recoil';

import { actions, sendTrackingLog } from '../../api/trackingLog';
import { AppContext } from '../../AppContext/AppContext';
import Layout from '../../components/Layout';
import { AlertPopup } from '../../components/Popup/AlertPopup/AlertPopup';
import { COLORS } from '../../constants/commonStyles';
import { subdomainState } from '../../recoil/atoms/index';
import { MobxValue } from '../../util/MobxValue';

import { HomeDesktop } from './base/desktop/Home.desktop';
import { HomeMobile } from './base/mobile/Home.mobile';
import { HomeRouteParams } from './base/viewmodel/Home.RouteParams';
import { HomeViewModel } from './base/viewmodel/Home.viewmodel';
import { HomeDesktopMRT } from './myrealtrip/desktop/Home.desktop.MRT';
import { MRTHomeMobile } from './myrealtrip/mobile/Home.mobile.MRT';

export const HomeWrapper: React.FC<
  {
    appContext: AppContext;
  } & HomeRouteParams
> = observer(function HomePage2Wrapper(props) {
  const { appContext, ...routeParams } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const subdomain = useRecoilValue(subdomainState);
  const isMRT = subdomain.includes('myrealtrip');

  const viewmodel = useMemo(
    () => new HomeViewModel(appContext, new MobxValue(routeParams), isMobile, 13),
    [isMobile],
  );

  (window as any).viewmodel = viewmodel;

  useEffect(() => {
    const sendLog = async () => {
      sendTrackingLog({
        action: actions['/'].ENTER,
      });
    };
    sendLog();
  }, []);

  useEffect(() => {
    // NOTE: 도시 자동 선택
    if (sessionStorage.preSelectedCity) {
      const preSelectedCity = JSON.parse(sessionStorage.getItem('preSelectedCity') || '');
      if (preSelectedCity?.cityCode && preSelectedCity?.cityName) {
        viewmodel.carListingSearchBoxInputVM.pickupCitySearch.selectedCityCode.set(
          preSelectedCity.cityCode,
        );
        viewmodel.carListingSearchBoxInputVM.pickupCitySearch.searchInput.set(
          preSelectedCity.cityName,
        );
        if (isMobile) {
          viewmodel.carListingSearchBox.stepManager.pushStep({ type: '2_rental_office' });
        }
      }
    }
  }, []);

  useEffect(() => {
    viewmodel.routeParams.setValue(routeParams);
  }, [routeParams]);

  useEffect(() => {
    viewmodel.carListingSearchBox.stepManager.updateRouteParams(routeParams);
  }, [routeParams, viewmodel.carListingSearchBox.stepManager]);

  const DifferentLocationAlert = () => (
    <AlertPopup
      visible={viewmodel.carListingSearchBox.showDifferentLocationAlert}
      title="해당 도시는 대여/반납 장소를 다르게 지정할 수 없습니다."
      closeText="닫기"
      // eslint-disable-next-line react/jsx-no-bind
      handleClose={() => viewmodel.carListingSearchBox.showDifferentLocationAlert.set(false)}
      isMobile
    />
  );

  if (isMobile) {
    return (
      <Layout withoutMaxWidth>
        <DifferentLocationAlert />
        {isMRT ? <MRTHomeMobile viewmodel={viewmodel} /> : <HomeMobile viewmodel={viewmodel} />}
      </Layout>
    );
  }
  return (
    <Layout
      classOverrides={{
        footerBgColor: COLORS.GRAY_100,
      }}
    >
      <div
        className={css`
          width: 100%;
          display: flex;
          justify-content: center;

          margin-top: 56px;
        `}
      >
        <div
          className={css`
            max-width: 1320px;
          `}
        >
          <DifferentLocationAlert />
          {isMRT ? <HomeDesktopMRT viewmodel={viewmodel} /> : <HomeDesktop viewmodel={viewmodel} />}
        </div>
      </div>
    </Layout>
  );
});

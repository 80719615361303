import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

const BANNER_TYPES = {
  clock: './badge_alarm.png',
  speaker: './badge_speaker.png',
  bell: './badge_notice.png',
  calendar: './badge_calendar.webp',
};
const SpanNotice = ({ content, type, hasIcon = true, subdomain = '' }) => {
  return subdomain.includes('myrealtrip') ? (
    <div className="flex align-center">
      {hasIcon && (
        <img className="mr-2 w-[36px] h-[36px]" src={BANNER_TYPES[type]} alt="banner-type" />
      )}
      <span className="text-small text-primary-gray px-2 py-[4px] h-[24px] bg-secondary-gray-light rounded-[100px]">
        {content}
      </span>
    </div>
  ) : (
    <div className="flex align-center mt-6">
      {hasIcon && (
        <img className="mr-2 w-[36px] h-[36px]" src={BANNER_TYPES[type]} alt="banner-type" />
      )}
      <span className="text-default text-primary-gray px-3 py-[9px] h-[36px] bg-secondary-gray-light rounded-[18px]">
        {content}
      </span>
    </div>
  );
};
SpanNotice.propTypes = {
  content: PropTypes.string,
  type: PropTypes.string,
  hasIcon: PropTypes.bool,
  subdomain: PropTypes.string,
};

SpanNotice.propTypes = {
  size: PropTypes.string,
};
const Banner = (props) => {
  const { item, onClick, hasIcon, subdomain = '' } = props;
  const sizeOption = subdomain.includes('myrealtrip') ? 'size_MRT' : 'size';
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const containerClassNames = `whitespace-pre-line hover:cursor-pointer ${
    item[sizeOption] === 'small' || item[sizeOption] === 'horizontal' ? 'row-span-1' : 'row-span-2'
  } ${
    item[sizeOption] === 'large' ||
    (item[sizeOption] === 'horizontal' && 'col-span-2 laptop-max-min:col-span-4')
  } tablet-max:col-span-2`;

  const subContainer = `tablet:hover:shadow-banner-hover delay-300  duration-300 rounded-trimo  ease-in-out
  ${subdomain.includes('myrealtrip') ? 'shadow-banner-mrt' : 'shadow-trimo'}
  ${item[sizeOption] === 'horizontal' ? 'tablet:flex tablet:justify-between' : ''} ${
  item[sizeOption] === 'small' ? 'h-[189px] tablet-max:h-[196px]' : ''
} w-full`;

  const imageClassNames = 'w-full h-full tablet-max:mt-6';
  const imageClassNames_MRT = `w-[344px] absolute tablet-max:mt-6 ${
    item.id === 3 ? '-top-40' : '-top-4'
  }`;

  // absolute bottom-50%

  const divImagesClassNames = `overflow-hidden w-full ${
    item[sizeOption] === 'large' || item[sizeOption] === 'vertical'
      ? 'rounded-b-trimo tablet:mt-6'
      : 'rounded-r-trimo'
  } ${
    item[sizeOption] === 'large'
      ? 'h-[200px]'
      : item[sizeOption] === 'horizontal'
        ? 'tablet:w-[343px] h-[197px] tablet-max:h-[216px]'
        : 'h-[216px]'
  }`;

  const divImagesClassNames_MRT = `overflow-hidden relative ${
    item[sizeOption] === 'large' || item[sizeOption] === 'vertical'
      ? 'rounded-t-trimo'
      : 'rounded-r-trimo'
  } ${
    item[sizeOption] === 'large'
      ? 'h-[200px]'
      : item[sizeOption] === 'horizontal'
        ? 'tablet:w-[253px] h-[168px] tablet-max:h-[168px]'
        : 'h-[168px]'
  }`;

  const sortTitleClassNames = `line-clamp-2 ${
    item[sizeOption] === 'large'
      ? 'whitespace-pre-line text-enomous leading-[42px]'
      : item[sizeOption] === 'small'
        ? 'text-semilarge leading-[24px]'
        : 'text-semihuge leading-[29px]'
  } font-bold  mt-[14px]
  tablet-max:text-semihuge tablet-max:tracking-medium tablet-max:leading-[32px]
  `;

  const sortTitleClassName_MRT = `line-clamp-2 ${
    item[sizeOption] === 'large'
      ? 'whitespace-pre-line text-big leading-[42px]'
      : item[sizeOption] === 'small'
        ? 'text-big leading-[22px]'
        : 'text-big leading-[22px]'
  } font-bold  mt-[8px]
  tablet-max:text-semihuge tablet-max:tracking-medium tablet-max:leading-[22px]
 -webkit-font-smoothing: antialiased -moz-osx-font-smoothing: grayscale font-smooth: never

  `;

  return (
    <div className={containerClassNames} onClick={onClick}>
      <div className="flex">
        <div className="flex tablet:hover:-translate-y-2 duration-300 w-full">
          {subdomain.includes('myrealtrip') ? (
            <div className={subContainer}>
              {item[sizeOption] !== 'small' && (
                <div className={divImagesClassNames_MRT}>
                  <div className="w-[344px] h-[203px] absolute bottom-0 ">
                    <img
                      className={imageClassNames_MRT}
                      src={!isMobile ? item.image_MRT : item.imageMobile ?? item.image_MRT}
                      alt="banner-image"
                    />
                  </div>
                </div>
              )}
              <div className="px-4 py-4 tablet-max:px-4">
                <SpanNotice
                  type={item.type}
                  content={item.notice}
                  hasIcon={hasIcon}
                  subdomain={subdomain}
                />
                <p
                  dangerouslySetInnerHTML={{ __html: item.sortTitle }}
                  className={sortTitleClassName_MRT}
                ></p>
                <p
                  dangerouslySetInnerHTML={{ __html: item.sortContent }}
                  className="mt-[8px] text-gray-600 text-[13px] font-medium line-clamp-1 tablet-max:text-default tablet-max:tracking-small
                  -webkit-font-smoothing: antialiased -moz-osx-font-smoothing: grayscale font-smooth: never"
                ></p>
              </div>
            </div>
          ) : (
            <div className={subContainer}>
              <div className="px-6 tablet-max:px-4">
                <SpanNotice
                  type={item.type}
                  content={item.notice}
                  hasIcon={hasIcon}
                  subdomain={subdomain}
                />
                <p
                  dangerouslySetInnerHTML={{ __html: item.sortTitle }}
                  className={sortTitleClassNames}
                ></p>
                <p
                  dangerouslySetInnerHTML={{ __html: item.sortContent }}
                  className="mt-[14px] text-default line-clamp-1 font-light tablet-max:text-default tablet-max:tracking-small"
                ></p>
              </div>
              {item[sizeOption] !== 'small' && (
                <div className={divImagesClassNames}>
                  {' '}
                  <img
                    className={imageClassNames}
                    src={!isMobile ? item.image : item.imageMobile ?? item.image}
                    alt="banner-image"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
Banner.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  hasIcon: PropTypes.bool,
  subdomain: PropTypes.string,
};
export default Banner;

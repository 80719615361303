import { HELP_PAGE, RESERVATION_CHECK } from '^/constants/routes';

export const NAVIGATION_MENU = [
  {
    name: '예약조회',
    url: RESERVATION_CHECK,
    subdomain: 'default',
  },
  {
    name: 'FAQ',
    url: HELP_PAGE,
    subdomain: 'default',
  },
  {
    name: '예약 조회하기',
    url: RESERVATION_CHECK,
    subdomain: 'myrealtrip',
  },
];

import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { VENDOR_LOGOS } from '^/constants/LogoImages.constants';
import { birthdayState } from '^/recoil/atoms';
import { pickupLocationState, returnLocationState } from '^/recoil/atoms/locationState';
import { pickupDatetimeState, returnDatetimeState } from '^/recoil/atoms/datetimeState';
import { authAtom } from '../../recoil/atoms/auth';
import ReservationConfirm from '../Booking/components/DriverDetails/components/ReservationConfirm';
import { calculateHours } from '../../util';
import { GroupCar, NameCar } from '../../components/ModelCar';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { font2 } from '^/constants/commonStyles';
import { COLORS2_AS_FOREGROUND } from '^/constants/commonStyles';
import { COLORS2_AS_BACKGROUND } from '^/constants/commonStyles';
import { MARGINS } from '^/constants/commonStyles';
import { Footer2 } from '^/components/Footer/Footer2';
import { Layout2DesktopStyles } from '^/components/Layout/Layout2Desktop';
import { Footer3Mobile, Footer3MobileDefaultStyles } from '^/components/Footer3/Footer3.Mobile';
import { translateServices } from '../Listing/common/translateServices';
import { actions, sendTrackingLog } from '^/api/trackingLog';

function gotoMyReservationByRefresh() {
  sendTrackingLog({
    action: actions.RESERVATION_RESULT.CLICK_RESERVATION_DETAIL,
  });
  const myReservationPath = typeRouter.routes.myReservationsList().href;
  window.location.href = myReservationPath;
}

function goToHomeByRefresh() {
  sendTrackingLog({
    action: actions.RESERVATION_RESULT.CLICK_HOME,
  });
  window.location = window.location.origin;
}

const ReservationResultHertz = ({ reservationData }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const isAuth = useRecoilValue(authAtom);
  const setPickupDatetime = useSetRecoilState(pickupDatetimeState);
  const setReturnDatetime = useSetRecoilState(returnDatetimeState);
  const setBirthday = useSetRecoilState(birthdayState);
  const setPickupLocation = useSetRecoilState(pickupLocationState);
  const setReturnLocation = useSetRecoilState(returnLocationState);

  const [resInfo, setResInfo] = useState({});

  const { bookingInfo, res_id, pkgSelected, temp_ppd_flag } = reservationData;

  const setupData = () => {
    setResInfo(reservationData.reservationInfo);
  };
  useEffect(() => {
    setupData();
    sendTrackingLog({
      action: actions.RESERVATION_RESULT.ENTER,
      data: res_id,
    });
  }, []);

  useEffect(() => {
    setPickupDatetime('');
    setReturnDatetime('');
    setBirthday('');
    setPickupLocation({
      cityCode: '',
      cityName: '',
      parentCategoryCode: '',
      categories: {},
      locationCode: '',
      locationName: '',
    });
    setReturnLocation({
      cityCode: '',
      cityName: '',
      parentCategoryCode: '',
      categories: {},
      locationCode: '',
      locationName: '',
    });
  }, [resInfo]);

  return (
    <div className={[].join(' ')}>
      <div
        className={[
          Layout2DesktopStyles.withMaxWidth,
          'relative flex flex-col mobile:mx-6 trimo-screen:mx-auto',
        ].join(' ')}
      >
        <div className="flex items-center gap-3 mt-[50px] tablet-max:my-5">
          <h1
            className={[
              isMobile ? 'text-middlegiant font-bold tablet-max:text-semihuge' : font2.Title1,
            ].join(' ')}
          >
            예약이 완료되었습니다!
          </h1>
        </div>
        {isMobile ? (
          <>
            <ReservationConfirm
              bookingInfo={bookingInfo}
              pkgSelected={pkgSelected}
              isTitle={false}
              resId={res_id}
              isTempPPD={temp_ppd_flag}
            />
            <div className="flex gap-[15px] justify-between items-center w-full mt-5">
              <button
                onClick={() => goToHomeByRefresh()}
                className="border border-neutral-100 basis-1/2 tablet:py-4 tablet:px-6 mobile:p-[15px] bg-white rounded-trimo-5 text-primary-gray font-bold"
              >
                {t('backHome')}
              </button>
              <button
                onClick={gotoMyReservationByRefresh}
                disabled={isAuth ? false : true}
                className="basis-1/2 tablet:py-4 tablet:px-6 mobile:p-[15px] bg-primary-skyblue tablet:bg-trimo-shadow rounded-trimo-5 text-primary-white font-bold whitespace-nowrap"
              >
                {t('toReservationHistory')}
              </button>
            </div>
          </>
        ) : (
          <div
            className={[
              'mt-[115px] mx-auto flex flex-col items-center gap-6 max-w-content w-[872px]',
            ].join(' ')}
          >
            <div className="bg-primary-white rounded-trimo shadow-trimodropdown pl-[47px] tablet:pr-[13px] tablet:py-[40px] text-primary-gray w-full">
              <div className="flex justify-between items-center">
                <GroupCar
                  modelName={bookingInfo?.carInfo?.vehInfo?.name}
                  className={[font2.Title1].join(' ')}
                  size={bookingInfo?.carInfo?.vehInfo?.size}
                  type={bookingInfo?.carInfo?.vehInfo?.type}
                />
                <img
                  alt="car-image"
                  src={VENDOR_LOGOS[bookingInfo?.carInfo?.vehInfo?.company]}
                  className="object-scale-down h-[26px] w-[72px]"
                />
              </div>
              <div className={['flex text-default mt-1.5 mb-3'].join(' ')}>
                <NameCar
                  modelName={bookingInfo?.carInfo?.vehInfo?.name}
                  className={font2.Caption1_Light}
                />
              </div>
              <p
                className={[
                  'mt-2',
                  font2.Headline1_Regular,
                  COLORS2_AS_FOREGROUND.sub.MrtGray1,
                ].join(' ')}
              >
                {translateServices(bookingInfo.carInfo.services, t)}
              </p>
              <div className="mt-[30px] flex flex-col gap-6 text-left">
                <div className="flex justify-between items-center tablet:gap-[50px] mobile:gap-12">
                  <div className="basis-1/5 tablet:text-semihuge font-bold">예약 번호</div>
                  <div className={['grow', font2.Body1_Regular].join(' ')}>
                    {temp_ppd_flag ? '-' : res_id || '-'}
                  </div>
                </div>
                <div className="flex justify-between items-center gap-[50px]">
                  <div className="basis-1/5 tablet:text-semihuge font-bold">
                    {t('rentaled')} / {t('return')} {t('location1')}
                  </div>
                  <div className={['grow'].join(' ')}>
                    {t(`location_name.${bookingInfo?.carInfo?.coreInfo?.pickup?.code}`) +
                      ' / ' +
                      t(`location_name.${bookingInfo?.carInfo?.coreInfo?.return?.code}`)}
                  </div>
                </div>
                <div className="flex justify-between items-center tablet:gap-[50px] mobile:gap-12">
                  <div className="basis-1/5 tablet:text-semihuge mobile:text-big font-bold">
                    {t('rentaled')} / {t('return')} {t('schedule')}
                  </div>
                  <div className="grow tablet:text-big mobile:text-default">
                    <span>
                      {format(
                        new Date(bookingInfo?.carInfo?.coreInfo?.pickup?.time),
                        'y.MM.dd HH:mm',
                      )}{' '}
                      ~{' '}
                      {format(
                        new Date(bookingInfo?.carInfo?.coreInfo?.return?.time),
                        'MM.dd HH:mm',
                      )}
                    </span>
                    <span>
                      {' '}
                      (
                      {calculateHours(
                        new Date(bookingInfo?.carInfo?.coreInfo?.return?.time),
                        new Date(bookingInfo?.carInfo?.coreInfo?.pickup?.time),
                      )}{' '}
                      {t('hourUnit')})
                    </span>
                  </div>
                </div>
                <div className="flex justify-between items-center tablet:gap-[50px] mobile:gap-12">
                  <div className="basis-1/5 tablet:text-semihuge mobile:text-big font-bold">
                    {t('insuranceDetails')}
                  </div>
                  <div className="grow tablet:text-big mobile:text-default flex flex-row items-center">
                    <p>{pkgSelected ? t(`insurance_name.${pkgSelected}`) : '기본 할인요금'}</p>
                  </div>
                </div>
                <div className="flex justify-between items-center tablet:gap-[50px] mobile:gap-12">
                  <div className="basis-1/5 tablet:text-semihuge mobile:text-big font-bold">
                    {t('moreOptions')}
                  </div>
                  <div className="grow text-big">
                    {bookingInfo?.carInfo.equipsCharge?.length > 0 ? (
                      <span>
                        {bookingInfo?.carInfo?.equipsCharge
                          .filter((item) => Number(item.quantity) > 0)
                          .map((equip) => {
                            return `${t(`equipment_${equip.code}`)} (${equip.quantity})`;
                          })
                          .join(' / ')}
                      </span>
                    ) : (
                      <span>{t('none')}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex tablet:gap-6 mobile:gap-[15px] justify-between items-center w-full">
              <button
                onClick={() => goToHomeByRefresh()}
                className={[
                  'basis-1/2 tablet:py-4.5 tablet:px-6 bg-trimo-shadow rounded-trimo-5',
                  font2.Body1_Bold,
                ].join(' ')}
              >
                {t('backHome')}
              </button>
              <button
                onClick={gotoMyReservationByRefresh}
                disabled={isAuth ? false : true}
                className={[
                  'basis-1/2 tablet:py-4.5 tablet:px-6 bg-primary-skyblue rounded-trimo-5 text-primary-white whitespace-nowrap',
                  font2.Body1_Bold,
                ].join(' ')}
              >
                {t('toReservationHistory')}
              </button>
            </div>
          </div>
        )}
      </div>
      {!isMobile ? (
        <>
          <div
            className={[
              COLORS2_AS_BACKGROUND.sub.MrtGray3,
              COLORS2_AS_FOREGROUND.sub.MrtGray3,
              MARGINS.TOP.spacing120,
              css`
                width: 100%;
                height: 2px;
              `,
            ].join(' ')}
          />
          <div className={['relative flex flex-col mobile:mx-6 trimo-screen:mx-auto'].join(' ')}>
            <Footer2 />
          </div>
        </>
      ) : (
        <Footer3Mobile
          stylesOverride={{
            FooterRoot: [Footer3MobileDefaultStyles.FooterRoot, MARGINS.TOP.spacing48].join(' '),
          }}
        />
      )}
    </div>
  );
};

export default ReservationResultHertz;

import { useEffect, useState } from 'react';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

import Input from '^/components/Input';
import { ConfirmDialogPopup } from '^/components/Popup/ConfirmDialogPopup/ConfirmDialogPopup';
import { CancellationReasonType, cancellationReasons } from '^/types/CancellationReasonType';
import { actions, sendTrackingLog } from '^/api/trackingLog';

const ConfirmReservationCancelPopup = ({ visible, handleCancel, handleConfirm, isMobile }) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [selectedReason, setSelectedReason] = useState<CancellationReasonType>('');
  const [otherReason, setOtherReason] = useState<string>('');

  const isOtherReason = selectedReason === '기타';
  useEffect(() => {
    if (!isOtherReason && selectedReason !== '') {
      setIsDisabled(false);
    } else if (isOtherReason && otherReason.replaceAll(' ', '') !== '') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedReason, otherReason]);

  return (
    <ConfirmDialogPopup
      visible={visible}
      title={
        <>
          <p className="mb-[25px] w-full">정말 예약을 취소하겠습니까?</p>
          <FormControl fullWidth>
            <FormLabel id="CacellationReason::FormLabel">
              <p className="mb-[8px] text-black">
                <span className="mr-[4px] text-red-700">*</span>
                예약 취소 사유
              </p>
            </FormLabel>
            <RadioGroup
              value={selectedReason}
              onChange={(e) => {
                sendTrackingLog({
                  action: actions.RESERVATION_DETAIL.SELECT_CANCEL_REASON,
                  data: e.target.value,
                });
                setSelectedReason(e.target.value);
                setOtherReason('');
              }}
            >
              {cancellationReasons.map((reason) => (
                <div className="flex w-full items-center" key={reason}>
                  <FormControlLabel value={reason} control={<Radio />} label={reason} />
                  {reason === '기타' && (
                    <Input
                      ref={null}
                      {...{
                        containerClassNames: 'flex-1',
                        classNames: 'h-[10px]',
                        height: '10px',
                        placeholder: '내용을 입력해주세요',
                        isDisabled: !isOtherReason,
                        focused: isOtherReason,
                        value: isOtherReason ? otherReason : '',
                        onBlur: (e) => {
                          sendTrackingLog({
                            action: actions.RESERVATION_DETAIL.WRITE_CANCEL_REASON,
                            data: e.target.value,
                          });
                        },
                        onChange: (e) => {
                          setOtherReason(e.target.value);
                        },
                        noMargin: true,
                      }}
                    />
                  )}
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        </>
      }
      confirmText="예약 취소"
      isDisabled={isDisabled}
      handleCancel={() => {
        /** 예약취소 cancel 버튼 tracking log */
        sendTrackingLog({
          action: actions.RESERVATION_DETAIL.CLICK_CANCEL_BUTTON_ON_CANCEL_CHECK_MODAL,
        });
        setSelectedReason('');
        setOtherReason('');
        handleCancel();
      }}
      handleConfirm={() => {
        /** 예약취소 confirm 버튼 tracking log */
        sendTrackingLog({
          action: actions.RESERVATION_DETAIL.CLICK_CONFIRM_BUTTON_ON_CANCEL_CHECK_MODAL,
        });
        handleConfirm(isOtherReason ? `${selectedReason} [${otherReason}]` : selectedReason);
      }}
      isMobile={isMobile}
    />
  );
};

export default ConfirmReservationCancelPopup;
